import * as React from 'react';
import { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import axios from 'axios';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DialogContentText from '@mui/material/DialogContentText';
import MoreIcon from '@mui/icons-material/MoreVert';
import { useAppStore } from '../AppStore/AppStore';
import { useNavigate } from 'react-router-dom';
import { FaBell, FaCheckCircle, FaDotCircle, FaMapMarked, FaMapMarkerAlt, FaMarker, FaSignOutAlt, FaWindowClose } from 'react-icons/fa';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import CloseIcon from '@mui/icons-material/Close';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import Swal from 'sweetalert2';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';


const Transition5 = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
}));





let notify = ''





export default function Navbar() {



    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('');


    const handleClickListItem = () => {
        setOpen(true);
    };

    const handleClose = (newValue) => {
        setOpen(false);

        if (newValue) {
            setValue(newValue);
        }
    };

    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const updateState = useAppStore((state) => state.updateOpen)
    const dopen = useAppStore((state) => state.dopen)

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
            sx={{ marginTop: 7 }}
        >

            <MenuItem style={{ color: 'red' }} onClick={() => {
                sessionStorage.removeItem('key')
                sessionStorage.removeItem(`email`)
                window.location.reload()
                window.location.href = '/'

            }}><FaSignOutAlt /> Logout</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';




    React.useEffect(() => {
        acceptedBidsPrice()
    }, [])


    const [open2, setOpen2] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen2((prevOpen) => !prevOpen);
    };

    const handleClose2 = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen2(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen2(false);
        } else if (event.key === 'Escape') {
            setOpen2(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open2;
    }, [open]);


    const [money, setMoney] = React.useState([])
    const [notifyLen, setNotifyLen] = React.useState()


    notify = notifyLen

    const acceptedBidsPrice = () => {
        const email = sessionStorage.getItem('email')
        const isNotification = 1
        const show = 'accept'
        axios.post('https://pmapi.emedha.in/send/bids/top-three/price', {
            email,
            show,
            isNotification
        }).then((res) => {
            setMoney(res.data.result)
            setNotifyLen(res.data.result.length)
        })
    }

    React.useEffect(() => {
        acceptedBidsPrice()
    }, [])


    const [open5, setOpen5] = React.useState(false);

    const handleClickOpen5 = (bidpoint, veh, driver) => {
        transPersonalData(bidpoint, veh, driver)
    };


    const handleClose5 = () => {
        setOpen5(false);
    };

    const [personalData, setPersonalData] = useState([])
    const [busines, setBusiness] = useState([])
    const [bidId, setBidId] = useState()
    const [vehicle, setVehicle] = useState([])
    const [driver, setDriver] = useState([])

    const transPersonalData = (bidId, vehicleId, driverId) => {

        setBidId(bidId)

        axios.post('https://pmapi.emedha.in/send/bids/proceed', {
            bidId
        }).then((res) => {
            setPersonalData(res.data.data)
            setBusiness(res.data.data)
            conf_veh(vehicleId, driverId)

        })
    }

    const conf_veh = (vehicleId, driverId) => {
        axios.post('https://pmapi.emedha.in/vehicle/data/show-to-confirm', {
            vehicleId
        }).then((res) => {
            setVehicle(res.data.data)
            conf_driver(driverId)
        })
    }

    const conf_driver = (driverId) => {
        axios.post('https://pmapi.emedha.in/driver/data/show-to-confirm', {
            driverId
        }).then((res) => {
            setDriver(res.data.data)
            setOpen5(true)
        })
    }

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'auto',
        color: theme.palette.text.secondary,
    }));

    const [value1, setValue1] = React.useState('1');

    const handleChange6 = (event, newValue) => {
        setValue1(newValue);
    };


    const [open3, setOpen3] = useState(false)
    const [rMoney, setRMoney] = useState('')

    const loadConfirm = () => {

        axios.post('https://pmapi.emedha.in/payment/get-90pre/payment', {
            bidId
        }).then((res) => {
            setRMoney(res.data.money)
            setOpen18(true)
        })
    }

    const [loadingImage, setLoadingImage] = useState('')
    const [loadingDate, setLoadingDate] = useState('')
    const [loadingFieldDataError, setLoadingFieldDataError] = useState(false)

    const uploadLoadingImage = () => {

        if (loadingDate === '' && loadingImage === '') {
            setLoadingFieldDataError(true)
            return
        }

        const formData = new FormData()

        const client_id = sessionStorage.getItem('client_id')



        formData.append('file', loadingImage)
        formData.append('bidId', bidId)
        formData.append('client_id', client_id)
        formData.append('loadingDate', loadingDate)

        axios.post('https://pmapi.emedha.in/driver/data/upload-loading-image', formData).then((res) => {
            if (res.data.status === 200) {
                setOpen3(true)
                setOpen18(false)
            }
        })

    }

    const payment90Pre = () => {
        const sendBidsNumber = sessionStorage.getItem('bidSendNum') - 1
        const client_id = sessionStorage.getItem('client_id')
        axios.post('https://pmapi.emedha.in/payment/90pre/payment', {
            bidId,
            sendBidsNumber,
            client_id
        }).then((res) => {
            setOpen3(false)
            setOpen5(false)
            acceptedBidsPrice()
            Swal.fire({
                title: "Successful..!",
                text: "Payment successfully sent ...!",
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Dashboard",
            }).then((result) => {
                if (result.value) {
                    setOpen3(false)
                    setOpen5(false)

                }
            });

        })
    }


    const [open18, setOpen18] = React.useState(false);



    return (
        <Box sx={{ flexGrow: 1 }}>
            < AppBar position="fixed" elevation={1} sx={{ backgroundColor: 'red', color: 'white', padding: 2 }}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{ mr: 3 }}
                        onClick={() => updateState(!dopen)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ display: { xs: 'none', sm: 'block' } }}
                    >
                        CUSTOMER DASHBOARD
                    </Typography>

                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

                        <IconButton
                            size="large"
                            aria-label="show 17 new notifications"
                            color="inherit"
                        >
                            {/* <Button>
                                {/* <Badge ref={anchorRef}
                                    id="composition-button"
                                    aria-controls={open2 ? 'composition-menu' : undefined}
                                    aria-expanded={open2 ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleToggle} badgeContent={notifyLen} color="error">

                                    {/* <NotificationsIcon></NotificationsIcon> */}

                            {/* </Badge> */}
                            {/* </Button> */}
                            <Stack direction="row" spacing={2}>
                                <div>
                                    <Popper
                                        open={open2}
                                        anchorEl={anchorRef.current}
                                        role={undefined}
                                        placement="bottom-start"
                                        transition
                                        disablePortal
                                    >
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{
                                                    transformOrigin:
                                                        placement === 'bottom-start' ? 'left top' : 'left bottom',
                                                }}
                                            >
                                                <Paper>
                                                    <ClickAwayListener onClickAway={handleClose2}>

                                                        <div style={{ cursor: 'auto', height: '27rem', width: '25rem', padding: 2 }} >
                                                            <Box style={{ backgroundColor: 'white', height: 55, width: '100%', boxShadow: 'none', border: 'none', justifyContent: 'space-between', borderRadius: '0.2rem', marginBottom: 10, flexDirection: 'row', alignItems: 'center', paddingRight: 5, paddingLeft: 5 }} className='card'  >
                                                                <Typography style={{ color: 'gray', fontSize: 19, fontWeight: 'bold', textAlign: 'start' }} ><FaBell size={23} /> Notifications</Typography>
                                                                <Typography style={{ color: 'gray', fontSize: 19, fontWeight: 'bold', textAlign: 'end', cursor: 'pointer' }} ><FaWindowClose size={23} onClick={() => setOpen2(false)} /></Typography>
                                                            </Box>
                                                            {
                                                                money.map((data) => {
                                                                    return (
                                                                        <Box sx={{ marginBottom: 4, flexDirection: 'row' }} >
                                                                            <Card elevation={0} sx={{ maxWidth: 100 + '%', backgroundColor: '#ebe9e9', }}>
                                                                                <CardContent>
                                                                                    <Typography variant="body2" color="text.secondary" style={{ marginBottom: 5 }}>
                                                                                        <Typography sx={{ fontSize: 11, position: 'relative', left: -43 }}>
                                                                                            {data.acceptedBids_id}
                                                                                        </Typography>
                                                                                    </Typography>
                                                                                    <Typography gutterBottom sx={{ fontSize: 11, position: 'relative', top: '0.3rem', left: '-5rem' }} component="div">
                                                                                        <FaDotCircle /> To: {data.toRoute}
                                                                                    </Typography>
                                                                                    <Box sx={{ height: 15, borderLeft: 1, position: 'relative', left: '1.72rem' }} ></Box>
                                                                                    <Typography sx={{ fontSize: 10, position: 'relative', top: '0.2rem', left: '-4.4rem' }} component="div">
                                                                                        <FaMapMarkerAlt size={21} color='gold' /> From:  {data.fromRoute}
                                                                                    </Typography>
                                                                                </CardContent>
                                                                                <CardActions>
                                                                                    <Button size="small" onClick={() => {
                                                                                        handleClickOpen5(data.acceptedBids_id, data.vehicleId, data.driverId)
                                                                                    }} style={{ backgroundColor: 'green', color: 'white' }} >Proceed</Button>
                                                                                </CardActions>
                                                                            </Card>
                                                                            <div >
                                                                                <Dialog
                                                                                    fullScreen
                                                                                    open={open5}
                                                                                    onClose={handleClose5}
                                                                                    TransitionComponent={Transition5}
                                                                                >
                                                                                    <AppBar elevation={2} sx={{ position: 'relative', backgroundColor: 'white', color: 'gray' }} >
                                                                                        <Toolbar>
                                                                                            <IconButton
                                                                                                edge="start"
                                                                                                color="inherit"
                                                                                                onClick={handleClose5}
                                                                                                aria-label="close"
                                                                                            >
                                                                                                <CloseIcon />
                                                                                            </IconButton>
                                                                                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                                                                                Bid Id: {bidId}  <span style={{ color: 'lightgreen' }} > Verified <FaCheckCircle color='lightgreen' /></span>
                                                                                            </Typography>
                                                                                            <Button autoFocus color="inherit" variant='outlined' onClick={() => loadConfirm()}>
                                                                                                Loading Confirmation
                                                                                            </Button>
                                                                                        </Toolbar>
                                                                                    </AppBar>
                                                                                    <List>
                                                                                        <ListItem>
                                                                                            <ListItemText />
                                                                                        </ListItem>
                                                                                        <Divider />
                                                                                        <ListItem >
                                                                                            <Box sx={{ flexGrow: 1, p: 3 }}>
                                                                                                <Grid container spacing={2}>
                                                                                                    <Grid xs={12}>
                                                                                                        <Item elevation={2} sx={{ height: '74vh' }}>
                                                                                                            <Box sx={{ width: '100%', typography: 'body1', p: 2 }}>
                                                                                                                <TabContext value={value1}>
                                                                                                                    <Box sx={{ boxShadow: '2px', p: 1 }}>
                                                                                                                        <TabList onChange={handleChange6} aria-label="lab API tabs example">
                                                                                                                            <Tab label="Personal Details" value="1" />
                                                                                                                            <Tab label="Business Details" value="2" />
                                                                                                                            <Tab label="Driver Details" value="3" />
                                                                                                                            <Tab label="Vehicle Details" value="4" />
                                                                                                                        </TabList>
                                                                                                                    </Box>
                                                                                                                    <Grid container spacing={2}>
                                                                                                                        <Grid xs={12}>
                                                                                                                            <Item elevation={0} sx={{ height: '50vh' }}>
                                                                                                                                <TabPanel value="1">
                                                                                                                                    <Box>
                                                                                                                                        <Typography>Personal Data</Typography>
                                                                                                                                        <Divider sx={{ borderBottom: 1 }} />
                                                                                                                                        {
                                                                                                                                            personalData.map((data) => {
                                                                                                                                                sessionStorage.setItem('bidSendNum', data.sendBidsNumber)
                                                                                                                                                sessionStorage.setItem('client_id', data.client_id)
                                                                                                                                                return (
                                                                                                                                                    <Box>
                                                                                                                                                        <Box sx={{ marginTop: 6 }}>
                                                                                                                                                            <Typography>Name: {data.first_name} {data.last_name}</Typography>
                                                                                                                                                            <br />
                                                                                                                                                            <Typography>Email: {data.email}</Typography>
                                                                                                                                                            <br />
                                                                                                                                                            <Typography>Mobile: {data.mobile}</Typography>
                                                                                                                                                        </Box>
                                                                                                                                                    </Box>
                                                                                                                                                )
                                                                                                                                            })
                                                                                                                                        }
                                                                                                                                    </Box>
                                                                                                                                </TabPanel>
                                                                                                                                <TabPanel value="2">
                                                                                                                                    <Box>
                                                                                                                                        <Typography>Business Data</Typography>
                                                                                                                                        <Divider sx={{ borderBottom: 1 }} />
                                                                                                                                        {
                                                                                                                                            busines.map((data) => {
                                                                                                                                                return (
                                                                                                                                                    <Box>

                                                                                                                                                        <Box sx={{ marginTop: 6 }}>
                                                                                                                                                            <Typography>Nature Of Business: {data.natureOfBusiness} </Typography>
                                                                                                                                                            <br />
                                                                                                                                                            <Typography>GST Number: {data.gst}</Typography>
                                                                                                                                                            <br />
                                                                                                                                                            <Typography>Firm: {data.firm}</Typography>
                                                                                                                                                            <br />
                                                                                                                                                            <Typography>Pan Card: {data.pan_card}</Typography>
                                                                                                                                                        </Box>
                                                                                                                                                    </Box>
                                                                                                                                                )
                                                                                                                                            })
                                                                                                                                        }
                                                                                                                                    </Box>
                                                                                                                                </TabPanel>
                                                                                                                                <TabPanel value="3">
                                                                                                                                    <Box>
                                                                                                                                        <Typography>Driver Data</Typography>
                                                                                                                                        <Divider sx={{ borderBottom: 1 }} />
                                                                                                                                        {
                                                                                                                                            driver.map((data) => {
                                                                                                                                                return (
                                                                                                                                                    <Box>
                                                                                                                                                        <Box sx={{ marginTop: 6 }}>
                                                                                                                                                            <Typography>Driver Name: {data.dName} </Typography>
                                                                                                                                                            <br />
                                                                                                                                                            <Typography>DL Number: {data.DLNumber}</Typography>
                                                                                                                                                            <br />
                                                                                                                                                            <Typography>Mobile Number: {data.dMobile}</Typography>
                                                                                                                                                            <br />
                                                                                                                                                            <Typography>Bank Upi Id: {data.bankUpi}</Typography>
                                                                                                                                                            <br />
                                                                                                                                                            <Typography>Driver Aadhar: {data.DAadhar}</Typography>
                                                                                                                                                        </Box>
                                                                                                                                                    </Box>
                                                                                                                                                )
                                                                                                                                            })
                                                                                                                                        }
                                                                                                                                    </Box>
                                                                                                                                </TabPanel>
                                                                                                                                <TabPanel value="4">
                                                                                                                                    <Typography>Vehicle Data</Typography>
                                                                                                                                    <Divider sx={{ borderBottom: 1 }} />
                                                                                                                                    <Box>
                                                                                                                                        {
                                                                                                                                            vehicle.map((data) => {
                                                                                                                                                return (
                                                                                                                                                    <Box>
                                                                                                                                                        <Box sx={{ marginTop: 6 }}>
                                                                                                                                                            <Typography>RC Number: {data.RegNum}</Typography>
                                                                                                                                                            <br />
                                                                                                                                                            <Typography>Vehicle: {data.vehicleType}</Typography>
                                                                                                                                                            <br />
                                                                                                                                                            <Typography>Capacity: {data.vehicleCapacity}</Typography>
                                                                                                                                                            <br />
                                                                                                                                                            <Typography>Road Permit: {data.RPermit}</Typography>
                                                                                                                                                            <br />
                                                                                                                                                            <Typography>Fitness Certificate: {data.fCertificate}</Typography>
                                                                                                                                                        </Box>
                                                                                                                                                    </Box>
                                                                                                                                                )
                                                                                                                                            })
                                                                                                                                        }
                                                                                                                                    </Box>
                                                                                                                                </TabPanel>
                                                                                                                            </Item>
                                                                                                                        </Grid>
                                                                                                                    </Grid>
                                                                                                                </TabContext>
                                                                                                            </Box>
                                                                                                        </Item>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Box>
                                                                                        </ListItem>
                                                                                    </List>
                                                                                </Dialog>
                                                                            </div>
                                                                        </Box>
                                                                    )
                                                                })
                                                            }
                                                            {
                                                                money.length === 0 && (
                                                                    <Box className='card' style={{ height: '39vh', width: '100%', alignItems: 'center', justifyContent: 'center', boxShadow: 'none' }} >
                                                                        <img src='https://img.freepik.com/free-vector/no-data-concept-illustration_114360-2506.jpg?size=626&ext=jpg&ga=GA1.1.415759594.1681714138&semt=ais' style={{ width: '90px' }} />
                                                                        <h6 style={{ color: 'gold' }}>0 Notifications</h6>
                                                                    </Box>
                                                                )
                                                            }
                                                            <div>
                                                                <Dialog open={open3} >
                                                                    <DialogTitle style={{ fontSize: 20, textAlign: 'center' }} >Payment Getway</DialogTitle>
                                                                    <DialogTitle>Pay your 90% pending amount to the your loading confirmation</DialogTitle>
                                                                    <DialogContent>

                                                                        <TextField
                                                                            autoFocus
                                                                            margin="dense"
                                                                            id="name"
                                                                            label="Delivery Confirmation Price"
                                                                            type="email"
                                                                            fullWidth
                                                                            variant="standard"
                                                                            value={rMoney}
                                                                        />
                                                                    </DialogContent>
                                                                    <DialogActions>
                                                                        <Button onClick={() => payment90Pre()} >Pay</Button>
                                                                        <Button onClick={() => setOpen3(false)}>Cancel</Button>
                                                                    </DialogActions>
                                                                </Dialog>
                                                            </div>
                                                            <div>
                                                                <Dialog
                                                                    open={open18}
                                                                    maxWidth='lg'
                                                                >
                                                                    <DialogTitle id="alert-dialog-title">
                                                                        {"Loading Confirmation"}
                                                                    </DialogTitle>
                                                                    <DialogContent>
                                                                        <div className='card' style={{ width: '60rem', borderRadius: '0.2rem', padding: 9, flexDirection: 'row', justifyContent: 'space-between' }} >

                                                                            <div style={{ width: '49%' }}>
                                                                                <img src="https://img.freepik.com/free-vector/vector-yellow-dump-truck-unloading-side-view-illustration-isolated-white-background_8130-972.jpg?size=626&ext=jpg&ga=GA1.1.415759594.1681714138&semt=ais" width='100%' alt='image' />
                                                                            </div>

                                                                            <div style={{ width: '40%', marginTop: 40 }}>
                                                                                <label>Loading date</label>
                                                                                <TextField
                                                                                    margin="dense"
                                                                                    id="name"
                                                                                    type="date"
                                                                                    fullWidth
                                                                                    variant="standard"
                                                                                    onChange={(e) => {
                                                                                        setLoadingDate(e.target.value)
                                                                                        setLoadingFieldDataError(false)
                                                                                    }}
                                                                                />
                                                                                <br />
                                                                                <label>Loading picture</label>
                                                                                <TextField
                                                                                    margin="dense"
                                                                                    id="name"
                                                                                    type="file"
                                                                                    name='file'
                                                                                    fullWidth
                                                                                    variant="standard"
                                                                                    onChange={(e) => {
                                                                                        setLoadingImage(e.target.files[0])
                                                                                        setLoadingFieldDataError(false)
                                                                                    }}

                                                                                />
                                                                                <br />

                                                                                <TextField
                                                                                    margin="dense"
                                                                                    id="name"
                                                                                    label="Delivery confirmation amount"
                                                                                    type="email"
                                                                                    fullWidth
                                                                                    variant="standard"
                                                                                    value={rMoney}
                                                                                />
                                                                                {
                                                                                    loadingFieldDataError && (
                                                                                        <Typography style={{ color: 'red', marginTop: 21 }} >Any fields can't be empty..!</Typography>
                                                                                    )
                                                                                }
                                                                            </div>

                                                                        </div>
                                                                    </DialogContent>

                                                                    <DialogActions>
                                                                        <Button onClick={() => setOpen18(false)} style={{ backgroundColor: 'red', color: 'white', fontWeight: 'bold' }}>Cancel</Button>
                                                                        <Button onClick={() => uploadLoadingImage()} style={{ backgroundColor: '#1c1c5c', color: 'white', fontWeight: 'bold' }} >
                                                                            Pay
                                                                        </Button>
                                                                    </DialogActions>
                                                                </Dialog>
                                                            </div>
                                                        </div>

                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>
                                </div>
                            </Stack>

                        </IconButton>
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                </Toolbar>

            </AppBar>

            {renderMenu}

        </Box>
    );
}
