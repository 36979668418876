import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import './Style.css'
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaRupeeSign } from 'react-icons/fa';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SearchItem from '../Dashboard/SearchItem';
import icon from './icon.png';
import icon2 from './icon2.png';
import icon1 from './icon1.png';

const Navigation = () => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [open1, setOpen1] = React.useState(false);

    const handleClickOpen1 = () => {
        setOpen1(true);
    };

    const handleClose1 = () => {
        setProductList([]);
        setSelectedLocation('');
        setOpenLocationDropdown(false);
        setOpen1(false);
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
      
        setOpen(false);
    };

    const navigate = useNavigate()

    const [products, setProductList] = useState([])

    const searchProduct = (product) => {
        if (product === '') {
            setProductList([]);
            setSelectedLocation('');
            setOpenLocationDropdown(false);
            return
        }
        axios.post('https://vyaparapi.emedha.in/searchProduct', {
            product
        }).then((res) => {
            setProductList(res.data);
            setOpenLocationDropdown(false);
        })
    }
  

    const [searchData, setSearchData] = useState([])
    const [open2, setOpen2] = useState(false)

    const showSearchProduct = (id) => {
        const searchItem = products.filter((data) => {
            if (data.id === id) {
                return data
            }
        })
        setSearchData(searchItem)
    }

    const [selectedLocation, setSelectedLocation] = useState('');  // Selected city
    const [locations, setLocations] = useState([]);  // List of unique locations
    const [openLocationDropdown, setOpenLocationDropdown] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    // Function to extract unique locations from products
    const getUniqueLocations = (products) => {
        const locations = products.map(product => product.city);
        return [...new Set(locations)];  // Remove duplicates
    };

    // Toggle dropdown visibility
    const handleLocationClick = () => {
        const uniqueLocations = getUniqueLocations(products);
        setLocations(uniqueLocations);  // Set locations only when dropdown is opened
        setOpenLocationDropdown(!openLocationDropdown);  // Toggle dropdown state
    };

    // Handle city selection
    const handleLocationSelect = (city) => {
        setSelectedLocation(city);  // Set the selected city
        setOpenLocationDropdown(false);  // Close the dropdown after selection
    };

    // Filter products based on selected city
    const filteredProducts = selectedLocation
        ? products.filter((product) => product.city === selectedLocation)
        : products;
    

    return (
        <div className='card nav-container' style={{ width: '100%', height: '7rem', background: 'red', borderRadius: '0.0003rem', border: 'none', justifyContent: 'center', position: 'fixed', top: '0px', zIndex: '10' }} >
            <div className='container-fluid card d-none d-lg-flex' style={{ borderRadius: '0.0rem', border: 'none', backgroundColor: 'red', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }} >

                <div style={{ width: '22rem', paddingLeft: 7 }} className='v-con-logo-con d-none d-lg-block'>
                    <div style={{ position: 'relative', top: '-0.3rem' ,backgroundColor:'white',borderRadius:'20px' }} >
                        <Typography onClick={() => navigate('/')} className='v-con-logo' style={{ color: 'red', fontSize: '1.4rem', fontWeight: '800', }} ><img src={icon1} style={{height:'45px',width: '45px', borderRadius: '0.1rem', marginRight: 3, position: 'relative', top: '0.2rem' }} />E-VyaparExchange<span className='v-store-t' style={{ marginTop: 3, fontSize: '1.1rem' }}>LLP</span></Typography>
                        <div style={{ width: '7.3rem', height: '0.19rem', backgroundColor: 'white', position: 'relative', top: '-0.2rem', left: 34, borderRadius: '0.4rem', }} ></div>
                    </div>
                </div>

                <div className='d-flex' style={{ width: '18rem', border: 'none', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', backgroundColor: 'red' }}>
                    <Typography onClick={() => navigate('/About')} className='menu-link' style={{ color: 'white' }}>About</Typography>
                    <a onClick={handleClickOpen} style={{ textDecoration: 'none', color: 'white' }} ><Typography className='menu-link' style={{ color: 'white' }}>Download App</Typography></a>
                    <Typography onClick={() => navigate('/Contact')} className='menu-link' style={{ color: 'white' }}>Contact</Typography>
                </div>

                {/* <div style={{ position: 'relative', backgroundColor: 'white', borderRadius: '1.2rem', paddingLeft: 8, paddingRight: 8, display: 'flex', alignItems: 'center' }} >
                    <SearchRoundedIcon />
                    <input onClick={handleClickOpen1} readOnly className='ps-3' style={{ background: 'white', border: 'none', height: '2.6rem', width: '27rem', borderRadius: '1.2rem', outline: 'none' }} placeholder='Search Vyapar Products/ City' ></input>
                    <ListRoundedIcon style={{ marginTop: -2 }} />
                </div> */}
                    <div style={{ position: 'relative', width:'350px', backgroundColor: 'white', borderRadius: '0.3rem', paddingLeft: 8, paddingRight: 8, display: 'flex', alignItems: 'center' }} >
                                <SearchRoundedIcon style={{ color: 'black' }} />
                                <input onClick={handleClickOpen1} readOnly className='ps-1' style={{ background: 'white', border: 'none', height: '2.6rem', width: '27rem', borderRadius: '0.3rem', outline: 'none' }} placeholder='Search Vyapar Products' ></input>
                                <ListRoundedIcon style={{ marginTop: -2, color: 'black' }} />
                            </div>

                <div className='sign-in-btn p-2' onClick={() => navigate('/login')}  >
                    <Typography style={{ border: 'none', color: 'white', fontWeight: '700' }} ><PersonAddAltRoundedIcon style={{ position: 'relative', top: '-0.1rem' }} /> Sign In</Typography>
                </div>
            </div>

            <div className='container-fluid card d-flex d-lg-none' style={{ borderRadius: '0.0rem', border: 'none', backgroundColor: 'red', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }} >
                <div style={{ position: 'relative', borderRadius: '0.2rem', width: '100%', paddingBottom: 10, justifyContent: 'space-between', display: 'flex' }} >
                    <div style={{ width: '17rem', }} className='v-con-logo-con d-flex'>
                        <div className='d-flex' style={{ position: 'relative', top: '-0.3rem' }} >
                            {/* <MenuOutlinedIcon style={{ fontSize: 22, color: 'white', position: 'relative', top: 5.2, marginRight: 10 }} /> */}
                            <Typography style={{ color: 'white', fontSize: '1.2rem', fontWeight: '600', }} >E-VyaparExchange<span className='v-store-t' style={{ marginTop: 3, fontSize: '1rem' }}>LLP</span></Typography>
                        </div>
                    </div>
                    <Typography onClick={() => navigate('/login')} style={{ border: 'none', color: 'white', fontWeight: '600', fontSize: 14 }} ><PersonAddAltRoundedIcon style={{ position: 'relative', top: '-0.1rem' }} /> Login</Typography>
                </div>
            </div>

            <div className='container-fluid card d-flex d-lg-none' style={{ borderRadius: '0.0rem', border: 'none', backgroundColor: 'red', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }} >
                <div style={{ position: 'relative', backgroundColor: 'white', borderRadius: '0.2rem', paddingLeft: 8, paddingRight: 8, width: '100%', display: 'flex', alignItems: 'center' }} >
                    <SearchRoundedIcon style={{ fontSize: 25, color: 'gray', marginTop: -2 }} />
                    <input onClick={handleClickOpen1} readOnly className='ps-1' style={{ background: 'white', border: 'none', height: '2.6rem', width: '16rem', borderRadius: '0.2rem', outline: 'none' }} placeholder='Search Vyapar Products/ City' ></input>
                </div>
            </div>

            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth='lg'
                >
                    <DialogContent>
                        <div style={{ backgroundColor: 'rgb(255, 87, 87)', width: '100%', padding: 10 }} >
                            <div style={{ marginBottom: 17 }} >
                                <Typography style={{ fontSize: '2rem', color: 'white', fontWeight: 'bold' }} >Get Start With Vyapar Connect App</Typography>
                                <Typography style={{ color: 'white' }} > Download Vyapar connect app</Typography>
                            </div>
                            <a target='__blank' href="https://play.google.com/store/apps/details?id=com.emedhatec.vyaparconnect" ><img src="https://img.udaan.com/v2/f_auto,q_auto:eco,w_240/u/assets/fqemcqvzvzexrrlijuo7.png" /></a>
                            <img src="https://img.udaan.com/v2/f_auto,q_auto:eco,w_240/u/assets/c1fds2uovbd7q3udrpc5.png" />

                        </div>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={handleClose} autoFocus>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <div>
                <Dialog
                    fullScreen={fullScreen}
                    open={open1}
                    onClose={handleClose1}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth='lg'
                >
                    {/* <DialogContent>
                        <div style={{ padding: 4, width: '20rem', height: '31rem' }} >
                            <div style={{ position: 'relative', backgroundColor: 'white', borderRadius: '0.3rem', marginBottom: 16, dispaly: 'flex', alignItems: 'center' }} className='d-flex align-items-center'>

                                <input onChange={(e) => searchProduct(e.target.value)} onClick={handleClickOpen1} className='ps-1 d-flex align-items-center' style={{ background: 'white', border: 'none', height: '2.6rem', width: '100%', outline: 'none', borderBottom: '2px solid gray' }} placeholder='Search Vyaparr pProducts' ></input>
                                <SearchRoundedIcon style={{ color: 'black', position: 'absolute', right: '3px', top: '9px' }} />
                            </div>
                            {
                                products.length !== 0 && (
                                    <div style={{ justifyContent: 'center', alignItems: 'center' }}  >
                                        <Typography style={{ color: 'gray', marginBottom: 14 }} >Click to more details</Typography>
                                        {
                                            products.slice(0, 8).map((data) => {
                                                return (
                                                    <Box onClick={() => {
                                                        setOpen2(true)
                                                        setOpen1(false)
                                                        showSearchProduct(data.id)
                                                    }} className='search-prpduct' sx={{ display: 'flex', marginBottom: 2, borderBottom: '1px solid lightgray', padding: 1 }} >
                                                        <div style={{ width: 89, overflow: 'hidden', marginLeft: 7 }} >
                                                            <img style={{ height: 43 }} className="img" src={`https://vyaparapi.emedha.in/images/${data.pPhoto}`} alt="1004" />
                                                        </div>
                                                        <Typography>
                                                            <Typography>{data.pName}</Typography>
                                                            <Typography>{data.city}</Typography>
                                                            <Typography style={{ fontSize: 12, fontWeight: 'bold' }} ><FaRupeeSign style={{ fontSize: 12, position: 'relative', top: '-0.1rem' }} />{data.pPrice - data.discount / 100 * data.pPrice} /-</Typography>
                                                        </Typography>
                                                    </Box>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }
                            {
                                products.length === 0 && (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}  >


                                        <Box>
                                            <Typography>Search Your Products</Typography>
                                        </Box>

                                    </div>
                                )
                            }
                        </div>
                    </DialogContent> */}
                    <DialogContent>
                        <div style={{ padding: 4, width: '20rem', height: '31rem' }}>
                            <div style={{ position: 'relative', backgroundColor: 'white', borderRadius: '0.3rem', marginBottom: 16 }}>
                                <input
                                    onChange={(e) => searchProduct(e.target.value)}
                                    onClick={handleClickOpen1}
                                    className="ps-1 d-flex align-items-center"
                                    style={{
                                        background: 'white',
                                        border: 'none',
                                        height: '2.6rem',
                                        width: '100%',
                                        outline: 'none',
                                        borderBottom: '2px solid gray',
                                    }}
                                    placeholder="Search Vyapar Products/ City"
                                />
                                <SearchRoundedIcon style={{ color: 'black', position: 'absolute', right: '3px', top: '9px' }} />
                            </div>

                            {/* Location Button */}
                            {/* <Button onClick={handleLocationClick} style={{ marginBottom: '10px' }}>
                                {selectedLocation ? `${selectedLocation}` : 'Select Your City'}
                            </Button> */}

                            {/* Location Dropdown */}
                            {openLocationDropdown && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        backgroundColor: 'white',
                                        border: '1px solid gray',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        zIndex: 100,
                                        width: '70%',
                                    }}
                                >
                                    <ul style={{ padding: 0, listStyleType: 'none' }}>
                                        {locations.map((city, index) => (
                                            <li
                                                key={index}
                                                onClick={() => handleLocationSelect(city)}
                                                style={{
                                                    cursor: 'pointer',
                                                    padding: '5px',
                                                    borderBottom: '1px solid #ddd',
                                                }}
                                            >
                                                {city}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            {/* Displaying filtered products */}
                            <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                                <Typography style={{ color: 'gray', marginBottom: 14 }}>Click to view more details</Typography>
                                {filteredProducts.slice(0, 8).map((data) => (
                                    <Box
                                        onClick={() => {
                                            setOpen2(true);
                                            setOpen1(false);
                                            showSearchProduct(data.id);
                                        }}
                                        className="search-prpduct"
                                        sx={{
                                            display: 'flex',
                                            marginBottom: 2,
                                            borderBottom: '1px solid lightgray',
                                            padding: 1,
                                        }}
                                    >
                                        <div style={{ width: 89, overflow: 'hidden', marginLeft: 7 }}>
                                            <img
                                                style={{ height: 43 }}
                                                className="img"
                                                src={`https://vyaparapi.emedha.in/images/${data.pPhoto}`}
                                                alt={data.pName}
                                            />
                                        </div>
                                        <Typography>
                                            <Typography>{data.pName}</Typography>
                                            <Typography>{data.city}</Typography>
                                            <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>
                                                <FaRupeeSign style={{ fontSize: 12, position: 'relative', top: '-0.1rem' }} />
                                                {data.pPrice - (data.discount / 100) * data.pPrice} /-
                                            </Typography>
                                        </Typography>
                                    </Box>
                                ))}
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button style={{ backgroundColor: 'red', color: 'white' }} onClick={handleClose1} autoFocus>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
                {
                    open2 && (
                        <SearchItem searchData={searchData} open2={open2} setOpen2={setOpen2} />
                    )
                }
            </div>

        </div>
    )
}

export default Navigation





// return (
//     <div className='card nav-container' style={{ width: '100%', height: '7rem', background: 'red', borderRadius: '0.0003rem', border: 'none', justifyContent: 'center', position: 'fixed', top: '0px', zIndex: '10' }} >
//         <Navbar variant='dark' style={{ paddingLeft: 0, color: 'white', backgroundColor: 'red', height: 'fit-content' }} fixed="top" className="navbar1  d-md-flex d-none flex-row  align-items-center justify-content-between">

//             <div className='nav-width1 mobile-space'>

//                 <Navbar className="d-flex flex-row  align-items-center justify-content-between flex-wrap ">
//                     <div className='container-fluid card d-none d-lg-flex' style={{ borderRadius: '0.0rem', border: 'none', backgroundColor: 'red', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }} >

//                         <div style={{ width: '22rem', paddingLeft: 7 }} className='v-con-logo-con d-none d-lg-block'>
//                             <div style={{ position: 'relative', top: '-0.3rem' }} >
//                                 <Typography className='v-con-logo' style={{ color: 'white', fontSize: '1.4rem', fontWeight: '800', }} ><img src={icon} style={{ height: '50px', width: '45px', borderRadius: '0.1rem', marginRight: 3, position: 'relative', top: '0.2rem' }} />E-VyaparExchange<span className='v-store-t' style={{ marginTop: 3, fontSize: '1.1rem' }}>LLP</span></Typography>
//                                 <div style={{ width: '7.3rem', height: '0.19rem', backgroundColor: 'white', position: 'relative', top: '-0.2rem', left: 34, borderRadius: '0.4rem', }} ></div>
//                             </div>
//                         </div>

//                         {/* <div className='d-flex' style={{ width: '18rem', border: 'none', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', backgroundColor: 'red' }}>
//                 <Typography onClick={() => navigate('/About')} className='menu-link' style={{ color: 'white' }}>About</Typography>
//                 <a onClick={handleClickOpen} style={{ textDecoration: 'none', color: 'white' }} ><Typography className='menu-link' style={{ color: 'white' }}>Download App</Typography></a>
//                 <Typography onClick={() => navigate('/Contact')} className='menu-link' style={{ color: 'white' }}>Contact</Typography>
//             </div> */}



//                         <div style={{ marginBottom: '10px', position: 'relative', backgroundColor: 'white', borderRadius: '0.3rem', paddingLeft: 8, paddingRight: 8, display: 'flex', alignItems: 'center' }} >
//                             <SearchRoundedIcon style={{ color: 'black' }} />
//                             <input onClick={handleClickOpen1} readOnly className='ps-1' style={{ background: 'white', border: 'none', height: '2.6rem', width: '27rem', borderRadius: '0.3rem', outline: 'none' }} placeholder='Search Vyapar Products' ></input>
//                             <ListRoundedIcon style={{ marginTop: -2, color: 'black' }} />
//                         </div>

//                         <div className=' sign-in-btn p-2' onClick={() => navigate('/login')} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginBottom: '10px', marginLeft: '200px' }}  >
//                             <Typography style={{ border: 'none', color: 'white', fontWeight: '700' }} ><PersonAddAltRoundedIcon style={{ position: 'relative', top: '-0.1rem' }} /> Sign In</Typography>
//                         </div>
                       
//                     </div>
                  
//                 </Navbar>
                
//             </div>
//         </Navbar>

//         {/* Mobile */}
//         <div className='container-fluid card d-flex d-lg-none' style={{ borderRadius: '0.0rem', border: 'none', backgroundColor: 'red', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }} >
//             <div style={{ position: 'relative', borderRadius: '0.2rem', width: '100%', paddingBottom: 10, justifyContent: 'space-between', display: 'flex' }} >
//                 <div style={{ width: '17rem', }} className='v-con-logo-con d-flex'>
//                     <div className='d-flex' style={{ position: 'relative', top: '-0.3rem' }} >
//                         {/* <MenuOutlinedIcon style={{ fontSize: 22, color: 'white', position: 'relative', top: 5.2, marginRight: 10 }} /> */}
//                         <Typography style={{ color: 'white', fontSize: '1.2rem', fontWeight: '600', }} >E-VyaparExchange<span className='v-store-t' style={{ marginTop: 3, fontSize: '1rem' }}>LLP</span></Typography>
//                     </div>
//                 </div>
//                 <Typography onClick={() => navigate('/login')} style={{ border: 'none', color: 'white', fontWeight: '600', fontSize: 14 }} ><PersonAddAltRoundedIcon style={{ position: 'relative', top: '-0.1rem' }} /> Login</Typography>
//             </div>
//         </div>
//         {/* Desktop */}
//         <div className='d-none d-md-block '>
//             <Navbar variant='light' style={{ paddingLeft: 10, color: 'white' }} fixed="top" className="navbar2 d-flex flex-row justify-content-between">


//                 <div className='large-screen2 col-md-12 col-xl-11 mx-auto d-md-flex d-none'>
//                     <center>
//                         <Navbar.Collapse id="basic-navbar-nav" >
//                             <Nav style={{ marginRight: 15, color: 'black' }}>
//                                 <Typography onClick={() => navigate('/About')} style={{ cursor: 'pointer', marginRight: 15, textDecoration: 'none', fontWeight: 400 }} className='nav-2-items'>About</Typography>
//                                 <Typography onClick={handleClickOpen} style={{ cursor: 'pointer', marginRight: 15, textDecoration: 'none', fontWeight: 400 }} className='nav-2-items'>Download App</Typography  >
//                                 <Typography onClick={() => navigate('/Contact')} style={{ cursor: 'pointer', marginRight: 15, textDecoration: 'none', fontWeight: 400 }} className='nav-2-items'>Contact </Typography>
//                             </Nav>
//                         </Navbar.Collapse>
//                     </center>
//                 </div>


//             </Navbar>
//         </div>
//         <div className='d-md-none d-block'>
//             <Navbar variant='light' style={{ color: 'white', width: '100%', position: 'fixed', top: '2.8rem', left: 0, right: 0, height: '1.8rem' }} className=" navbar2 d-flex flex-row justify-content-between p-1">
//                 <div style={{ color: 'black', justifyContent: 'space-between', margin: '10px', alignItems: 'center', width: '80%' }} className='d-flex'>
//                     <Typography onClick={() => navigate('/About')}
//                         style={{ cursor: 'pointer', fontSize: 10.5, textDecoration: 'none', fontWeight: 400 }} className='nav-2-items'>About</Typography>
//                     <Typography onClick={handleClickOpen} style={{ cursor: 'pointer', fontSize: 10.5, textDecoration: 'none', fontWeight: 400 }} className='nav-2-items'>Download App</Typography>
//                     <Typography onClick={() => navigate('/Contact')} style={{ cursor: 'pointer', fontSize: 10.5, textDecoration: 'none', fontWeight: 400 }} className='nav-2-items'>Contact</Typography  >




//                 </div>


//             </Navbar>
//         </div>

//         <div className='container-fluid card d-flex d-lg-none' style={{ borderRadius: '0.0rem', border: 'none', backgroundColor: 'red', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }} >
//             <div style={{ position: 'relative', backgroundColor: 'white', borderRadius: '0.2rem', paddingLeft: 8, paddingRight: 8, width: '100%', display: 'flex', alignItems: 'center' }} >
//                 <SearchRoundedIcon style={{ fontSize: 25, color: 'gray', marginTop: -2 }} />
//                 <input onClick={handleClickOpen1} readOnly className='ps-1' style={{ background: 'white', border: 'none', height: '2.6rem', width: '16rem', borderRadius: '0.2rem', outline: 'none' }} placeholder='Search Vyapar Products/ City' ></input>
//             </div>
//         </div>

//         <div>
//             <Dialog
//                 open={open}
//                 onClose={handleClose}
//                 aria-labelledby="responsive-dialog-title"
//                 aria-describedby="alert-dialog-description"
//                 maxWidth='lg'
//             >
//                 <DialogContent>
//                     <div style={{ backgroundColor: 'rgb(255, 87, 87)', width: '100%', padding: 10 }} >
//                         <div style={{ marginBottom: 17 }} >
//                             <Typography style={{ fontSize: '2rem', color: 'white', fontWeight: 'bold' }} >Get Start With Vyapar Connect App</Typography>
//                             <Typography style={{ color: 'white' }} > Download Vyapar connect app</Typography>
//                         </div>
//                         <a target='__blank' href="https://play.google.com/store/apps/details?id=com.emedhatec.vyaparconnect" ><img src="https://img.udaan.com/v2/f_auto,q_auto:eco,w_240/u/assets/fqemcqvzvzexrrlijuo7.png" /></a>
//                         <img src="https://img.udaan.com/v2/f_auto,q_auto:eco,w_240/u/assets/c1fds2uovbd7q3udrpc5.png" />

//                     </div>
//                 </DialogContent>
//                 <DialogActions>

//                     <Button onClick={handleClose} autoFocus>
//                         Cancel
//                     </Button>
//                 </DialogActions>
//             </Dialog>
//         </div>
//         <div>
//             <Dialog
//                 fullScreen={fullScreen}
//                 open={open1}
//                 onClose={handleClose1}
//                 aria-labelledby="responsive-dialog-title"
//                 maxWidth='lg'
//             >
//                 {/* <DialogContent>
//                     <div style={{ padding: 4, width: '20rem', height: '31rem' }} >
//                         <div style={{ position: 'relative', backgroundColor: 'white', borderRadius: '0.3rem', marginBottom: 16, dispaly: 'flex', alignItems: 'center' }} className='d-flex align-items-center'>

//                             <input onChange={(e) => searchProduct(e.target.value)} onClick={handleClickOpen1} className='ps-1 d-flex align-items-center' style={{ background: 'white', border: 'none', height: '2.6rem', width: '100%', outline: 'none', borderBottom: '2px solid gray' }} placeholder='Search Vyaparr pProducts' ></input>
//                             <SearchRoundedIcon style={{ color: 'black', position: 'absolute', right: '3px', top: '9px' }} />
//                         </div>
//                         {
//                             products.length !== 0 && (
//                                 <div style={{ justifyContent: 'center', alignItems: 'center' }}  >
//                                     <Typography style={{ color: 'gray', marginBottom: 14 }} >Click to more details</Typography>
//                                     {
//                                         products.slice(0, 8).map((data) => {
//                                             return (
//                                                 <Box onClick={() => {
//                                                     setOpen2(true)
//                                                     setOpen1(false)
//                                                     showSearchProduct(data.id)
//                                                 }} className='search-prpduct' sx={{ display: 'flex', marginBottom: 2, borderBottom: '1px solid lightgray', padding: 1 }} >
//                                                     <div style={{ width: 89, overflow: 'hidden', marginLeft: 7 }} >
//                                                         <img style={{ height: 43 }} className="img" src={`https://vyaparapi.emedha.in/images/${data.pPhoto}`} alt="1004" />
//                                                     </div>
//                                                     <Typography>
//                                                         <Typography>{data.pName}</Typography>
//                                                         <Typography>{data.city}</Typography>
//                                                         <Typography style={{ fontSize: 12, fontWeight: 'bold' }} ><FaRupeeSign style={{ fontSize: 12, position: 'relative', top: '-0.1rem' }} />{data.pPrice - data.discount / 100 * data.pPrice} /-</Typography>
//                                                     </Typography>
//                                                 </Box>
//                                             )
//                                         })
//                                     }
//                                 </div>
//                             )
//                         }
//                         {
//                             products.length === 0 && (
//                                 <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}  >


//                                     <Box>
//                                         <Typography>Search Your Products</Typography>
//                                     </Box>

//                                 </div>
//                             )
//                         }
//                     </div>
//                 </DialogContent> */}
//                 <DialogContent>
//                     <div style={{ padding: 4, width: '20rem', height: '31rem' }}>
//                         <div style={{ position: 'relative', backgroundColor: 'white', borderRadius: '0.3rem', marginBottom: 16 }}>
//                             <input
//                                 onChange={(e) => searchProduct(e.target.value)}
//                                 onClick={handleClickOpen1}
//                                 className="ps-1 d-flex align-items-center"
//                                 style={{
//                                     background: 'white',
//                                     border: 'none',
//                                     height: '2.6rem',
//                                     width: '100%',
//                                     outline: 'none',
//                                     borderBottom: '2px solid gray',
//                                 }}
//                                 placeholder="Search Vyapar Products/ City"
//                             />
//                             <SearchRoundedIcon style={{ color: 'black', position: 'absolute', right: '3px', top: '9px' }} />
//                         </div>

//                         {/* Location Button */}
//                         {/* <Button onClick={handleLocationClick} style={{ marginBottom: '10px' }}>
//                             {selectedLocation ? `${selectedLocation}` : 'Select Your City'}
//                         </Button> */}

//                         {/* Location Dropdown */}
//                         {openLocationDropdown && (
//                             <div
//                                 style={{
//                                     position: 'absolute',
//                                     backgroundColor: 'white',
//                                     border: '1px solid gray',
//                                     padding: '10px',
//                                     borderRadius: '5px',
//                                     zIndex: 100,
//                                     width: '70%',
//                                 }}
//                             >
//                                 <ul style={{ padding: 0, listStyleType: 'none' }}>
//                                     {locations.map((city, index) => (
//                                         <li
//                                             key={index}
//                                             onClick={() => handleLocationSelect(city)}
//                                             style={{
//                                                 cursor: 'pointer',
//                                                 padding: '5px',
//                                                 borderBottom: '1px solid #ddd',
//                                             }}
//                                         >
//                                             {city}
//                                         </li>
//                                     ))}
//                                 </ul>
//                             </div>
//                         )}

//                         {/* Displaying filtered products */}
//                         <div style={{ justifyContent: 'center', alignItems: 'center' }}>
//                             <Typography style={{ color: 'gray', marginBottom: 14 }}>Click to view more details</Typography>
//                             {filteredProducts.slice(0, 8).map((data) => (
//                                 <Box
//                                     onClick={() => {
//                                         setOpen2(true);
//                                         setOpen1(false);
//                                         showSearchProduct(data.id);
//                                     }}
//                                     className="search-prpduct"
//                                     sx={{
//                                         display: 'flex',
//                                         marginBottom: 2,
//                                         borderBottom: '1px solid lightgray',
//                                         padding: 1,
//                                     }}
//                                 >
//                                     <div style={{ width: 89, overflow: 'hidden', marginLeft: 7 }}>
//                                         <img
//                                             style={{ height: 43 }}
//                                             className="img"
//                                             src={`https://vyaparapi.emedha.in/images/${data.pPhoto}`}
//                                             alt={data.pName}
//                                         />
//                                     </div>
//                                     <Typography>
//                                         <Typography>{data.pName}</Typography>
//                                         <Typography>{data.city}</Typography>
//                                         <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>
//                                             <FaRupeeSign style={{ fontSize: 12, position: 'relative', top: '-0.1rem' }} />
//                                             {data.pPrice - (data.discount / 100) * data.pPrice} /-
//                                         </Typography>
//                                     </Typography>
//                                 </Box>
//                             ))}
//                         </div>
//                     </div>
//                 </DialogContent>
//                 <DialogActions>
//                     <Button style={{ backgroundColor: 'red', color: 'white' }} onClick={handleClose1} autoFocus>
//                         Cancel
//                     </Button>
//                 </DialogActions>
//             </Dialog>
//             {
//                 open2 && (
//                     <SearchItem searchData={searchData} open2={open2} setOpen2={setOpen2} />
//                 )
//             }
//         </div>

//     </div>
// )






// import React, { useState } from 'react';
// import axios from 'axios';
// import { DialogContent, Button, Typography, Box } from '@mui/material';
// import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
// import { FaRupeeSign } from 'react-icons/fa';

// const ProductSearchComponent = () => {
//   const [searchTerm, setSearchTerm] = useState(''); // Search input
//   const [productList, setProductList] = useState([]); // List of products
//   const [selectedLocation, setSelectedLocation] = useState(''); // Selected city
//   const [locations, setLocations] = useState([]); // List of unique locations
//   const [openLocationDropdown, setOpenLocationDropdown] = useState(false); // Dropdown visibility
//   const [loading, setLoading] = useState(false); // Loading state
//   const [error, setError] = useState(''); // Error handling

//   // Function to search for products
//   const searchProduct = (product) => {
//     if (product === '') {
//       setProductList([]);
//       setSelectedLocation('');
//       setOpenLocationDropdown(false);
//       return;
//     }
//     setLoading(true);
//     axios
//       .post('http://localhost:3000/search-product', { product })
//       .then((res) => {
//         setProductList(res.data);
//         setOpenLocationDropdown(false);
//         setLoading(false);
//         const uniqueLocations = getUniqueLocations(res.data); // Extract unique locations
//         setLocations(uniqueLocations);
//       })
//       .catch((err) => {
//         setLoading(false);
//         setError('An error occurred while fetching data.');
//         console.error(err);
//       });
//   };

//   // Function to extract unique locations from products
//   const getUniqueLocations = (products) => {
//     const locationList = products.map((product) => product.city);
//     return [...new Set(locationList)]; // Remove duplicates
//   };

//   // Toggle dropdown visibility for locations
//   const handleLocationClick = () => {
//     setOpenLocationDropdown(!openLocationDropdown);
//   };

//   // Handle city selection
//   const handleLocationSelect = (city) => {
//     setSelectedLocation(city);
//     setOpenLocationDropdown(false);
//   };

//   // Filter products based on selected city
//   const filteredProducts = selectedLocation
//     ? productList.filter((product) => product.city === selectedLocation)
//     : productList;

//   return (
//     <DialogContent>
//       <div style={{ padding: 4, width: '20rem', height: '31rem' }}>
//         {/* Search Input */}
//         <div style={{ position: 'relative', backgroundColor: 'white', borderRadius: '0.3rem', marginBottom: 16 }}>
//           <input
//             onChange={(e) => {
//               setSearchTerm(e.target.value);
//               searchProduct(e.target.value);
//             }}
//             className="ps-1 d-flex align-items-center"
//             style={{
//               background: 'white',
//               border: 'none',
//               height: '2.6rem',
//               width: '100%',
//               outline: 'none',
//               borderBottom: '2px solid gray',
//             }}
//             placeholder="Search Vyaparr Products"
//           />
//           <SearchRoundedIcon style={{ color: 'black', position: 'absolute', right: '3px', top: '9px' }} />
//         </div>

//         {/* Location Button */}
//         <Button onClick={handleLocationClick} style={{ marginBottom: '10px' }}>
//           {selectedLocation ? `${selectedLocation}` : 'Select Your State'}
//         </Button>

//         {/* Location Dropdown */}
//         {openLocationDropdown && (
//           <div
//             style={{
//               position: 'absolute',
//               backgroundColor: 'white',
//               border: '1px solid gray',
//               padding: '10px',
//               borderRadius: '5px',
//               zIndex: 100,
//               width: '70%',
//             }}
//           >
//             <ul style={{ padding: 0, listStyleType: 'none' }}>
//               {locations.map((city, index) => (
//                 <li
//                   key={index}
//                   onClick={() => handleLocationSelect(city)}
//                   style={{
//                     cursor: 'pointer',
//                     padding: '5px',
//                     borderBottom: '1px solid #ddd',
//                   }}
//                 >
//                   {city}
//                 </li>
//               ))}
//             </ul>
//           </div>
//         )}

//         {/* Loading State */}
//         {loading && <Typography>Loading...</Typography>}

//         {/* Error Message */}
//         {error && <Typography color="error">{error}</Typography>}

//         {/* Displaying filtered products */}
//         <div style={{ justifyContent: 'center', alignItems: 'center' }}>
//           <Typography style={{ color: 'gray', marginBottom: 14 }}>Click to view more details</Typography>
//           {filteredProducts.slice(0, 8).map((product) => (
//             <Box
//               onClick={() => {
//                 // Handle product click (you can define your own function to show more details)
//                 console.log('Selected product:', product);
//               }}
//               className="search-product"
//               sx={{
//                 display: 'flex',
//                 marginBottom: 2,
//                 borderBottom: '1px solid lightgray',
//                 padding: 1,
//               }}
//               key={product.id}
//             >
//               <div style={{ width: 89, overflow: 'hidden', marginLeft: 7 }}>
//                 <img
//                   style={{ height: 43 }}
//                   className="img"
//                   src={`https://vyaparapi.emedha.in/images/${product.pPhoto}`}
//                   alt={product.pName}
//                 />
//               </div>
//               <Typography>
//                 <Typography>{product.pName}</Typography>
//                 <Typography>{product.city}</Typography>
//                 <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>
//                   <FaRupeeSign style={{ fontSize: 12, position: 'relative', top: '-0.1rem' }} />
//                   {product.pPrice - (product.discount / 100) * product.pPrice} /-
//                 </Typography>
//               </Typography>
//             </Box>
//           ))}
//         </div>
//       </div>
//     </DialogContent>
//   );
// };

// export default ProductSearchComponent;
