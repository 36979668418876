import React from "react";
import Slider from "react-slick";
import './smCrousel.css'


const SmallCarousel = () => {

    const settings = {
        dots: false,
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        arrows:false,
        adaptiveHeight: true,
    };


    return (
        <div className="bg-white" >

            <Slider {...settings}>
                <div className="p-2" >
                    <img className="w-100" src="https://www.jiomart.com/images/cms/aw_rbslider/slides/1690805055_Paytm_Wallet_1240x209.jpg?im=Resize=(1241,195)" />
                </div>
                <div className="p-2">
                    <img className="w-100" src="https://www.jiomart.com/images/cms/aw_rbslider/slides/1690805086_MobiKwik_1240x209.jpg?im=Resize=(1241,195)" />
                </div>
                <div className="p-2">
                    <img className="w-100" src="https://www.jiomart.com/images/cms/aw_rbslider/slides/1690805144_Simpl_1240x209.jpg?im=Resize=(1241,195)" />
                </div>
                <div className="p-2">
                    <img className="w-100" src="https://www.jiomart.com/images/cms/aw_rbslider/slides/1690805115_TWID_1240x209.jpg?im=Resize=(1241,195)" />
                </div>
            </Slider>

        </div>
    );

}

export default SmallCarousel