

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { GiHamburgerMenu } from 'react-icons/gi'
import { useAppStore } from '../AppStore/index'

// import { useState } from 'react';
import './index.css'
import { FiLogIn } from 'react-icons/fi'
import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { Routes, Route, Link } from 'react-router-dom';
import { IoMdClose } from 'react-icons/io';
import { TbLogout } from 'react-icons/tb'
import HomePage from '../HomePage';
import { AlertContainer, alert } from 'react-custom-alert';
import Dashboard from '../Dashboard';
import vyaparlogo from '../Images/vyaparlogo.png';




const AdminNavbar = () => {

    const onToggleSidebar = () => {
        updateState(!dopen)
    }

    const updateState = useAppStore((state) => state.updateOpen)

    const dopen = useAppStore((state) => state.dopen)

    console.log(dopen)

    const [isWebDevSideDrop, setIsSideWebDrop] = useState(false)

    const dropOpen1 = () => {
        setIsSideWebDrop(prevState => !prevState)
    }

    const [alert, setAlert] = useState(false)




    const onSetAlert = () => {
        const confirm = window.confirm("Are you sure,do you want to logout?");
        if (confirm) {
            sessionStorage.removeItem('key')
            sessionStorage.removeItem(`email`)
            window.location.reload()
            window.location.href = '/'
        } else {

            window.location.href = window.location.href;
        }
    }




    return (
        <>
            <Navbar variant='dark' style={{ paddingLeft: 10, color: 'white' }} fixed="top" className="navbar1 d-flex flex-row justify-content-between">

                <Navbar>
{/* 
                    <Navbar.Brand href="#home">
                        <img
                            src={vyaparlogo}
                            className='evyapar-logo'

                            alt="Evyapar logo"
                        />
                    </Navbar.Brand> */}

                </Navbar>

                <div className='large-screen'>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" >
                        <Nav className='nav-items' style={{ fontWeight: 'bold', marginRight: 15 }}>

                            <Link onClick={()=> onSetAlert()} style={{ textDecoration: 'none' }}>   <Nav style={{ fontWeight: 'bold', marginRight: 25 }}><Button style={{ backgroundColor: '#d9370d', width: 120, marginLeft: 20 }}>Logout </Button> </Nav></Link>

                        </Nav>
                    </Navbar.Collapse>
                </div>

            </Navbar>


            {/* 
            <Routes>
                <Route path='/' element={<HomePage></HomePage>}/>
            </Routes> */}
        </>
    );
}

export default AdminNavbar