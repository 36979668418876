// import { Typography } from "@mui/material";
// import React from "react";
// import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
// import './Style.css'

// const Footer = () => {
//     return (
//         <div className="footer">
//             <div style={{ backgroundColor: 'rgb(255, 87, 87)', width: '100%', padding: 10 }} >
//                 <div style={{ marginBottom: 17 }} >
//                     <Typography style={{ fontSize: '2rem', color: 'white', fontWeight: 'bold' }} >Get Start With Vyapar Connect App</Typography>
//                     <Typography style={{ color: 'white' }} > Download Vyapar connect app</Typography>
//                 </div>
//                 <a target='__blank' href="https://play.google.com/store/apps/details?id=com.emedhatec.vyaparconnect" ><img src="https://img.udaan.com/v2/f_auto,q_auto:eco,w_240/u/assets/fqemcqvzvzexrrlijuo7.png" /></a>
//                 <img src="https://img.udaan.com/v2/f_auto,q_auto:eco,w_240/u/assets/c1fds2uovbd7q3udrpc5.png" />

//             </div>
//             <div style={{ backgroundColor: 'white', height: '15rem', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
//                 <div>
//                     <div style={{display:'flex', justifyContent:'center', marginBottom:10}} >
//                         <FaInstagram style={{fontSize:'1.6rem'}} ></FaInstagram>
//                         <FaTwitter style={{fontSize:'1.6rem', marginLeft:10, marginRight:10}}></FaTwitter>
//                         <FaFacebook style={{fontSize:'1.6rem'}}></FaFacebook>
//                     </div>

//                     <div style={{ width: '100%', height: '0.2rem', backgroundColor: 'red', borderRadius: '0.6rem' }} ></div>
//                     <Typography className="text-center" >@2023 vyapar connect. all right reserved</Typography>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default Footer

import { Typography } from "@mui/material";
import React from "react";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import './Style.css'
import './index.css'
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="footer">
            <div style={{ backgroundColor: 'rgb(255, 87, 87)', width: '100%', padding: 10 }} >
                <div style={{ marginBottom: 17 }} >
                    <Typography style={{ fontSize: '2rem', color: 'white', fontWeight: 'bold' }} >Get Start With Vyapar Connect App</Typography>
                    <Typography style={{ color: 'white' }} > Download Vyapar connect app</Typography>
                </div>
                <a target='__blank' href="https://play.google.com/store/apps/details?id=com.emedhatec.vyaparconnect" ><img src="https://img.udaan.com/v2/f_auto,q_auto:eco,w_240/u/assets/fqemcqvzvzexrrlijuo7.png" /></a>
                <img src="https://img.udaan.com/v2/f_auto,q_auto:eco,w_240/u/assets/c1fds2uovbd7q3udrpc5.png" />

            </div>
            <div style={{ backgroundColor: 'white', height: '15rem', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }} >
                        <FaInstagram style={{ fontSize: '1.6rem' }} ></FaInstagram>
                        <FaTwitter style={{ fontSize: '1.6rem', marginLeft: 10, marginRight: 10 }}></FaTwitter>
                        <FaFacebook style={{ fontSize: '1.6rem' }}></FaFacebook>
                    </div>

                    <div style={{ width: '100%', height: '0.2rem', backgroundColor: 'red', borderRadius: '0.6rem' }} ></div>
                    <Typography className="text-center" >@2023 vyapar connect. all right reserved</Typography>
                    <div style={{ display: 'flex', alignItems: 'center',marginTop:'20px' }}>
                    <Link to='/privacy-policy' style={{textDecoration:'none'}}>   <p className='privacy-policy'>Privacy Policy</p></Link> 
                     <Link to='/refund-policy'  style={{textDecoration:'none'}}> <p className='privacy-policy'>Refund Policy</p></Link> 
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Footer