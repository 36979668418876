// import React, { useEffect, useState } from 'react';
// import { Container, Row, Col, Card, Button } from 'react-bootstrap';
// import { Typography, Box, CircularProgress } from '@mui/material';

// const UserProfile = () => {
//   const [userData, setUserData] = useState(null);
//   const [loading, setLoading] = useState(true);

//   // Get the email from sessionStorage
//   const email = sessionStorage.getItem('email');

//   useEffect(() => {
//     if (!email) {
//       console.error("No email in sessionStorage");
//       setLoading(false);
//       return;
//     }

//     // Fetch data from the API
//     fetch('http://localhost:3000/api/signup')
//       .then((response) => response.json())
//       .then((data) => {
//         // Filter the data based on the email in sessionStorage
//         const userProfile = data.find(user => user.email === email);
//         if (userProfile) {
//           setUserData(userProfile);
//         } else {
//           console.error('User profile not found for the given email');
//         }
//         setLoading(false);
//       })
//       .catch((error) => {
//         console.error('Error fetching user data:', error);
//         setLoading(false);
//       });
//   }, [email]);

//   // If the data is loading, display a loading spinner
//   if (loading) {
//     return (
//       <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
//         <CircularProgress />
//       </Box>
//     );
//   }

//   // If user data is not found, display an error message
//   if (!userData) {
//     return (
//       <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
//         <Typography variant="h6" color="error">
//           User data not found or not logged in!
//         </Typography>
//       </Box>
//     );
//   }

//   // Function to obfuscate name
//   const obfuscateName = (name) => {
//     if (!name) return 'No details provided';
//     return `${name.substring(0, 3)}*****${name.substring(name.length - 2)}`;
//   };

//   // Function to obfuscate phone number
//   const obfuscatePhoneNumber = (phone) => {
//     if (!phone) return 'No details provided';
//     return `******${phone.substring(phone.length - 4)}`;
//   };

//   // Function to obfuscate email
//   const obfuscateEmail = (email) => {
//     if (!email) return 'No details provided';
//     const [localPart, domain] = email.split('@');
//     return `${localPart.substring(0, 3)}*****@${domain.substring(0, 5)}*****.com`;
//   };

//   return (
//     <div style={{ marginTop: '130px' }}>
//       <Row className="justify-content-center">
//         <Col md={8}>
//           <Card>
//             <Card.Header>
//               <Typography variant="h4" component="div" align="center" style={{color:'red'}}>
//                 User Profile
//               </Typography>
//             </Card.Header>
//             <Card.Body>

//               {/* Personal Details Section */}
//               <Box sx={{ marginBottom: '20px' }}>
//                 <Typography variant="h6" sx={{ fontWeight: '600',color:'red' }}>
//                   Personal Details
//                 </Typography>
//                 <Row>
//                   <Col xs={12} md={6}>
//                     <Typography variant="body1" sx={{ fontWeight: 600 }}>
//                       Name:
//                     </Typography>
//                     <Typography variant="body1">{obfuscateName(userData.fullName)}</Typography>
//                   </Col>
//                   <Col xs={12} md={6}>
//                     <Typography variant="body1" sx={{ fontWeight: 600 }}>
//                       Email:
//                     </Typography>
//                     <Typography variant="body1">{obfuscateEmail(userData.email)}</Typography>
//                   </Col>
//                   <Col xs={12} md={6}>
//                     <Typography variant="body1" sx={{ fontWeight: 600 }}>
//                       Phone:
//                     </Typography>
//                     <Typography variant="body1">{obfuscatePhoneNumber(userData.mobile)}</Typography>
//                   </Col>
//                 </Row>
//               </Box>

//               {/* Address Section */}
//               <Box sx={{ marginBottom: '20px' }}>
//                 <Typography variant="h6" sx={{ fontWeight: '600',color:'red' }}>
//                   Address Information
//                 </Typography>
//                 <Row>
//                   <Col xs={12} md={6}>
//                     <Typography variant="body1" sx={{ fontWeight: 600 }}>
//                       City:
//                     </Typography>
//                     <Typography variant="body1">{userData.city || 'No details provided'}</Typography>
//                   </Col>
//                   <Col xs={12} md={6}>
//                     <Typography variant="body1" sx={{ fontWeight: 600 }}>
//                       State:
//                     </Typography>
//                     <Typography variant="body1">{userData.state || 'No details provided'}</Typography>
//                   </Col>
//                   <Col xs={12} md={6}>
//                     <Typography variant="body1" sx={{ fontWeight: 600 }}>
//                       Address:
//                     </Typography>
//                     <Typography variant="body1">{userData.address || 'No details provided'}</Typography>
//                   </Col>
//                 </Row>
//               </Box>

//               {/* Business Information Section */}
//               <Box sx={{ marginBottom: '20px' }}>
//                 <Typography variant="h6" sx={{ fontWeight: '600',color:'red' }}>
//                   Business Information
//                 </Typography>
//                 <Row>
//                   <Col xs={12} md={6}>
//                     <Typography variant="body1" sx={{ fontWeight: 600 }}>
//                       Business Vertical:
//                     </Typography>
//                     <Typography variant="body1">{userData.businessVertical || 'No details provided'}</Typography>
//                   </Col>
//                   <Col xs={12} md={6}>
//                     <Typography variant="body1" sx={{ fontWeight: 600 }}>
//                       Sub-Vertical:
//                     </Typography>
//                     <Typography variant="body1">{userData.subVertical || 'No details provided'}</Typography>
//                   </Col>
//                   <Col xs={12} md={6}>
//                     <Typography variant="body1" sx={{ fontWeight: 600 }}>
//                       Classification:
//                     </Typography>
//                     <Typography variant="body1">{userData.classification || 'No details provided'}</Typography>
//                   </Col>
//                   <Col xs={12} md={6}>
//                     <Typography variant="body1" sx={{ fontWeight: 600 }}>
//                       Company Name:
//                     </Typography>
//                     <Typography variant="body1">{userData.companyName || 'No details provided'}</Typography>
//                   </Col>
//                   <Col xs={12} md={6}>
//                     <Typography variant="body1" sx={{ fontWeight: 600 }}>
//                       Payment Status:
//                     </Typography>
//                     <Typography variant="body1">{userData.payment_status }</Typography>
//                   </Col>
//                 </Row>
//               </Box>

//               {/* Edit Profile Button */}
//               <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
//                 <Button variant="primary" onClick={() => alert('Edit functionality')}>
//                   Edit Profile
//                 </Button>
//               </Box>

//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>
//     </div>
//   );
// };

// export default UserProfile;


import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Typography, Box, CircularProgress } from '@mui/material';
import { Email, Phone, LocationOn, Business, Payment } from '@mui/icons-material';  // Importing icons

const UserProfile = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const email = sessionStorage.getItem('email');

  useEffect(() => {
    if (!email) {
      console.error("No email in sessionStorage");
      setLoading(false);
      return;
    }

    fetch('https://vyaparapi.emedha.in/api/signup')
      .then((response) => response.json())
      .then((data) => {
        const userProfile = data.find(user => user.email === email);
        if (userProfile) {
          setUserData(userProfile);
        } else {
          console.error('User profile not found for the given email');
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
        setLoading(false);
      });
  }, [email]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!userData) {
    return (
      <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
        <Typography variant="h6" color="error">
          User data not found or not logged in!
        </Typography>
      </Box>
    );
  }

  const obfuscateName = (name) => {
    if (!name) return 'No details provided';
    return `${name.substring(0, 3)}*****${name.substring(name.length - 2)}`;
  };

  const obfuscatePhoneNumber = (phone) => {
    if (!phone) return 'No details provided';
    return `******${phone.substring(phone.length - 4)}`;
  };

  const obfuscateEmail = (email) => {
    if (!email) return 'No details provided';
    const [localPart, domain] = email.split('@');
    return `${localPart.substring(0, 3)}*****@${domain.substring(0, 5)}.com`;
  };

  return (
    <div style={{ marginTop: '130px' }}>
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={6}>
          <Card className="shadow-sm">
            <Card.Header className="text-center  text-white" style={{backgroundColor:"red"}}>
              <Typography variant="h4" component="div">
                User Profile
              </Typography>
            </Card.Header>
            <Card.Body>
              {/* Personal Details Section */}
              <Box sx={{ marginBottom: '20px' }}>
                <Typography variant="h6" sx={{ fontWeight: 600, color: 'red' }}>
                  Personal Details
                </Typography>
                <Row className="g-3">
                  <Col xs={12} md={6}>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      <Email sx={{ marginRight: '8px', fontSize: '20px' }} />
                      Name:
                    </Typography>
                    <Typography variant="body1">{obfuscateName(userData.fullName)}</Typography>
                  </Col>
                  <Col xs={12} md={6}>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      <Email sx={{ marginRight: '8px', fontSize: '20px' }} />
                      Email:
                    </Typography>
                    <Typography variant="body1">{obfuscateEmail(userData.email)}</Typography>
                  </Col>
                  <Col xs={12} md={6}>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      <Phone sx={{ marginRight: '8px', fontSize: '20px' }} />
                      Phone:
                    </Typography>
                    <Typography variant="body1">{obfuscatePhoneNumber(userData.mobile)}</Typography>
                  </Col>
                </Row>
              </Box>

              {/* Address Section */}
              <Box sx={{ marginBottom: '20px' }}>
                <Typography variant="h6" sx={{ fontWeight: 600, color: 'red' }}>
                  Address Information
                </Typography>
                <Row className="g-3">
                <Col xs={12} md={6}>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      <LocationOn sx={{ marginRight: '8px', fontSize: '20px' }} />
                      Address:
                    </Typography>
                    <Typography variant="body1">{userData.address || 'No details provided'}</Typography>
                  </Col>
                  <Col xs={12} md={6}>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      <LocationOn sx={{ marginRight: '8px', fontSize: '20px' }} />
                      City:
                    </Typography>
                    <Typography variant="body1">{userData.city || 'No details provided'}</Typography>
                  </Col>
                  <Col xs={12} md={6}>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      <LocationOn sx={{ marginRight: '8px', fontSize: '20px' }} />
                      State:
                    </Typography>
                    <Typography variant="body1">{userData.state || 'No details provided'}</Typography>
                  </Col>
                 
                </Row>
              </Box>

              {/* Business Information Section */}
              <Box sx={{ marginBottom: '20px' }}>
                <Typography variant="h6" sx={{ fontWeight: 600, color: 'red' }}>
                  Business Information
                </Typography>
                <Row className="g-3">
                  <Col xs={12} md={6}>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      <Business sx={{ marginRight: '8px', fontSize: '20px' }} />
                      Business Vertical:
                    </Typography>
                    <Typography variant="body1">{userData.businessVertical || 'No details provided'}</Typography>
                  </Col>
                  <Col xs={12} md={6}>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      <Business sx={{ marginRight: '8px', fontSize: '20px' }} />
                      Sub-Vertical:
                    </Typography>
                    <Typography variant="body1">{userData.subVertical || 'No details provided'}</Typography>
                  </Col>
                  <Col xs={12} md={6}>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      <Business sx={{ marginRight: '8px', fontSize: '20px' }} />
                      Classification:
                    </Typography>
                    <Typography variant="body1">{userData.classification || 'No details provided'}</Typography>
                  </Col>
                  <Col xs={12} md={6}>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      <Business sx={{ marginRight: '8px', fontSize: '20px' }} />
                      Company Name:
                    </Typography>
                    <Typography variant="body1">{userData.companyName || 'No details provided'}</Typography>
                  </Col>
                  <Col xs={12} md={6}>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      <Payment sx={{ marginRight: '8px', fontSize: '20px' }} />
                      Payment Status:
                    </Typography>
                    <Typography variant="body1">{userData.payment_status}</Typography>
                  </Col>
                </Row>
              </Box>

              {/* Edit Profile Button */}
              {/* <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
                <Button style={{backgroundColor:'red'}} onClick={() => alert('Edit functionality')}>
                  Edit Profile
                </Button>
              </Box> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UserProfile;
