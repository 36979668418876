import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import axios from 'axios';
import { Button, Typography } from '@mui/material';

import Swal from "sweetalert2";

const AadharKyc = () => {

    const AadharUpdate = () => {
        Swal.fire({
            title: "Successful..!",
            cancelButtonText: 'Cancel',
            text: "Your aadhar number successfully updated...!",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Dashboard",
        }).then((result) => {
            if (result.value) {
                window.location.reload()
            } else {

            }
        });
    }

    const AadharTime = () => {
        Swal.fire({
            title: "Time Out..!",
            cancelButtonText: 'Cancel',
            text: "Please try again after some time ...!",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",

        }).then((result) => {
            if (result.value) {

            } else {

            }
        });
    }

    const AadharOtpError = () => {
        Swal.fire({
            title: "Somthing Wrong..!",
            text: "Please try again later ...!",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",

        }).then((result) => {
            if (result.value) {

            } else {

            }
        });
    }

    const [Aadhar, setAadhar] = useState('')
    const [AadharData, setAadharData] = useState('')
    const [AadharValidated, setAadharValidated] = useState(false)

    const handleSubmitAadhar = (event) => {

        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            generateOtp(Aadhar)
        }
        setAadharValidated(true);
    };

    const kyc = () => {
        const kycType = 'aadhar'
        axios.post('https://vyaparapi.emedha.in/kyc', {
            userId: sessionStorage.getItem('userId'),
            aadhar_number: Aadhar,
            kycType
        }).then((res) => {
            AadharUpdate()
        })
    }


    const aadharVerification = (otp) => {

        axios.post('https://vyaparapi.emedha.in/e-vyapar/v1/submit/otp', {
            ref_id,
            otp
        }).then((res) => {
            if (res.data.statusCode === 200) {
                kyc()
                setIsOtp(false)

            } else {
                AadharOtpError()
            }
        }).catch((err) => {
            AadharOtpError()
            console.log(err)
        })
    }


    const [isOtp, setIsOtp] = useState(false)
    const [otp, setOtp] = useState(null)
    const [ref_id, set_ref_id] = useState()

    const generateOtp = (aadharNumber) => {
        setWait4(true)
        axios.post('https://vyaparapi.emedha.in/e-vyapar/v1/generate/otp', {
            aadharNumber
        }).then((res) => {
            if (res.data.statusCode === 200) {
                setWait4(false)
                setIsOtp(true)
                set_ref_id(res.data.ref_id)
            } else {
                AadharTime()
                setWait4(false)
            }
        })
    }

    const [wait4, setWait4] = useState(false)
    const [isAadharStatus, setIsAadharStatus] = useState()

    const userKycCheck = () => {
        axios.post('https://vyaparapi.emedha.in/e-vyapar/pg/v1/p-status-check', {
            userId: sessionStorage.getItem('userId')
        }).then((res) => {
            setIsAadharStatus(res.data.data[0].isAadharKyc)
            setAadharData(res.data.data[0].AadharNumber)
        })
    }

    useEffect(() => {
        userKycCheck()
    }, [])

    return (
        <div>
            <div className='col-lg-12 mx-auto pb-4 p-1 pb-5 ' style={{ marginTop: '2rem' }} >
                {
                    !isOtp && (
                        <div>
                            <Form noValidate validated={AadharValidated} onSubmit={(e) => {
                                handleSubmitAadhar(e)
                            }} >
                                <Row className="mb-3">
                                    <center><img width='150px' src='https://th.bing.com/th/id/OIP.3CORWtSb3iA6AhikTCZEvwHaFH?w=239&h=180&c=7&r=0&o=5&dpr=2.5&pid=1.7' /></center>
                                    <h4>Aadhar Details</h4>
                                    <p>We need the ID proof of the business owner for verification purposes.</p>
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Label>Aadhar Number</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            disabled={(isAadharStatus !== 0)}
                                            placeholder={`${isAadharStatus === 0 ? "EX: 9475 7252 8356" : "XXXX XXXX " + AadharData.substring(AadharData.length + 4, 8)}`}
                                            style={{ padding: '0.8rem' }}
                                            onChange={(e) => {
                                                setAadhar(e.target.value)
                                            }}
                                        />
                                        {
                                            isAadharStatus !== 0 && (
                                                <Typography style={{ color: 'rgb(42, 179, 42)' }} >Verified <img src='https://cdn-icons-png.flaticon.com/128/3472/3472620.png' style={{ width: '16px' }} /></Typography>
                                            )
                                        }
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Aadhar Number
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <div style={{ height: '4rem', width: '19rem', backgroundColor: 'rgb(243, 242, 242)', marginBottom: '0.5rem', borderRadius: '0.4rem', padding: '0.2rem' }} >
                                    <span style={{ fontWeight: 'bold', color: 'black', fontSize: 20 }} >Note:</span> <span style={{ color: 'gray', fontSize: 13 }}> OTP will be sent to the mobile number linked to this aadhaar.</span>
                                </div>
                                {
                                    !wait4 && (
                                        <Button type='submit' variant="contained" style={{ backgroundColor: '#12124e', padding: '0.6rem', width: '9rem', marginTop: 59 }}>  Send OTP</Button>
                                    )
                                }
                                {
                                    wait4 && (
                                        <Button disabled={true} type='submit' variant="contained" style={{ padding: '0.6rem', width: '9rem', marginTop: 59 }}> Sending...</Button>
                                    )
                                }
                            </Form>
                        </div>
                    )
                }

                {
                    isOtp && (
                        <div>
                            <center><img width='150px' src='https://th.bing.com/th/id/OIP.3CORWtSb3iA6AhikTCZEvwHaFH?w=239&h=180&c=7&r=0&o=5&dpr=2.5&pid=1.7' /></center>
                            <h4>Verify</h4>
                            <p>We need the ID proof of the business owner for verification purposes.</p>
                            <Form.Group as={Col} md="10" controlId="validationCustom01">
                                <Form.Label>Enter OTP</Form.Label>

                                <Form.Control
                                    required
                                    type="text"
                                    value={otp}

                                    placeholder="Enter OTP"
                                    style={{ padding: '0.8rem' }}
                                    onChange={(e) => {
                                        setOtp(e.target.value)
                                    }}
                                />
                                <div style={{ height: '6rem', width: '23rem', backgroundColor: 'green', marginBottom: '0.5rem', borderRadius: '0.4rem', padding: '0.2rem', marginTop: '1rem' }} >
                                    <span style={{ fontWeight: 'bold', color: 'white', fontSize: 21 }} >Note:</span> <span style={{ color: 'white', fontSize: 15 }}> OTP has been sent to the your register mobile number please check and fallow geven instruction</span>
                                </div>
                                {/* <div className='d-flex' >
                                <button disabled={true} style={{ position: 'relative', right: '0.6rem', fontWeight: 'bold', color: 'rgb(19, 192, 19)', border: 'none', backgroundColor: 'white', marginTop: '0.2rem', borderRadius: '1rem' }} > <img style={{ height: '22px', width: '22px', position: 'relative', top: '-0.12rem' }} src='https://th.bing.com/th?id=OIP.8ZRKpc1B-_gDhkMlCOf5CwHaHa&w=250&h=250&c=8&rs=1&qlt=90&o=6&dpr=2.5&pid=3.1&rm=2' />Verified</button>
                            </div> */}
                            </Form.Group>
                        </div>
                    )
                }

                <div >
                    {

                        (isOtp) && <Button type='submit' onClick={() => aadharVerification(otp)} variant="contained" style={{ backgroundColor: '#12124e', padding: '0.6rem', width: '9rem', marginTop: '6rem' }}>  Verify</Button>
                    }
                </div>

            </div >
        </div >
    )
}

export default AadharKyc