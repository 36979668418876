import './index.css'
import { useAppStore } from '../AppStore/index'
import * as React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Modal from 'react-bootstrap/Modal';
import { GiHamburgerMenu } from 'react-icons/gi'
import './index.css'
import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { IoMdClose } from 'react-icons/io';
import { TbLogout } from 'react-icons/tb'
import { FaRupeeSign, FaSearch, FaUserCircle } from 'react-icons/fa';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import MyStore from '../MyStore'
import NewReferral from '../NewReferral';
import AllProducts from '../AllProducts';
import ReferralIn from '../ReferralIn';
import ReferralClose from '../ReferralClose';
import UpdateKyc from '../UpdateKyc';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import { Box, DialogContent } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Navigation from '../LandingPage/Navigation';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SearchItem from './SearchItem';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import { useEffect } from 'react';

import icon from './icon.png';
import icon1 from './icon1.png';

// import Footer from '../Footer/index'
import Footer from '../LandingPage/Footer';
import UserProfile from './UserProfile';




const Dashboard = () => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const navigate = useNavigate()

    const updateState = useAppStore((state) => state.updateOpen)

    const dopen = useAppStore((state) => state.dopen)


    const onToggleSidebar = () => {
        updateState(!dopen)
    }

    React.useEffect(() => {
        if (!sessionStorage.getItem('key')) {
            window.location.href = '/'
        }
    }, [])

    const onSetAlert = () => {
        const confirm = window.confirm("Are you sure,do you want to logout?");
        if (confirm) {
            sessionStorage.removeItem('key')
            sessionStorage.removeItem(`email`)
            window.location.reload()
            window.location.href = '/'

        } else {

            window.location.href = window.location.href;
        }
    }


    const [lgShow, setLgShow] = useState()

    const [searchInput, setSearchInput] = useState()

    const onChangeSearchInput = (e) => {
        setSearchInput(e.target.value)
    }


    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [isMyproduct, setIsMyproduct] = useState(false)
    const [isNewRefferal, setIsNewRefferal] = useState(false)
    const [isRefferalClose, setIsRefferalClose] = useState(false)
    const [isRefferalIn, setIsRefferalIn] = useState(false)
    const [isAddProduct, setIsAddProduct] = useState(false)
    const [isAllProduct, setIsAllProduct] = useState(true)
    const [isUpdateKyc, setIsUpdateKyc] = useState(false)
    const [isUserProfile, setIsUserProfile] = useState(false)
    
    const [open1, setOpen1] = React.useState(false);

    const handleClickOpen1 = () => {
        setOpen1(true);
    };

    const handleClose1 = () => {
        setProductList([]);
        setSelectedLocation('');
        setOpenLocationDropdown(false);
        setOpen1(false);
    };

    const [products, setProductList] = useState([])

    const searchProduct = (product) => {
        if (product === '') {
            setProductList([]);
            setSelectedLocation('');
            setOpenLocationDropdown(false);
            return
        }
        axios.post('https://vyaparapi.emedha.in/searchProduct', {
            product
        }).then((res) => {
            setProductList(res.data);
            setOpenLocationDropdown(false);
        })
    }

    const [searchData, setSearchData] = useState([])
    const [open2, setOpen2] = useState(false)

    const showSearchProduct = (id) => {
        const searchItem = products.filter((data) => {
            if (data.id === id) {
                return data
            }
        })
        setSearchData(searchItem)
    }

    const [signupData, setSignupData] = useState([]);
    const [fullName, setFullName] = useState(null);

    const fetchSignupData = async () => {
        try {
            const response = await axios.get("https://vyaparapi.emedha.in/api/signup");
            setSignupData(response.data);
        } catch (error) {
            console.error("Error fetching signup data:", error);
        }
    };

    // Use effect to fetch data when component mounts name
    useEffect(() => {
        fetchSignupData();
    }, []);

    useEffect(() => {
        const sessionName = sessionStorage.getItem("name"); // Retrieve the name from sessionStorage
        // console.log("Session Name from Storage:", sessionName); // Debugging: Check the value from sessionStorage

        if (signupData.length > 0) {
            // Filter signupData to find the user whose fullName matches the sessionName
            const matchedUser = signupData.find(user => user.fullName === sessionName);

            // console.log("Matched User:", matchedUser); // Debug: Check the matched user

            if (matchedUser) {
                setFullName(matchedUser.fullName); // Set the fullName state if match is found
            }
        }
    }, [signupData]); // Re-run whenever signupData changes

    // console.log(fullName)

    const [selectedLocation, setSelectedLocation] = useState('');  // Selected city
    const [locations, setLocations] = useState([]);  // List of unique locations
    const [openLocationDropdown, setOpenLocationDropdown] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    // Function to extract unique locations from products
    const getUniqueLocations = (products) => {
        const locations = products.map(product => product.city);
        return [...new Set(locations)];  // Remove duplicates
    };

    // Toggle dropdown visibility
    const handleLocationClick = () => {
        const uniqueLocations = getUniqueLocations(products);
        setLocations(uniqueLocations);  // Set locations only when dropdown is opened
        setOpenLocationDropdown(!openLocationDropdown);  // Toggle dropdown state
    };

    // Handle city selection
    const handleLocationSelect = (city) => {
        setSelectedLocation(city);  // Set the selected city
        setOpenLocationDropdown(false);  // Close the dropdown after selection
    };

    // Filter products based on selected city
    const filteredProducts = selectedLocation
        ? products.filter((product) => product.city === selectedLocation)
        : products;
    // console.log(openLocationDropdown)







    const [activeLink, setActiveLink] = useState('');

    return (
        <>
            <div>
                <>

                    {/* navbar after login */}

                    <div className='d-block d-md-none' >
                        <div className='card nav-container' style={{ width: '100%', height: '7rem', background: 'red', borderRadius: '0.0003rem', border: 'none', justifyContent: 'center', position: 'fixed', top: '0px', zIndex: '10' }} >
                            <div className='container-fluid card d-none d-lg-flex' style={{ borderRadius: '0.0rem', border: 'none', backgroundColor: 'red', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }} >

                                <div style={{ width: '17rem', paddingLeft: 7 }} className='v-con-logo-con d-none d-lg-block'>
                                    <div style={{ position: 'relative', top: '-0.3rem' }} >
                                        <Typography className='v-con-logo' style={{ color: 'white', fontSize: '1.4rem', fontWeight: '800', }} ><img src='https://th.bing.com/th/id/OIP.yexM5GOQwF5P104LDy9G8wHaHa?w=177&h=180&c=7&r=0&o=5&dpr=2.5&pid=1.7' style={{ width: '27px', borderRadius: '0.1rem', marginRight: 3, position: 'relative', top: '0.2rem' }} />E-VyaparExchange<span className='v-store-t' style={{ marginTop: 3, fontSize: '1.1rem' }}>LLP</span></Typography>
                                        <div style={{ width: '7.3rem', height: '0.19rem', backgroundColor: 'white', position: 'relative', top: '-0.2rem', left: 34, borderRadius: '0.4rem', }} ></div>
                                    </div>
                                </div>

                                <div style={{ position: 'relative', backgroundColor: 'white', borderRadius: '1.2rem', paddingLeft: 8, paddingRight: 8, display: 'flex', alignItems: 'center' }} >
                                    <SearchRoundedIcon />
                                    <input onClick={handleClickOpen1} readOnly className='ps-3' style={{ background: 'white', border: 'none', height: '2.6rem', width: '27rem', borderRadius: '1.2rem', outline: 'none' }} placeholder='Search Vyapar Products' ></input>
                                    <ListRoundedIcon style={{ marginTop: -2 }} />
                                </div>
                                <div className='sign-in-btn p-2' onClick={() => navigate('/login')}  >
                                    <Typography style={{ border: 'none', color: 'white', fontWeight: '700' }} ><PersonAddAltRoundedIcon style={{ position: 'relative', top: '-0.1rem' }} /> Sign In</Typography>
                                </div>
                            </div>

                            <div className='container-fluid card d-flex d-lg-none' style={{ borderRadius: '0.0rem', border: 'none', backgroundColor: 'red', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }} >
                                <div style={{ position: 'relative', borderRadius: '0.2rem', width: '100%', paddingBottom: 10, justifyContent: 'space-between', display: 'flex' }} >
                                    <div style={{ width: '16rem', }} className='v-con-logo-con d-flex'>
                                        <div className='d-flex' style={{ position: 'relative', top: '0.9rem' }} >
                                            {/* <MenuOutlinedIcon style={{ fontSize: 22, color: 'white', position: 'relative', top: 5.2, marginRight: 10 }} /> */}
                                            <Typography style={{ color: 'white', fontSize: '1.0rem', fontWeight: '600', }} >  Welcome   <span className='v-store-t' style={{ marginTop: 3, fontSize: '1rem' }}>{fullName} </span>  </Typography>



                                        </div>
                                    </div>
                                    {/* <Typography  onClick={() => navigate('/login')} style={{ border: 'none', color: 'white', fontWeight: '600', fontSize: 14 }} ><PersonAddAltRoundedIcon style={{ position: 'relative', top: '-0.1rem' }} /> Logiin</Typography> */}


                                    <Link onClick={() => {
                                        setIsNewRefferal(false)
                                        setIsUpdateKyc(true)
                                        setIsAddProduct(false)
                                        setIsUserProfile(false)
                                        setIsMyproduct(false)
                                        setIsRefferalIn(false)
                                        setIsRefferalClose(false)
                                        setIsAllProduct(false)
                                    }} style={{ textDecoration: 'none' }}> <Button style={{ backgroundColor: 'white', width: 110, marginLeft: 10, color: '#d9370d', borderColor: '#d9370d', borderRadius: 3, marginTop: '0.9rem' }}>Update Kyc</Button> </Link>
                                    <div >
                                        <Nav style={{ cursor: 'pointer' }} onClick={handleClick} ><h1 style={{ marginLeft: 15, fontSize: 30, color: 'black', position: 'relative', top: '0.5rem' }}><FaUserCircle /></h1> </Nav>
                                        <Popover
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <Typography sx={{ pl: 3, pt: 2, fontSize: 16 }}>Profile</Typography>
                                            <Typography sx={{ pl: 3, pr: 2, pb: 3, pt: 2, fontSize: 16 }}>   {sessionStorage.getItem(`key`) && (
                                                <Link onClick={onSetAlert} style={{ textDecoration: 'none', fontSize: 16, color: 'red' }}> Logout<TbLogout style={{ marginLeft: 5 }} /></Link>
                                            )}</Typography>
                                        </Popover>
                                    </div>
                                </div>
                            </div>

                            <div className='container-fluid card d-flex d-lg-none' style={{ borderRadius: '0.0rem', border: 'none', backgroundColor: 'red', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }} >
                                <div style={{ position: 'relative', backgroundColor: 'white', borderRadius: '0.2rem', paddingLeft: 8, paddingRight: 8, width: '100%', display: 'flex', alignItems: 'center' }} >
                                    <SearchRoundedIcon style={{ fontSize: 25, color: 'gray', marginTop: -2 }} />
                                    <input onClick={handleClickOpen1} readOnly className='ps-1' style={{ background: 'white', border: 'none', height: '2.6rem', width: '16rem', borderRadius: '0.2rem', outline: 'none' }} placeholder='Search Vyapar Productss' ></input>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Navbar variant='dark' style={{ paddingLeft: 10, color: 'white', backgroundColor: 'red', height: 'fit-content' }} fixed="top" className="navbar1 d-md-flex d-none flex-row  align-items-center justify-content-between">

                        <div className='nav-width1 mobile-space'>

                            <Navbar className="d-flex flex-row  align-items-center justify-content-between flex-wrap ">

                                <div style={{ width: '20rem', paddingLeft: 7 }} className='v-con-logo-con'>
                                    <div onClick={() => navigate('/user-dashboard')} style={{ position: 'relative', top: '-0.5rem', backgroundColor: 'white', borderRadius: '20px' }} >
                                        <Typography className='v-con-logo' style={{ color: 'red', fontSize: '1.4rem', fontWeight: '800', }} ><img src={icon1} style={{ height: '45px', width: '45px', borderRadius: '0.1rem', marginRight: 3, position: 'relative', top: '0.2rem' }} />Welcome <span className='v-store-t' style={{ marginTop: 3, fontSize: '1.0rem' }}>{fullName}</span></Typography>
                                        <div style={{ width: '7.3rem', height: '0.19rem', backgroundColor: 'white', position: 'relative', top: '-0.2rem', left: 34, borderRadius: '0.4rem', }} ></div>
                                    </div>
                                </div>


                            </Navbar>

                            {/* <div className='small-md-screen'>
                            {dopen ?
                                <IoMdClose className='icon' style={{ fontSize: 40, color: '#1c1f18' }} onClick={onToggleSidebar} /> : <GiHamburgerMenu style={{ color: 'white', fontSize: 35, marginRight: 20 }} onClick={onToggleSidebar} />}
                        </div> */}
                        </div>

                        <div className='large-screen'>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav" >
                                <Nav className='nav-items' style={{ marginRight: 15 }}>

                                    <div style={{ marginBottom: '10px', position: 'relative', backgroundColor: 'white', borderRadius: '0.3rem', paddingLeft: 8, paddingRight: 8, display: 'flex', alignItems: 'center' }} >
                                        <SearchRoundedIcon style={{ color: 'black' }} />
                                        <input onClick={handleClickOpen1} readOnly className='ps-1' style={{ background: 'white', border: 'none', height: '2.6rem', width: '13rem', borderRadius: '0.3rem', outline: 'none' }} placeholder='Search Vyapar Products' ></input>
                                        <ListRoundedIcon style={{ marginTop: -2, color: 'black' }} />
                                    </div>

                                    <Link onClick={() => {
                                        setIsNewRefferal(false)
                                        setIsUpdateKyc(true)
                                        setIsAddProduct(false)
                                        setIsMyproduct(false)
                                        setIsRefferalIn(false)
                                        setIsUserProfile(false)
                                        setIsRefferalClose(false)
                                        setIsAllProduct(false)
                                    }} style={{ textDecoration: 'none' }}> <Button style={{ backgroundColor: 'white', width: 125, marginLeft: 10, color: '#d9370d', borderColor: '#d9370d', borderRadius: 3, marginTop: '0.0rem' }}>Update Kyc</Button> </Link>
                                    <div>
                                        <Nav style={{ cursor: 'pointer' }} onClick={handleClick} ><h1 style={{ marginLeft: 15, fontSize: 30, color: 'black', position: 'relative', top: '0rem' }}><FaUserCircle /></h1> </Nav>
                                        <Popover
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <Typography
                                            onClick={() => {
                                                setIsUserProfile(true)
                                                setIsNewRefferal(false)
                                                setIsUpdateKyc(false)
                                                setIsAddProduct(false)
                                                setIsMyproduct(false)
                                                setIsRefferalIn(false)
                                                setIsRefferalClose(false)
                                                setIsAllProduct(false)
                                            }} sx={{ pl: 3, pt: 2, fontSize: 16 }}>Profile</Typography>
                                            <Typography sx={{ pl: 3, pr: 2, pb: 3, pt: 2, fontSize: 16 }}>   {sessionStorage.getItem(`key`) && (
                                                <Link onClick={onSetAlert} style={{ textDecoration: 'none', fontSize: 16, color: 'red' }}> Logout<TbLogout style={{ marginLeft: 5 }} /></Link>
                                            )}</Typography>
                                        </Popover>
                                    </div>

                                </Nav>
                            </Navbar.Collapse>
                        </div>

                    </Navbar>

                    <div className='d-none d-md-block'>
                        <Navbar variant='light' style={{ paddingLeft: 10, color: 'white' }} fixed="top" className="navbar2 d-flex flex-row justify-content-between">


                            <div className='large-screen2 col-md-12 col-xl-11 mx-auto d-md-flex d-none'>
                                <center>
                                    <Navbar.Collapse id="basic-navbar-nav" >
                                        <Nav style={{ fontWeight: 'bold', marginRight: 15, color: 'black' }}>
                                            {/* <Typography onClick={() => {
                                            setActiveLink('all-products');
                                            setIsNewRefferal(false)
                                            setIsAddProduct(false)
                                            setIsMyproduct(false)
                                            setIsRefferalIn(false)
                                            setIsRefferalClose(false)
                                            setIsAllProduct(true)
                                            setIsUpdateKyc(false)
                                            window.location.reload()
                                        }} to='/all-products' style={{ cursor: 'pointer', marginRight: 15, textDecoration: 'none', fontWeight: 400 }} className='nav-2-items'>All Products</Typography> */}

                                            {/* <Typography onClick={() => {
                                                setIsNewRefferal(true)
                                                setIsAddProduct(false)
                                                setIsMyproduct(false)
                                                setIsRefferalIn(false)
                                                setIsRefferalClose(false)
                                                setIsAllProduct(false)
                                            }} to='new-referral' style={{ cursor: 'pointer', marginRight: 15, textDecoration: 'none', fontWeight: 400 }} className='nav-2-items'>Referral Out</Typography>
                                           
                                           
                                            <Typography onClick={() => {
                                                setIsNewRefferal(false)
                                                setIsAddProduct(false)
                                                setIsMyproduct(false)
                                                setIsRefferalIn(true)
                                                setIsRefferalClose(false)
                                                setIsAllProduct(false)
                                                setIsUpdateKyc(false)
                                            }} to='referral-in' style={{ cursor: 'pointer', marginRight: 15, textDecoration: 'none', fontWeight: 400 }} className='nav-2-items'>Referral In</Typography  >
                                          
                                          
                                            <Typography onClick={() => {
                                                setIsNewRefferal(false)
                                                setIsAddProduct(false)
                                                setIsMyproduct(false)
                                                setIsRefferalIn(false)
                                                setIsRefferalClose(true)
                                                setIsAllProduct(false)
                                                setIsUpdateKyc(false)
                                            }} to='referral-close' style={{ cursor: 'pointer', marginRight: 15, textDecoration: 'none', fontWeight: 400 }} className='nav-2-items'>Referral Close</Typography  >
                                           
                                           
                                            <Typography onClick={() => {
                                                setIsNewRefferal(false)
                                                setIsAddProduct(false)
                                                setIsMyproduct(true)
                                                setIsRefferalIn(false)
                                                setIsRefferalClose(false)
                                                setIsAllProduct(false)
                                                setIsUpdateKyc(false)
                                            }} style={{ cursor: 'pointer', marginRight: 15, textDecoration: 'none', fontWeight: 400 }} className='nav-2-items'>My Product </Typography>
                                        */}
                                            <Typography
                                                onClick={() => {
                                                    setActiveLink('all-products');
                                                    setIsNewRefferal(false);
                                                    setIsUserProfile(false);
                                                    setIsAddProduct(false);
                                                    setIsMyproduct(false);
                                                    setIsRefferalIn(false);
                                                    setIsRefferalClose(false);
                                                    setIsAllProduct(true);
                                                    setIsUpdateKyc(false);
                                                    //window.location.reload();
                                                }}
                                                to='/all-products'
                                                className={`nav-2-items ${activeLink === 'all-products' ? 'active-link' : ''}`}
                                                style={{ cursor: 'pointer', marginRight: 15, textDecoration: 'none', fontWeight: activeLink === 'all-products' ? 'bold' : '400', color: activeLink === 'all-products' ? 'red' : 'black' }}
                                            >
                                                All Products
                                            </Typography>
                                            <Typography
                                                onClick={() => {
                                                    setActiveLink('referral-out');
                                                    setIsNewRefferal(true);
                                                    setIsAddProduct(false);
                                                    setIsUserProfile(false);
                                                    setIsMyproduct(false);
                                                    setIsRefferalIn(false);
                                                    setIsRefferalClose(false);
                                                    setIsAllProduct(false);
                                                }}
                                                to='new-referral'
                                                className={`nav-2-items ${activeLink === 'referral-out' ? 'active-link' : ''}`}
                                                style={{ cursor: 'pointer', marginRight: 15, textDecoration: 'none', fontWeight: activeLink === 'referral-out' ? 'bold' : '400', color: activeLink === 'referral-out' ? 'red' : 'black' }}
                                            >
                                                Referral Out
                                            </Typography>

                                            {/* Referral In Link */}
                                            <Typography
                                                onClick={() => {
                                                    setActiveLink('referral-in');
                                                    setIsNewRefferal(false);
                                                    setIsAddProduct(false);
                                                    setIsUserProfile(false);
                                                    setIsMyproduct(false);
                                                    setIsRefferalIn(true);
                                                    setIsRefferalClose(false);
                                                    setIsAllProduct(false);
                                                    setIsUpdateKyc(false);
                                                }}
                                                to='referral-in'
                                                className={`nav-2-items ${activeLink === 'referral-in' ? 'active-link' : ''}`}
                                                style={{ cursor: 'pointer', marginRight: 15, textDecoration: 'none', fontWeight: activeLink === 'referral-in' ? 'bold' : '400', color: activeLink === 'referral-in' ? 'red' : 'black' }}
                                            >
                                                Referral In
                                            </Typography>

                                            {/* Referral Close Link */}
                                            <Typography
                                                onClick={() => {
                                                    setActiveLink('referral-close');
                                                    setIsNewRefferal(false);
                                                    setIsAddProduct(false);
                                                    setIsMyproduct(false);
                                                    setIsUserProfile(false);
                                                    setIsRefferalIn(false);
                                                    setIsRefferalClose(true);
                                                    setIsAllProduct(false);
                                                    setIsUpdateKyc(false);
                                                }}
                                                to='referral-close'
                                                className={`nav-2-items ${activeLink === 'referral-close' ? 'active-link' : ''}`}
                                                style={{ cursor: 'pointer', marginRight: 15, textDecoration: 'none', fontWeight: activeLink === 'referral-close' ? 'bold' : '400', color: activeLink === 'referral-close' ? 'red' : 'black' }}
                                            >
                                                Referral Close
                                            </Typography>

                                            {/* My Product Link */}
                                            <Typography
                                                onClick={() => {
                                                    setActiveLink('my-product');
                                                    setIsNewRefferal(false);
                                                    setIsAddProduct(false);
                                                    setIsMyproduct(true);
                                                    setIsUserProfile(false);
                                                    setIsRefferalIn(false);
                                                    setIsRefferalClose(false);
                                                    setIsAllProduct(false);
                                                    setIsUpdateKyc(false);
                                                }}
                                                style={{ cursor: 'pointer', marginRight: 15, textDecoration: 'none', fontWeight: activeLink === 'my-product' ? 'bold' : '400', color: activeLink === 'my-product' ? 'red' : 'black' }}
                                                className={`nav-2-items ${activeLink === 'my-product' ? 'active-link' : ''}`}
                                            >
                                                My Product
                                            </Typography>


                                        </Nav>
                                    </Navbar.Collapse>
                                </center>
                            </div>


                        </Navbar>
                    </div>

                    <div className='d-md-none d-block'>
                        <Navbar variant='light' style={{ color: 'white', width: '100%', position: 'fixed', top: '2.8rem', left: 0, right: 0, height: '1.8rem' }} className=" navbar2 d-flex flex-row justify-content-between p-1">
                            <div style={{ color: 'black', justifyContent: 'space-between', alignItems: 'center', width: '100%' }} className='d-flex'>
                                <Typography onClick={() => {
                                     setActiveLink('all-products');
                                    setIsNewRefferal(false)
                                    setIsAddProduct(false)
                                    setIsMyproduct(false)
                                    setIsRefferalIn(false)
                                    setIsRefferalClose(false)
                                    setIsAllProduct(true)
                                    setIsUserProfile(false);
                                    setIsUpdateKyc(false)
                                    //window.location.reload()
                                }} to='/all-products' 
                                // style={{ cursor: 'pointer', fontSize: 10.5, textDecoration: 'none', fontWeight: 400 }} className='nav-2-items'>
                                className={`nav-2-items ${activeLink === 'all-products' ? 'active-link1' : ''}`}
                                style={{ cursor: 'pointer',fontSize: 10.5,  textDecoration: 'none', fontWeight: 400, color: activeLink === 'all-products' ? 'red' : 'black' }}
                                  >All Products</Typography>
                                <Typography onClick={() => {
                                       setActiveLink('referral-out');
                                    setIsNewRefferal(true)
                                    setIsAddProduct(false)
                                    setIsMyproduct(false)
                                    setIsRefferalIn(false)
                                    setIsRefferalClose(false)
                                    setIsAllProduct(false)
                                    setIsUserProfile(false);
                                }} to='new-referral' 
                                //</div>style={{ cursor: 'pointer', fontSize: 10.5, textDecoration: 'none', fontWeight: 400 }} className='nav-2-items'>Referral Out</Typography>
                                className={`nav-2-items ${activeLink === 'referral-out' ? 'active-link1' : ''}`}
                                style={{ cursor: 'pointer', fontSize: 10.5, textDecoration: 'none', fontWeight: 400, color: activeLink === 'referral-out' ? 'red' : 'black' }}
                            >
                                Referral Out
                            </Typography>
                                
                                <Typography onClick={() => {
                                    setActiveLink('referral-in');
                                    setIsNewRefferal(false)
                                    setIsAddProduct(false)
                                    setIsMyproduct(false)
                                    setIsRefferalIn(true)
                                    setIsRefferalClose(false)
                                    setIsAllProduct(false)
                                    setIsUserProfile(false);
                                    setIsUpdateKyc(false)
                                }} to='referral-in' 
                                //</div>style={{ cursor: 'pointer', fontSize: 10.5, textDecoration: 'none', fontWeight: 400 }} className='nav-2-items'>Referral In</Typography  >
                                className={`nav-2-items ${activeLink === 'referral-in' ? 'active-link1' : ''}`}
                                style={{ cursor: 'pointer', fontSize: 10.5, textDecoration: 'none', fontWeight: 400, color: activeLink === 'referral-in' ? 'red' : 'black' }}
                            >
                                Referral In
                            </Typography>
                                
                                <Typography onClick={() => {
                                       setActiveLink('referral-close');
                                    setIsNewRefferal(false)
                                    setIsAddProduct(false)
                                    setIsMyproduct(false)
                                    setIsRefferalIn(false)
                                    setIsRefferalClose(true)
                                    setIsUserProfile(false);
                                    setIsAllProduct(false)
                                    setIsUpdateKyc(false)
                                }} to='referral-close' 
                               //</div> 
                               style={{ cursor: 'pointer', fontSize: 10.5, textDecoration: 'none', fontWeight: 400 ,color: activeLink === 'referral-close' ? 'red' : 'black' }} 
                               //className='nav-2-items'>Referral Close</Typography  >
                               className={`nav-2-items ${activeLink === 'referral-close' ? 'active-link1' : ''}`}
                               //style={{ cursor: 'pointer', fontSize: '10.5', textDecoration: 'none', fontWeight: activeLink === 'referral-close' ? 'bold' : '400', }}
                           >
                               Referral Close
                           </Typography>
                                
                                <Typography onClick={() => {
                                    setActiveLink('my-product');
                                    setIsNewRefferal(false)
                                    setIsAddProduct(false)
                                    setIsMyproduct(true)
                                    setIsUserProfile(false);
                                    setIsRefferalIn(false)
                                    setIsRefferalClose(false)
                                    setIsAllProduct(false)
                                    setIsUpdateKyc(false)
                                }}
                                //</div> style={{ cursor: 'pointer', fontSize: 10.5, textDecoration: 'none', fontWeight: 400 }} className='nav-2-items'>My Product </Typography>
                                style={{ cursor: 'pointer', fontSize: 10.5, textDecoration: 'none', fontWeight: 400, color: activeLink === 'my-product' ? 'red' : 'black' }}
                                className={`nav-2-items ${activeLink === 'my-product' ? 'active-link1' : ''}`}
                            >
                                My Product
                            </Typography>


                            </div>


                        </Navbar>
                    </div>

                    <Modal
                        size="xl"
                        show={lgShow}
                        onHide={() => setLgShow(false)}
                        aria-labelledby="example-modal-sizes-title-lg"
                        centered
                        backgroundColor='#e3010f'
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-lg">
                                Filter
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='container'>
                                <div className='row mx-auto referral-in-product'>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    {
                        isNewRefferal && (
                            <NewReferral></NewReferral>
                        )
                    }
                    {
                        isAllProduct && (
                            <div className='d-block d-md-none' style={{ marginTop: 147 }} >
                                <AllProducts></AllProducts>
                            </div>
                        )
                    }
                    {
                        isAllProduct && (
                            <div className='d-none d-md-block'>
                                <AllProducts></AllProducts>
                            </div>
                        )
                    }
                    {
                        isMyproduct && (
                            <MyStore></MyStore>
                        )
                    }
                    {
                        isRefferalIn && (
                            <ReferralIn></ReferralIn>
                        )
                    }
                    {
                        isRefferalClose && (
                            <ReferralClose></ReferralClose>

                        )
                    }
                    {
                        isUpdateKyc && (
                            <UpdateKyc></UpdateKyc>
                        )
                    }
                    {
                        isUserProfile && (
                            <UserProfile></UserProfile>
                        )
                    }
                    <div>

                        <Dialog
                            open={open1}
                            onClose={handleClose1}
                            aria-labelledby="alert-dialog-title"
                            fullScreen={fullScreen}
                            aria-describedby="alert-dialog-description"
                            maxWidth='lg'
                        >
                            {/* <DialogContent>
                            <div style={{ padding: 4, width: '20rem', height: '31rem', }} >
                                <div style={{ position: 'relative', backgroundColor: 'white', borderRadius: '0.3rem', marginBottom: 16 }} >

                                    <input onChange={(e) => searchProduct(e.target.value)} onClick={handleClickOpen1} className='ps-1' style={{ background: 'white', border: 'none', height: '2.6rem', width: '100%', outline: 'none', borderBottom: '2px solid gray' }} placeholder='Search Vyapar Products' ></input>
                                    <SearchRoundedIcon style={{ color: 'black', position: 'absolute', right: '3px', top: '9px' }} />
                                </div>
                                {
                                    products.length !== 0 && (
                                        <div style={{ justifyContent: 'center', alignItems: 'center' }}  >
                                            <Typography style={{ color: 'gray', marginBottom: 14 }} >Click to more details</Typography>
                                            {
                                                products.slice(0, 8).map((data) => {
                                                    return (
                                                        <Box onClick={() => {
                                                            setOpen2(true)
                                                            setOpen1(false)
                                                            showSearchProduct(data.id)
                                                        }} sx={{ display: 'flex', marginBottom: 2, borderBottom: '1px solid lightgray', padding: 1 }} className='search-prpduct' >
                                                            <div style={{ width: 89, overflow: 'hidden', marginLeft: 7 }} >
                                                                <img style={{ height: 43 }} className="img" src={`https://vyaparapi.emedha.in/images/${data.pPhoto}`} alt="1004" />
                                                            </div>
                                                            <Typography>
                                                                <Typography>{data.pName}</Typography>
                                                                <Typography style={{ fontSize: 12, fontWeight: 'bold' }} ><FaRupeeSign style={{ fontSize: 12, position: 'relative', top: '-0.1rem' }} />{data.pPrice - data.discount / 100 * data.pPrice} /-</Typography>
                                                            </Typography>
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                }
                                {
                                    products.length === 0 && (
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}  >


                                            <Box>
                                                <Typography>Search Your Products</Typography>
                                            </Box>

                                        </div>
                                    )
                                }
                            </div>
                        </DialogContent> */}
                            <DialogContent>
                                <div style={{ padding: 4, width: '20rem', height: '31rem' }}>
                                    <div style={{ position: 'relative', backgroundColor: 'white', borderRadius: '0.3rem', marginBottom: 16 }}>
                                        <input
                                            onChange={(e) => searchProduct(e.target.value)}
                                            onClick={handleClickOpen1}
                                            className="ps-1 d-flex align-items-center"
                                            style={{
                                                background: 'white',
                                                border: 'none',
                                                height: '2.6rem',
                                                width: '100%',
                                                outline: 'none',
                                                borderBottom: '2px solid gray',
                                            }}
                                            placeholder="Search Vyapar Products"
                                        />
                                        <SearchRoundedIcon style={{ color: 'black', position: 'absolute', right: '3px', top: '9px' }} />
                                    </div>

                                    {/* Location Button */}
                                    {/* <Button  onClick={handleLocationClick} style={{ marginBottom: '10px',backgroundColor:'white',borderColor:'white',color:'blue' }}>
                                {selectedLocation ? `${selectedLocation}` : 'Select Your City'}
                            </Button> */}

                                    {/* Location Dropdown */}
                                    {openLocationDropdown && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                backgroundColor: 'white',
                                                border: '1px solid gray',
                                                padding: '10px',
                                                borderRadius: '5px',
                                                zIndex: 100,
                                                width: '70%',
                                            }}
                                        >
                                            <ul style={{ padding: 0, listStyleType: 'none' }}>
                                                {locations.map((city, index) => (
                                                    <li
                                                        key={index}
                                                        onClick={() => handleLocationSelect(city)}
                                                        style={{
                                                            cursor: 'pointer',
                                                            padding: '5px',
                                                            borderBottom: '1px solid #ddd',
                                                        }}
                                                    >
                                                        {city}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}

                                    {/* Displaying filtered products */}
                                    <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography style={{ color: 'gray', marginBottom: 14 }}>Click to view more details</Typography>
                                        {filteredProducts.slice(0, 8).map((data) => (
                                            <Box
                                                onClick={() => {
                                                    setOpen2(true);
                                                    setOpen1(false);
                                                    showSearchProduct(data.id);
                                                }}
                                                className="search-prpduct"
                                                sx={{
                                                    display: 'flex',
                                                    marginBottom: 2,
                                                    borderBottom: '1px solid lightgray',
                                                    padding: 1,
                                                }}
                                            >
                                                <div style={{ width: 89, overflow: 'hidden', marginLeft: 7 }}>
                                                    <img
                                                        style={{ height: 43 }}
                                                        className="img"
                                                        src={`https://vyaparapi.emedha.in/images/${data.pPhoto}`}
                                                        alt={data.pName}
                                                    />
                                                </div>
                                                <Typography>
                                                    <Typography>{data.pName}</Typography>
                                                    <Typography>{data.city}</Typography>
                                                    <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>
                                                        <FaRupeeSign style={{ fontSize: 12, position: 'relative', top: '-0.1rem' }} />
                                                        {data.pPrice - (data.discount / 100) * data.pPrice} /-
                                                    </Typography>
                                                </Typography>
                                            </Box>
                                        ))}
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button style={{ backgroundColor: 'red', color: 'white' }} onClick={handleClose1} autoFocus>
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </>
                {
                    open2 && (
                        <SearchItem searchData={searchData} open2={open2} setOpen2={setOpen2} />
                    )
                }
            </div>

            <div style={{ marginTop: 21 }} >
                <Footer />
            </div>

        </>
    )
}


export default Dashboard