import React from "react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Typography } from "@mui/material"; import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import './Style.css'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'auto',
    color: theme.palette.text.secondary,
}));

const ShopType = () => {
    return (
        <div>
            <Box sx={{ flexGrow: 1 }}>
            <Typography style={{color:'red', fontSize:'1.3rem', fontWeight:'bold'}} >Select Your Shop Type</Typography>
            <div style={{height:'0.4rem', width:'8rem', backgroundColor:'red', borderRadius:'0.9rem', position:'relative', top:'-0.2rem', marginBottom:'2rem'}} ></div>
                <Grid container spacing={2}>
                     
                    <Grid item sm={2} md={2}>
                        <Item  className="shop-reg-con" elevation={0} style={{ borderRadius: '0.2rem', position: 'relative' }}>
                            <div className="shop-reg" style={{ position: 'absolute', right: 0, left: 0, bottom: 0, top: 0, backgroundColor: 'lightgray', opacity: 0.4 }} ></div>
                            <div className="shop-reg" style={{ backgroundColor: 'gray', cursor:'pointer', borderRadius: '50%', width: 40, height: 40, position: 'absolute', bottom: '4px', right: '4px', justifyContent: 'center', alignItems: 'center' }} >
                                <ChevronRightIcon />
                            </div>
                            <div className="image">
                                <img width='100%' src="https://udaan.azureedge.net/assets/ewhvm3n9ctk2palc7nre.png" alt="img" ></img>
                            </div>
                            <div>
                                <Typography style={{ fontSize: '0.94rem', fontWeight: 'bold' }} >Electronics & Appliances</Typography>
                                <Typography style={{ fontSize: '0.8rem' }}>

                                    Mobile Accessories, IT Accessories,
                                    COVID Essentials, Appliances, Smartphone
                                </Typography>
                            </div>
                        </Item>
                    </Grid>
                    <Grid item md={2}>
                        <Item  className="shop-reg-con" elevation={0} style={{ borderRadius: '0.2rem', position: 'relative'}}>


                            <div className="shop-reg" style={{ position: 'absolute', right: 0, left: 0, bottom: 0, top: 0, backgroundColor: 'lightgray', opacity: 0.4 }} ></div>
                            <div className="shop-reg" style={{ backgroundColor: 'gray', cursor:'pointer', borderRadius: '50%', width: 40, height: 40, position: 'absolute', bottom: '4px', right: '4px', justifyContent: 'center', alignItems: 'center' }} >
                                <ChevronRightIcon />
                            </div>
                            <div className="image">
                                <img width='100%' src="https://udaan.azureedge.net/assets/2inu4v2r998l0pvdiiwi.png" alt="img" ></img>
                            </div>
                            <div>
                                <Typography style={{ fontSize: '0.94rem', fontWeight: 'bold' }} >Electrical </Typography>
                                <Typography style={{ fontSize: '0.8rem' }}>
                                    Switch & Switch Accessories, Lights & Light Accessories,
                                    Wires, Electrical Accessories, Electrical Home Appliances

                                </Typography>
                            </div>
                        </Item>
                    </Grid>
                    <Grid item md={2}>
                        <Item  className="shop-reg-con" elevation={0} style={{ borderRadius: '0.2rem', position: 'relative' }}>

                            <div className="shop-reg" style={{ position: 'absolute', right: 0, left: 0, bottom: 0, top: 0, backgroundColor: 'lightgray', opacity: 0.4 }} ></div>
                            <div className="shop-reg" style={{ backgroundColor: 'gray', cursor:'pointer', borderRadius: '50%', width:50, height: 40, position: 'absolute', bottom: '4px', right: '4px', justifyContent: 'center', alignItems: 'center' }} >
                                <ChevronRightIcon />
                            </div>
                            <div className="image">
                                <img width='100%' src="https://udaan.azureedge.net/assets/vnnhza6trtob5dfvsd6l.png" alt="img" ></img>
                            </div>
                            <div>
                                <Typography style={{ fontSize: '0.94rem', fontWeight: 'bold' }} >Medicines</Typography>
                                <Typography style={{ fontSize: '0.8rem' }}>

                                    Medicines, OTC/FMCG,
                                    Generics, Ethicals, Medical Devices

                                </Typography>
                            </div>
                        </Item>
                    </Grid>
                    <Grid item md={2}>
                        <Item  className="shop-reg-con" elevation={0} style={{ borderRadius: '0.2rem', position: 'relative' }}>

                            <div className="shop-reg" style={{ position: 'absolute', right: 0, left: 0, bottom: 0, top: 0, backgroundColor: 'lightgray', opacity: 0.4 }} ></div>
                            <div className="shop-reg" style={{ backgroundColor: 'gray', cursor:'pointer', borderRadius: '50%', width: 40, height: 40, position: 'absolute', bottom: '4px', right: '4px', justifyContent: 'center', alignItems: 'center' }} >
                                <ChevronRightIcon />
                            </div>
                            <div className="image">
                                <img width='100%' src="https://udaan.azureedge.net/assets/ytb416yrb4uyl53wmwtx.png" alt="img" ></img>
                            </div>
                            <div>
                                <Typography style={{ fontSize: '0.94rem', fontWeight: 'bold' }} >Clothing & Accessories </Typography>
                                <Typography style={{ fontSize: '0.8rem' }}>

                                    Women's Garments, Men's Garments,
                                    Women's Ethnic, Women's Western, Kid's Garments

                                </Typography>
                            </div>
                        </Item>
                    </Grid>
                    <Grid item md={2}>
                        <Item  className="shop-reg-con" elevation={0} style={{ borderRadius: '0.2rem', position: 'relative' }}>

                            <div className="shop-reg" style={{ position: 'absolute', right: 0, left: 0, bottom: 0, top: 0, backgroundColor: 'lightgray', opacity: 0.4 }} ></div>
                            <div className="shop-reg" style={{ backgroundColor: 'gray', cursor:'pointer', borderRadius: '50%', width: 40, height: 40, position: 'absolute', bottom: '4px', right: '4px', justifyContent: 'center', alignItems: 'center' }} >
                                <ChevronRightIcon />
                            </div>
                            <div className="image">
                                <img width='100%' src="https://udaan.azureedge.net/assets/27hm0s6pd9lw5dxbbs8w.png" alt="img" ></img>
                            </div>
                            <div>
                                <Typography style={{ fontSize: '0.94rem', fontWeight: 'bold' }} >Home & Kitchen</Typography>
                                <Typography style={{ fontSize: '0.8rem' }}>

                                    COVID Essentials, Steel Aluminium Copper Utensils,
                                    Appliances, Kitchen And Dining, Clean</Typography>
                            </div>
                        </Item>
                    </Grid>
                    <Grid item md={2}>
                        <Item  className="shop-reg-con" elevation={0} style={{ borderRadius: '0.2rem', position: 'relative' }}>

                            <div className="shop-reg" style={{ position: 'absolute', right: 0, left: 0, bottom: 0, top: 0, backgroundColor: 'lightgray', opacity: 0.4 }} ></div>
                            <div className="shop-reg" style={{ backgroundColor: 'gray', cursor:'pointer', borderRadius: '50%', width: 40, height: 40, position: 'absolute', bottom: '4px', right: '4px', justifyContent: 'center', alignItems: 'center' }} >
                                <ChevronRightIcon />
                            </div>
                            <div className="image">
                                <img width='100%' src="https://udaan.azureedge.net/assets/r7jle9jva3f6hdp81dda.png" alt="img" ></img>
                            </div>
                            <div>
                                <Typography style={{ fontSize: '0.94rem', fontWeight: 'bold' }} >
                                    Luggage & Backpacks</Typography>
                                <Typography style={{ fontSize: '0.8rem' }}>
                                    Luggage & Backpacks

                                    Luggage & Backpacks, COVID Essentials,

                                </Typography>
                            </div>
                        </Item>
                    </Grid>
                    <Grid item md={2}>
                        <Item  className="shop-reg-con" elevation={0} style={{ borderRadius: '0.2rem', position: 'relative' }}>

                            <div className="shop-reg" style={{ position: 'absolute', right: 0, left: 0, bottom: 0, top: 0, backgroundColor: 'lightgray', opacity: 0.4 }} ></div>
                            <div className="shop-reg" style={{ backgroundColor: 'gray', cursor:'pointer', borderRadius: '50%', width: 40, height: 40, position: 'absolute', bottom: '4px', right: '4px', justifyContent: 'center', alignItems: 'center' }} >
                                <ChevronRightIcon />
                            </div>
                            <div className="image">
                                <img width='100%' src="https://ud-img.azureedge.net/w_768,q_75/u/assets/d2pwbqzp6b478j1q7evx.png" alt="img" ></img>
                            </div>
                            <div>
                                <Typography style={{ fontSize: '0.94rem', fontWeight: 'bold' }} > Hardware & Sanitaryware</Typography>
                                <Typography style={{ fontSize: '0.8rem' }} >

                                    Kitchen & Bath Fittings, Furniture Hardware Fittings,
                                    Power & Hand Tools, Adhesives & Sealants

                                </Typography>
                            </div>
                        </Item>
                    </Grid>
                    <Grid item md={2}>
                        <Item  className="shop-reg-con" elevation={0} style={{ borderRadius: '0.2rem', position: 'relative' }}>

                            <div className="shop-reg" style={{ position: 'absolute', right: 0, left: 0, bottom: 0, top: 0, backgroundColor: 'lightgray', opacity: 0.4 }} ></div>
                            <div className="shop-reg" style={{ backgroundColor: 'gray', cursor:'pointer', borderRadius: '50%', width: 40, height: 40, position: 'absolute', bottom: '4px', right: '4px', justifyContent: 'center', alignItems: 'center' }} >
                                <ChevronRightIcon />
                            </div>
                            <div className="image">
                                <img width='100%' src="https://udaan.azureedge.net/assets/m62b8m2bum8bdzma8z0f.png" alt="img" ></img>
                            </div>
                            <div>
                                <Typography style={{ fontSize: '0.94rem', fontWeight: 'bold' }} >
                                    Toys, Baby & Sports </Typography>
                                <Typography style={{ fontSize: '0.8rem' }}>

                                    Cycles, Toys and Baby Gear,
                                    Baby Gear, Sports & Fitness Gear, Cycles, Toys

                                </Typography>
                            </div>
                        </Item>
                    </Grid>
                    <Grid item md={2}>
                        <Item  className="shop-reg-con" elevation={0} style={{ borderRadius: '0.2rem', position: 'relative' }}>

                            <div className="shop-reg" style={{ position: 'absolute', right: 0, left: 0, bottom: 0, top: 0, backgroundColor: 'lightgray', opacity: 0.4 }} ></div>
                            <div className="shop-reg" style={{ backgroundColor: 'gray', cursor:'pointer', borderRadius: '50%', width: 40, height: 40, position: 'absolute', bottom: '4px', right: '4px', justifyContent: 'center', alignItems: 'center' }} >
                                <ChevronRightIcon />
                            </div>
                            <div className="image">
                                <img width='100%' src="https://udaan.azureedge.net/assets/r1wk52f0c7rt4fi95wt3.png" alt="img" ></img>
                            </div>
                            <div>
                                <Typography style={{ fontSize: '0.94rem', fontWeight: 'bold' }} >Fulfillment Material </Typography>
                                <Typography style={{ fontSize: '0.8rem' }}>
                                    Fulfillment Material
                                </Typography>
                            </div>
                        </Item>
                    </Grid>
                    <Grid item md={2}>
                        <Item  className="shop-reg-con" elevation={0} style={{ borderRadius: '0.2rem', position: 'relative' }}>

                            <div className="shop-reg" style={{ position: 'absolute', right: 0, left: 0, bottom: 0, top: 0, backgroundColor: 'lightgray', opacity: 0.4 }} ></div>
                            <div className="shop-reg" style={{ backgroundColor: 'gray', cursor:'pointer', borderRadius: '50%', width: 40, height: 40, position: 'absolute', bottom: '4px', right: '4px', justifyContent: 'center', alignItems: 'center' }} >
                                <ChevronRightIcon />
                            </div>
                            <div className="image">
                                <img width='100%' src="https://udaan.azureedge.net/assets/mlekkys9molzkstbv75i.png" alt="img" ></img>
                            </div>
                            <div>
                                <Typography style={{ fontSize: '0.94rem', fontWeight: 'bold' }} >Footwear </Typography>
                                <Typography style={{ fontSize: '0.8rem' }}>

                                    Footwear, Men's Footwear,
                                    Women's Footwear, Boys Footwear, Girls Footwear

                                </Typography>
                            </div>
                        </Item>
                    </Grid>
                    <Grid item md={2}>
                        <Item  className="shop-reg-con" elevation={0} style={{ borderRadius: '0.2rem', position: 'relative' }}>

                            <div className="shop-reg" style={{ position: 'absolute', right: 0, left: 0, bottom: 0, top: 0, backgroundColor: 'lightgray', opacity: 0.4 }} ></div>
                            <div className="shop-reg" style={{ backgroundColor: 'gray', cursor:'pointer', borderRadius: '50%', width: 40, height: 40, position: 'absolute', bottom: '4px', right: '4px', justifyContent: 'center', alignItems: 'center' }} >
                                <ChevronRightIcon />
                            </div>
                            <div className="image">
                                <img width='100%' src="https://udaan.azureedge.net/assets/ovz6eb05u2j82knrax96.png" alt="img" ></img>
                            </div>
                            <div>
                                <Typography style={{ fontSize: '0.94rem', fontWeight: 'bold' }} >Food</Typography>
                                <Typography style={{ fontSize: '0.8rem' }}>

                                    Food - Staples, FMCG,
                                    Fruits & Vegetables, Meat</Typography>
                            </div>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default ShopType