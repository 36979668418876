import React from "react";

const Advertisements = () => {
    return (
        <div>
            <div className="mt-2" >
                <img style={{ width: '100%' }} src="https://www.jiomart.com/images/cms/aw_rbslider/slides/1686738824_bLOCK_BUSTER_dEALS_160x320.jpg?im=Resize=(1240,150)" ></img>
            </div>
        </div>
    )
}

export default Advertisements