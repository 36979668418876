import { Button } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import React from 'react'
import './index.css'
import { BsFillCartCheckFill } from 'react-icons/bs'
import { GrFormUpload } from 'react-icons/gr'
import { useAppStore } from '../AppStore'
import { useEffect, useState } from 'react'
import axios from 'axios'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { FaCheckCircle, FaCircle, FaDotCircle, FaRupeeSign } from 'react-icons/fa'
import { Typography } from '@mui/material'


const AddProductForm = () => {

    const dopen = useAppStore((state) => state.dopen)
    const [pName, setPName] = useState()
    const [pPrice, setPPrice] = useState(45)
    const [validity, setValidity] = useState()
    const [pPhoto, setPPhoto] = useState()
    const [pAbout, setPAbout] = useState()
    const [discount, setDiscount] = useState(10)
    const [product, setProduct] = useState([])
    const [classification, setClassification] = useState(sessionStorage.getItem('classification'))

    const onProductNameChange = (e) => {
        setPName(e.target.value)

    }

    const onChangeProductPrice = (e) => {
        setPPrice(e.target.value)
    }

    const onChangeProductDiscount = (e) => {
        setDiscount(e.target.value)

    }

    const onChangeProductDate = (e) => {
        setValidity(e.target.value)

    }

    const onChangeProductImg = (e) => {
        setPPhoto(e.target.files[0])
    }

    const onChangeproductDescription = (e) => {
        setPAbout(e.target.value)
    }

    const addProduct = (e) => {
        const email = sessionStorage.getItem('email')
        const formData = new FormData();
        formData.append('pName', pName)
        formData.append('pPrice', pPrice)
        formData.append('photo', pPhoto)
        formData.append('pAbout', pAbout);
        formData.append('validity', validity);
        formData.append('discount', discount);
        formData.append('email', email);

        formData.append('name', sessionStorage.getItem('name'))
        formData.append('mobile', sessionStorage.getItem('mobile'))
        formData.append('company', sessionStorage.getItem('company'))
        formData.append('location', sessionStorage.getItem('location'))
        formData.append('classification', classification);

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        axios.post("https://vyaparapi.emedha.in/product/post", formData, config)
            .then((response) => {
                alert(response.data);
                window.location.reload()
            }).catch((error) => {
            });
    }


    const getProductData = () => {
        axios.get("https://vyaparapi.emedha.in/product/get").then((resp) => {
            setProduct(resp.data)
        })
    }




    const [dis, setDis] = useState()

    useEffect(() => {
        getProductData()
        const dis = ((discount / 100) * pPrice)
        setDis(dis)
    }, [])

    const disableDate = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0
        const yyyy = today.getFullYear();
        return `${yyyy}-${mm}-${dd}`;
      };

    const checkPayStatus = () => {

        const userId = sessionStorage.getItem('userId')

        axios.post(`https://vyaparapi.emedha.in/e-vyapar/pg/v1/p-status`, {

            userId: userId

        }).then((res) => {
            if (res.data.ps === 1) {
                addProduct()
            } else {
                setIsPay(true)
                setOpen1(true)
            }
        })

    }

    const [isPay, setIsPay] = useState(false)
    const [isSuccessfullLogin, setIsSuccessFullLogin] = useState(false)

    const [payUrl, setPayUrl] = useState()
    const [isPayConfirm, setIsPayConfirm] = useState(false)

    const pay = () => {

        const userId = sessionStorage.getItem('userId')
        axios.post(` https://vyaparapi.emedha.in/e-vyapar/pg/v1/pay`, {
            payText: payText,
            userId: userId
        }).then((res) => {
            console.log(res.data.success)
            if (res.data.success) {
                setPayUrl(res.data.url)
                setIsPay(false)
                setIsPayConfirm(true)
            } else {
                alert('Somthing went wrong')
            }
        })

    }

    const [subscription1, setSubscription1] = useState(false)
    const [subscription2, setSubscription2] = useState(false)
    const [subscription3, setSubscription3] = useState(false)
    const [subscription4, setSubscription4] = useState(false)
    const [payText, setPayText] = useState('')

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));




    const [open1, setOpen1] = React.useState(false);





    return (
        <>
            {/* <NavbarEvyapar/> */}

            <div className='container' style={{ marginTop: 140 }} >
                <div className='row add-product-form-bg-container'>
                    <div className='col-lg-8 d-flex flex-column jusify-content-center'>
                        <form className='Add-product-form-container' >
                            <h1 className='add-product-heading text-primary'>Add Product <BsFillCartCheckFill style={{ color: 'black', marginLeft: 10 }} /></h1>
                            <input value={classification} type='text' id='productName' className='add-product-input' placeholder='Product name' />
                            <input type='text' id='productName' className='add-product-input' placeholder='Product name' onChange={onProductNameChange} />
                            <input type='text' id='productPrice' className='add-product-input' placeholder='Product price' onChange={onChangeProductPrice} />
                            <input type='text' id='productDiscount' className='add-product-input' placeholder='Product Discount' onChange={onChangeProductDiscount} />
                            <input type='date' id='date' className='add-product-input' onChange={onChangeProductDate} min={disableDate()} />
                            <div className='add-product-file'><input type="file" id="myFile" name="filename" onChange={onChangeProductImg}></input></div>
                            <textarea rows='4' cols='40' placeholder='About Product' className='mt-4' style={{ marginLeft: 80 }} onChange={onChangeproductDescription}></textarea>
                            <div className='text-center'><Button className='upload-btn' onClick={checkPayStatus}>Upload Product <GrFormUpload style={{ color: 'white', marginLeft: 6 }} /></Button></div>
                        </form>
                    </div>
                </div>

            </div>
            <div>
                <Dialog
                    fullScreen={fullScreen}
                    open={open1}
                    maxWidth='lg'
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogContent>


                        {
                            isPay && (


                                <div className='card' style={{ backgroundColor: 'red', borderRadius: 6, elevation: 23, justifyContent: 'center', alignItems: 'center', width: '28rem' }} >
                                    <div className='card' style={{ width: '100%', borderColor: 'white', borderWidth: 1, borderRadius: 4, borderStyle: 'solid', padding: 9 }} >
                                        <Typography onClick={() => {
                                            setSubscription1(!subscription1)
                                            setSubscription2(false)
                                            setSubscription3(false)
                                            setPayText('1500')
                                            setSubscription4(false)
                                        }} style={{ borderColor: 'white', borderWidth: 0.5, borderRadius: 4, borderStyle: 'dashed', height: 80, marginBottom: 20, width: '100%', padding: 4, position: 'relative', backgroundColor: 'red' }} >
                                            <div>
                                                <Typography style={{ color: 'white', fontWeight: 'bold', fontSize: 17, marginBottom: 10 }}>Subscribe Now</Typography>
                                                <Typography style={{ color: 'white', fontSize: 15 }}>Pay 6 months subscription 1500 RS/- </Typography>
                                            </div>

                                            {
                                                !subscription1 && (
                                                    <FaCircle color='white' size={20} style={{ position: 'absolute', right: 10, top: 5, cursor: 'pointer' }} />
                                                )
                                            }
                                            {
                                                subscription1 && (
                                                    <FaDotCircle color='white' size={20} style={{ position: 'absolute', right: 10, top: 5, cursor: 'pointer' }} />
                                                )
                                            }

                                        </Typography>
                                        <Typography onClick={() => {
                                            setSubscription2(!subscription2)
                                            setSubscription1(false)
                                            setSubscription3(false)
                                            setPayText('2000')
                                            setSubscription4(false)
                                        }} style={{ borderColor: 'white', borderWidth: 0.5, borderRadius: 4, borderStyle: 'dashed', height: 80, marginBottom: 20, width: '100%', padding: 4, position: 'relative', backgroundColor: 'red' }} >
                                            <div>
                                                <Typography style={{ color: 'white', fontWeight: 'bold', fontSize: 17, marginBottom: 10 }}>Subscribe Now</Typography>
                                                <Typography style={{ color: 'white', fontSize: 15 }}>Pay 1 year subscription 2000 RS/- </Typography>
                                            </div>

                                            {
                                                !subscription2 && (
                                                    <FaCircle name='circle-o' color='white' size={20} style={{ position: 'absolute', right: 10, top: 5, cursor: 'pointer' }} />
                                                )
                                            }
                                            {
                                                subscription2 && (
                                                    <FaDotCircle name='check-circle-o' color='white' size={20} style={{ position: 'absolute', right: 10, top: 5, cursor: 'pointer' }} />
                                                )
                                            }

                                        </Typography>
                                        <Typography onClick={() => {
                                            setSubscription3(!subscription3)
                                            setSubscription2(false)
                                            setSubscription1(false)
                                            setPayText('3750')
                                            setSubscription4(false)
                                        }} style={{ borderColor: 'white', borderWidth: 0.5, borderRadius: 4, borderStyle: 'dashed', height: 80, marginBottom: 20, width: '100%', padding: 4, position: 'relative', backgroundColor: 'red' }} >
                                            <div>
                                                <Typography style={{ color: 'white', fontWeight: 'bold', fontSize: 17, marginBottom: 10 }}>Subscribe Now</Typography>
                                                <Typography style={{ color: 'white', fontSize: 15 }}>Pay 2 year subscription 3750 RS/- </Typography>
                                            </div>

                                            {
                                                !subscription3 && (
                                                    <FaCircle name='circle-o' color='white' size={20} style={{ position: 'absolute', right: 10, top: 5, cursor: 'pointer' }} />
                                                )
                                            }
                                            {
                                                subscription3 && (
                                                    <FaDotCircle name='check-circle-o' color='white' size={20} style={{ position: 'absolute', right: 10, top: 5, cursor: 'pointer' }} />
                                                )
                                            }

                                        </Typography>
                                        <Typography onClick={() => {
                                            setSubscription4(!subscription4)
                                            setSubscription2(false)
                                            setSubscription3(false)
                                            setPayText('5500')
                                            setSubscription1(false)
                                        }} style={{ borderColor: 'white', borderWidth: 0.5, borderRadius: 4, borderStyle: 'dashed', height: 80, width: '100%', padding: 4, position: 'relative', backgroundColor: 'red' }} >
                                            <div>
                                                <Typography style={{ color: 'white', fontWeight: 'bold', fontSize: 17, marginBottom: 10 }}>Subscribe Now</Typography>
                                                <Typography style={{ color: 'white', fontSize: 15 }}>Pay 3 year subscription 5500 RS/- </Typography>
                                            </div>

                                            {
                                                !subscription4 && (
                                                    <FaCircle name='circle-o' color='white' size={20} style={{ position: 'absolute', right: 10, top: 5, cursor: 'pointer' }} />
                                                )
                                            }
                                            {
                                                subscription4 && (
                                                    <FaDotCircle name='check-circle-o' color='white' size={20} style={{ position: 'absolute', right: 10, top: 5, cursor: 'pointer' }} />
                                                )
                                            }

                                        </Typography>
                                        {
                                            (subscription1 || subscription2 || subscription3 || subscription4) && (
                                                <button style={{ height: 50, backgroundColor: 'white', borderRadius: 3, width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 12 }} onClick={() => pay()} >
                                                    <Typography style={{ fontSize: 14, fontWeight: 'bold', color: 'red' }} >Pay {payText} RS /-</Typography>
                                                </button>
                                            )
                                        }
                                    </div>
                                </div>

                            )
                        }
                        {
                            isPayConfirm && (
                                <div className='card p-4' style={{ backgroundColor: 'red', borderRadius: 6, elevation: 23, justifyContent: 'center', alignItems: 'center', width: '28rem' }} >
                                    <img src='https://th.bing.com/th/id/OIP.1fMFpLDsUd1aX6IKspPMIgAAAA?w=167&h=180&c=7&r=0&o=5&dpr=2.5&pid=1.7' width='110px' />
                                    <br />
                                    <Typography style={{ color: 'white' }} >Subscribe Amount is <FaRupeeSign />{payText} /-</Typography>
                                    <br />
                                    <div style={{ display: 'flex' }}>
                                        <a href={payUrl} target='_blank' >
                                            <button style={{ height: 43, backgroundColor: 'white', borderRadius: 3, width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 12, border: 'none' }} onClick={() => pay()} >
                                                <Typography style={{ fontSize: 14, fontWeight: 'bold', color: 'red', width: '12rem', backgroundColor: 'white', borderRadius: '0.3rem', border: 'none' }} >Pay Now</Typography>
                                            </button>
                                        </a>
                                        <button onClick={() => {
                                            setOpen1(false)
                                            setIsPay(false)
                                            setIsPayConfirm(false)
                                            setSubscription1(false)
                                            setSubscription2(false)
                                            setSubscription3(false)
                                            setSubscription4(false)
                                        }} style={{ height: 43, backgroundColor: 'white', borderRadius: 3, width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 12, border: 'none', marginLeft: 2 }}  >
                                            <Typography style={{ fontSize: 14, fontWeight: 'bold', color: 'red', width: '12rem', backgroundColor: 'white', borderRadius: '0.3rem', border: 'none' }} >Cancel</Typography>
                                        </button>
                                    </div>
                                </div>
                            )
                        }
                    </DialogContent>
                </Dialog>
            </div>
        </>
    )
}

export default AddProductForm