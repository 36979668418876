import './index.css'
import { useEffect, useState } from 'react'

import axios from 'axios'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea } from '@mui/material';
import { FaCaretRight, FaRupeeSign, FaWhatsapp } from 'react-icons/fa'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EmailIcon from '@mui/icons-material/Email';
import TopCarausel from '../LandingPage/TopCarausel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SearchItem from '../Dashboard/SearchItem';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'auto',
    color: theme.palette.text.secondary,
}));


const AllProdcuts = () => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [open1, setOpen1] = useState(false);

    const handleClickOpen1 = () => {
        setOpen1(true);
    };

    const handleClose1 = () => {
        setOpen1(false);
    };


    const [pName, setPName] = useState()
    const [pPrice, setPPrice] = useState(45)
    const [validity, setValidity] = useState()
    const [pPhoto, setPPhoto] = useState()
    const [pAbout, setPAbout] = useState()
    const [discount, setDiscount] = useState(10)
    const [product, setProduct] = useState([])

    const productDetails = (pPhoto, pPrice, discount, validity, pName, about) => {
        setPName(pName)
        setPPhoto(pPhoto)
        setPPrice(pPrice)
        setDiscount(discount)
        setValidity(validity)
        setPAbout(about)
        handleClickOpen1()
    }

    const [productsList, setProductList] = useState([])


    const getAllProducts = async () => {

        const apiUrl = `https://vyaparapi.emedha.in/all-product/get`

        axios.get(apiUrl).then((res) => {
            setProductList(res.data)
        })
    }



    useEffect(() => {
        getAllProducts()
    }, [])

    const [referralIn, setReferralIn] = useState([])
    const [rippleF, setRipple] = useState(false)
    const [isNewReferal, setIsNewReferal] = useState('')
    const [isShowClassification, setShowClasification] = useState(false)
    const [isShowProduct, setIsShowProduct] = useState()

    const referralInData = (e) => {
        setRipple(true)
        axios.get('https://vyaparapi.emedha.in/all-product/get').then((res) => {
            setReferralIn(res.data)
            setTimeout(() => setRipple(false), 1500)
        })

    }

    const [isFilter, setIsFilter] = useState(false)

    useEffect(() => {
        referralInData()
    }, [])

    const numColumns = 2

    const [loadingMsg, setLoadingMsg] = useState('Loading')
    const [loading, setLoading] = useState(false)
    const [isCustomer, setIsCustomer] = useState(false)
    const [isSelf, setIsSelf] = useState(false)
    const [isSendMsg, setIsSendMsg] = useState(false)
    const [isSendCustomerMsg, setIsSendCustomerMsg] = useState(false)
    const [isSendSelfMsg, setIsSendSelfMsg] = useState(false)
    const [isAllClassification, setIsAllClassification] = useState()


    const [isNewReferal1, setIsNewReferal1] = useState(false)
    const [isNewReferal2, setIsNewReferal2] = useState(false)
    const [location, setLocation] = useState([])
    const [msg, setMsg] = useState('')

    const getLocationData = () => {
        axios.get("https://vyaparapi.emedha.in/city/get").then((resp) => {
            setLocation(resp.data)
        })
    }

    const getProductData = () => {
        axios.get("https://vyaparapi.emedha.in/product/get").then((resp) => {
            setProduct(resp.data)
        })
    }

    useEffect(() => {
        getLocationData()
        getProductData()
    }, [])

    const [selectLocation, setSelectLocation] = useState('Select Location')
    const [selectProduct, setSelectProduct] = useState('Select Product')



    const [customerSalerEmail, setCustomersalerEmail] = useState()
    const [customerSalerName, setCustomersalerName] = useState()
    const [customerSalerProduct, setCustomersalerProduct] = useState()
    const [customerSalerLocation, setCustomersalerLocation] = useState()
    const [customerSalerPhoto, setCustomersalerPhoto] = useState()
    const [customerSalerDiscount, setCustomersalerDiscount] = useState()
    const [customerSalerPrice, setCustomersalerPrice] = useState()
    const [customerSalerDiscription, setCustomersalerDiscription] = useState()
    const [customerSalerMobile, setCustomerSalerMobile] = useState()
    const [id, setId] = useState()


    const customer = (name, email, product, location, pPhoto, discount, discription, pPrice, sdid, mobile, id) => {

        setId(id)
        setCustomersalerEmail(email)
        setCustomersalerName(name)
        setCustomersalerProduct(product)
        setCustomersalerLocation(location)
        setCustomersalerPhoto(pPhoto)
        setCustomersalerDiscount(discount)
        setCustomersalerDiscription(discription)
        setCustomersalerPrice(pPrice)
        setCustomerSalerMobile(mobile)
    }




    const [MSalerEmail, setMsalerEmail] = useState()
    const [MSalerName, setMsalerName] = useState()
    const [MSalerProduct, setMsalerProduct] = useState()
    const [MSalerLocation, setMsalerLocation] = useState()
    const [MSalerPhoto, setMsalerPhoto] = useState()
    const [MSalerDiscount, setMsalerDiscount] = useState()
    const [MSalerPrice, setMsalerPrice] = useState()
    const [MSalerDiscription, setMsalerDiscription] = useState()
    const [MId, setMId] = useState()

    const [first_name, set_first_name] = useState()
    const [mobile, setMobile] = useState()
    const [state, setState] = useState()
    const [companyName, setCompanyName] = useState()

    // useEffect(() => {
    //     getSignup()
    // }, [])

    useEffect(() => {

        set_first_name(sessionStorage.getItem('name'))


        setMobile(sessionStorage.getItem('mobile'))


        setState(sessionStorage.getItem('location'))


        setCompanyName(sessionStorage.getItem('company'))


    }, [])

    const [customerBadEmail, setCustomerBadEmail] = useState(false)
    const [customerBadName, setCustomerBadName] = useState(false)
    const [customerBadMobile, setCustomerBadMobile] = useState(false)
    const [wait, setWait] = useState(false)

    const customerDataValidation = () => {
        if (customerName === "") {
            setCustomerBadName(true)
        } else {
            if (customerMobile === "") {
                setCustomerBadMobile(true)
            } else {
                if (customerEmail === "") {
                    setCustomerBadEmail(true)
                } else {
                    customerSendMessage()
                }
            }
        }
    }

    const [sendType, setSendType] = useState('')

    const memberSendMessage = () => {

        setWait(true)

        if (sendType === 'whatsApp') {
            alert('whatasapp clicked')
            axios.post(`https://emedha.com/evyapar/wapp/send.php?mobile=${mobile}&name=${first_name}&param1=${MSalerName}&param2=${pName}&param3=${salerMobile}&code=referral`)
            axios.post(`https://emedha.com/evyapar/wapp/send.php?mobile=${salerMobile}&name=${MSalerName}&param1=${first_name}&param2=${pName}&param3=${mobile}&code=referralout`).then(() => {
                alert('Message send successfully !')
                setWait(false)
            })
            return
        }

        const email = sessionStorage.getItem('email')

        axios.post('https://vyaparapi.emedha.in/send-message-member', {
            email,
            salerEmail: MSalerEmail,
            companyName: companyName,
            first_name,
            mobile,
            name: MSalerName,
            pName: pName,
            photo: pPhoto,
            price: pPrice,
            discount: discount,
            discription: pAbout,
            location: sessionStorage.getItem('location'),
            userId: MId,
            id: id,
            salerMobile: salerMobile
        }).then((res) => {

            if (res.data) {
                setWait(false)
                alert('message successfully send')
                setOpen(false)
                setOpenn(false)
            }



        })

    }

    const [salerMobile, setMsalerMobile] = useState()


    const member = (salerEmail, name, pName, photo, price, discount, discription, location, userId, validity, mobile, id) => {

        setId(id)
        setMsalerEmail(salerEmail)
        setMsalerMobile(mobile)
        setMsalerName(name)
        setMsalerProduct(pName)
        setMsalerLocation(location)
        setMsalerPhoto(photo)
        setMsalerDiscount(discount)
        setMsalerDiscription(discription)
        setMsalerPrice(price)
        setMId(userId)
        setPPhoto(photo)
        setValidity(validity)

    }


    const [customerName, setCustomerName] = useState("")
    const [customerMobile, setCustomerMobile] = useState("")
    const [customerEmail, setCustomerEmail] = useState("")

    const customerSendMessage = () => {

        setWait(true)

        if (sendType === 'whatsapp') {
            alert('whatasapp clicked')
            axios.post(`https://emedha.com/evyapar/wapp/send.php?mobile=${mobile}&name=${first_name}&param1=${customerSalerName}&param2=${customerSalerProduct}&code=referralin`)
            axios.post(`https://emedha.com/evyapar/wapp/send.php?mobile=${customerMobile}&name=${customerName}&param1=${customerSalerName}&param2=${customerSalerProduct}&param3=${customerSalerMobile}&code=referral`)
            axios.post(`https://emedha.com/evyapar/wapp/send.php?mobile=${customerSalerMobile}&name=${customerSalerName}&param1=${customerName}&param2=${customerSalerProduct}&param3=${customerMobile}&code=referralout`).then(() => {
                alert('Message send successfully !')
                setWait(false)
            })
            return;
        }

        const email = sessionStorage.getItem('email')
        axios.post('https://vyaparapi.emedha.in/send-message-customer', {
            customerEmail,
            customerMobile,
            customerName,
            customerSalerName,
            customerSalerEmail,
            customerSalerProduct,
            customerSalerLocation,
            customerSalerPhoto,
            customerSalerPrice,
            customerSalerDiscount,
            customerSalerDiscription,
            customerSalerMobile,
            email,
            first_name,
            id
        }).then((res) => {

            if (res.data) {

                setWait(false)
                alert('message successfully send')

                setCustomerMobile('')
                setCustomerEmail('')
                setCustomerName('')
            }



        })

    }

    const [openn, setOpenn] = useState(false);
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
        setOpenn(false);
    };



    const currentDate = new Date().toISOString().split('T')[0]; // 'YYYY-MM-DD'


    return (
        <Box sx={{ flexGrow: 1 }}>
            <div style={{ marginTop: 126 }} >
                <TopCarausel></TopCarausel>
            </div>
            <Grid container sx={{ paddingLeft: 1, paddingRight: 1, marginTop: 2, marginBottom: 5, paddingBottom: 4 }} spacing={2}>
                {
                    productsList.map((data) => {
                        return (
                            <Grid item lg={2} xs={6}>
                                <Item elevation={1} style={{ height: 340, position: 'relative', borderRadius: '0.6rem' }}>
                                    <Card elevation={0} sx={{ maxWidth: '100%', justifyContent: 'center', alignItems: 'center', borderRadius: '0.6rem' }}>
                                        <center>
                                            <CardActionArea>
                                                <center>
                                                    <div class='cards1' style={{ overflow: 'hidden', marginBottom: 4 }} >
                                                        <div style={{ width: '96%', overflow: 'hidden' }} >
                                                            <center> <img style={{ height: '8rem' }} src={`https://vyaparapi.emedha.in/images/${data.pPhoto}`} alt="1004" /></center>
                                                        </div>
                                                    </div>
                                                </center>
                                            </CardActionArea>
                                        </center>
                                    </Card>
                                    <CardContent className='d-none d-md-block' >
                                        <Box style={{ height: '10rem', position: 'absolute', left: 0, right: 0, bottom: 0, paddingLeft: 10 }}>
                                            <Typography style={{ fontSize: '1.1rem', color: 'black', fontWeight: '600', marginBottom: 14, textTransform: 'capitalize' }} >{data.pName?.length > 15 ? data.pName.slice(0, 15) + '...' : data.pName}</Typography>
                                            {/* <Typography style={{ fontSize: 15 }}> <Typography style={{ color: 'black' }} ><FaRupeeSign style={{ fontSize: 14, position: 'relative', top: '-0.1rem' }} />{data.pPrice - data.discount / 100 * data.pPrice} <span className='product-detail-orginal-cost' style={{ fontSize: 14, color: 'gray' }}>{data.pPrice}</span><span style={{ fontSize: 15, fontWeight: '700', color: 'green', fontStyle: 'italic' }}>{data.discount}% Off</span>  </Typography></Typography> */}
                                               <Typography style={{ fontSize: 12, marginTop: 6 }}>
                                                <Typography style={{ color: 'black' }}>
                                                    <FaRupeeSign style={{ fontSize: 12, position: 'relative', top: '-0.1rem' }} />
                                                    {Math.floor(data.pPrice - (data.discount / 100 * data.pPrice))} {/* Use Math.floor() for integer */}
                                                    <span className='product-detail-orginal-cost' style={{ fontSize: 12, color: 'gray' }}>
                                                        {data.pPrice.toLocaleString()}  {/* Format with commas */}
                                                    </span>
                                                    <span style={{ fontSize: 12, fontWeight: '700', color: 'green', fontStyle: 'italic' }}>
                                                        {data.discount}% Off
                                                    </span>
                                                </Typography>
                                            </Typography>

                                            {/* <Typography style={{ fontSize: 12 }}><span style={{ fontSize: 12 }}>Validity: </span>{data.validity}</Typography> */}
                                            <Typography style={{ fontSize: 12 }}>
                                                <span style={{ fontSize: 12 }}>Validity: </span>
                                                {data.validity < currentDate ? 'Expired' : data.validity}
                                            </Typography>


                                            <button onClick={() => {
                                                productDetails(data.pPhoto, data.pPrice, data.discount, data.validity, data.pName, data.discription)
                                                member(data.email, data.first_name, data.pName, data.pPhoto, data.pPrice, data.discount, data.discription, data.location, data.id, data.validity, data.mobile, data.id)
                                                customer(data.first_name, data.email, data.pName, data.location, data.pPhoto, data.discount, data.discription, data.pPrice, data.id, data.mobile, data.id)
                                            }} style={{ borderRadius: '0.2rem', fontSize: 15, border: 'none', backgroundColor: 'red', color: 'white', height: '2.2rem', width: '7.5rem', marginTop: 29 }}>More Details</button>
                                        </Box>
                                    </CardContent>
                                    <CardContent className='d-block d-md-none' >
                                        <Box style={{ height: '10rem', position: 'absolute', left: 0, right: 0, bottom: 0, paddingLeft: 10 }}>
                                            <Typography style={{ fontSize: '1.0rem', color: 'black' }} >{data.pName}</Typography>
                                            {/* <Typography style={{ fontSize: 12, marginTop: 6 }}> <Typography style={{ color: 'black' }} ><FaRupeeSign style={{ fontSize: 12, position: 'relative', top: '-0.1rem' }} />{data.pPrice - data.discount / 100 * data.pPrice} <span className='product-detail-orginal-cost' style={{ fontSize: 12, color: 'gray' }}>{data.pPrice}</span><span style={{ fontSize: 12, fontWeight: '700', color: 'green', fontStyle: 'italic' }}>{data.discount}% Off</span>  </Typography></Typography> */}
                                            <Typography style={{ fontSize: 12, marginTop: 6 }}>
                                                <Typography style={{ color: 'black' }}>
                                                    <FaRupeeSign style={{ fontSize: 12, position: 'relative', top: '-0.1rem' }} />
                                                    {Math.floor(data.pPrice - (data.discount / 100 * data.pPrice))} {/* Use Math.floor() for integer */}
                                                    <span className='product-detail-orginal-cost' style={{ fontSize: 12, color: 'gray' }}>
                                                        {data.pPrice.toLocaleString()}  {/* Format with commas */}
                                                    </span>
                                                    <span style={{ fontSize: 12, fontWeight: '700', color: 'green', fontStyle: 'italic' }}>
                                                        {data.discount}% Off
                                                    </span>
                                                </Typography>
                                            </Typography>



                                            {/* <Typography style={{ fontSize: 12 }}><span style={{ fontSize: 12 }}>Validity: </span>{data.validity}</Typography> */}
                                            <Typography style={{ fontSize: 12 }}>
                                                <span style={{ fontSize: 12 }}>Validity: </span>
                                                {data.validity < currentDate ? 'Expired' : data.validity}
                                            </Typography>

                                            <button onClick={() => {
                                                productDetails(data.pPhoto, data.pPrice, data.discount, data.validity, data.pName, data.discription)
                                                member(data.email, data.first_name, data.pName, data.pPhoto, data.pPrice, data.discount, data.discription, data.location, data.id, data.validity, data.mobile, data.id)
                                                customer(data.first_name, data.email, data.pName, data.location, data.pPhoto, data.discount, data.discription, data.pPrice, data.id, data.mobile, data.id)
                                            }} style={{ borderRadius: '0.2rem', fontSize: 15, border: 'none', backgroundColor: 'red', color: 'white', height: '2.2rem', width: '7.5rem' }}>More Details</button>
                                        </Box>
                                    </CardContent>
                                </Item>
                            </Grid>
                        )
                    })
                }
            </Grid>
            <div>
                <Dialog
                    open={open1}
                    onClose={handleClose1}
                    fullScreen={fullScreen}
                    aria-labelledby="alert-dialog-title"
                    maxWidth='xl'
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle style={{ marginBottom: 12 }} id="alert-dialog-title">
                        <Typography style={{ color: 'white', fontWeight: 'bold' }} > </Typography>
                    </DialogTitle>

                    <DialogContent>
                        <div style={{ width: '35rem' }} className="row d-none d-md-block" >
                            <div className="row">
                                <div className="col-lg-12">
                                    <div style={{ padding: 6, borderTopRightRadius: '0.2rem', borderBottomRightRadius: '0.2rem', overflow: 'hidden' }} >
                                        <center>
                                            <img src={`https://vyaparapi.emedha.in/images/${pPhoto}`} style={{ height: '15rem' }} />
                                        </center>
                                    </div>
                                </div>
                                <div style={{ borderBottom: '1px solid lightgray' }} ></div>
                                <div className="col-lg-12 mt-2" style={{ marginTop: '-0.4rem' }} >

                                    <div>
                                        <Typography style={{ fontSize: '1.2rem', color: 'black', fontWeight: '700' }} >{pName}</Typography>
                                        <Typography style={{ fontSize: 15 }}> <Typography style={{ color: 'black' }} ><FaRupeeSign style={{ fontSize: 14, position: 'relative', top: '-0.1rem' }} />{pPrice - discount / 100 * pPrice} <span className='product-detail-orginal-cost' style={{ fontSize: 14, color: 'gray' }}>{pPrice}</span> <span style={{ fontSize: 15, fontWeight: '700', color: 'green', fontStyle: 'italic' }}>{discount}% Off</span>  </Typography></Typography>

                                        <Typography style={{ fontSize: 12 }}><span style={{ fontSize: 12 }}>Validity: </span>{validity}</Typography>
                                        <br />
                                        <Typography style={{ fontSize: 14, color: 'black' }}>Description: {pAbout}</Typography>
                                        <br />
                                        <Typography style={{ color: 'rgb(53, 53, 111)', fontSize: 18, fontWeight: 'bold' }} >Referral</Typography>
                                        <div style={{ borderTop: '4px solid rgb(53, 53, 111)', width: '2.3rem', marginTop: '-0.2rem' }} ></div>
                                        <br />
                                        {/* <button onClick={() => {

                                            setOpen(true)

                                        }} style={{ border: 'none', background: 'rgb(172, 55, 55)', height: '2.2rem', width: '6.8rem', borderRadius: '0.1rem', color: 'white', marginRight: 3 }}><EmailIcon style={{ fontSize: 17, marginTop: '-0.12rem', position: 'relative', top: -1 }} /> Email</button>
                                        <button style={{ border: 'none', background: 'green', height: '2.2rem', width: '6.8rem', borderRadius: '0.1rem', color: 'white', marginRight: 3, paddingBottom: 2 }}><FaWhatsapp style={{ fontSize: 17, marginTop: '-0.12rem', color: 'white', position: 'relative', top: -1 }} /> Whatsapp</button> */}

                                        <button onClick={() => {

                                            setOpen(true)
                                            setSendType('email')

                                        }} style={{ border: 'none', background: 'rgb(172, 55, 55)', height: '2.2rem', width: '6.8rem', borderRadius: '0.1rem', color: 'white', marginRight: 3 }}><EmailIcon style={{ fontSize: 17, marginTop: '-0.12rem', position: 'relative', top: -1 }} /> Email</button>
                                        <button
                                            onClick={() => {

                                                setOpenn(true)
                                                setSendType('whatsapp')

                                            }} style={{ border: 'none', background: 'green', height: '2.2rem', width: '6.8rem', borderRadius: '0.1rem', color: 'white', marginRight: 3, paddingBottom: 2 }}><FaWhatsapp style={{ fontSize: 17, marginTop: '-0.12rem', color: 'white', position: 'relative', top: -1 }} /> Whatsapp</button>



                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row d-block d-md-none" >
                            <div className="row">
                                <div className="col-lg-12">
                                    <div style={{ padding: 6, borderTopRightRadius: '0.2rem', borderBottomRightRadius: '0.2rem', overflow: 'hidden' }} >
                                        <center>
                                            <img src={`https://vyaparapi.emedha.in/images/${pPhoto}`} style={{ height: '15rem' }} />
                                        </center>
                                    </div>
                                </div>
                                <div style={{ borderBottom: '1px solid lightgray' }} ></div>
                                <div className="col-lg-12 mt-2" style={{ marginTop: '-0.4rem' }} >

                                    <div>
                                        <Typography style={{ fontSize: '1.2rem', color: 'black', fontWeight: '700' }} >{pName}</Typography>
                                        <Typography style={{ fontSize: 15 }}> <Typography style={{ color: 'black' }} ><FaRupeeSign style={{ fontSize: 14, position: 'relative', top: '-0.1rem' }} />{pPrice - discount / 100 * pPrice} <span className='product-detail-orginal-cost' style={{ fontSize: 14, color: 'gray' }}>{pPrice}</span> <span style={{ fontSize: 15, fontWeight: '700', color: 'green', fontStyle: 'italic' }}>{discount}% Off</span>  </Typography></Typography>

                                        <Typography style={{ fontSize: 12 }}><span style={{ fontSize: 12 }}>Validity: </span>{validity}</Typography>
                                        <br />
                                        <Typography style={{ fontSize: 14, color: 'black' }}>Description: {pAbout}</Typography>
                                        <br />
                                        <Typography style={{ color: 'rgb(53, 53, 111)', fontSize: 18, fontWeight: 'bold' }} >Referral</Typography>
                                        <div style={{ borderTop: '4px solid rgb(53, 53, 111)', width: '2.3rem', marginTop: '-0.2rem' }} ></div>
                                        <br />
                                        <button onClick={() => {

                                            setOpen(true)
                                            setSendType('email')

                                        }} style={{ border: 'none', background: 'rgb(172, 55, 55)', height: '2.2rem', width: '6.8rem', borderRadius: '0.1rem', color: 'white', marginRight: 3 }}><EmailIcon style={{ fontSize: 17, marginTop: '-0.12rem', position: 'relative', top: -1 }} /> Email</button>
                                        <button
                                            onClick={() => {

                                                setOpen(true)
                                                setSendType('whatsapp')

                                            }} style={{ border: 'none', background: 'green', height: '2.2rem', width: '6.8rem', borderRadius: '0.1rem', color: 'white', marginRight: 3, paddingBottom: 2 }}><FaWhatsapp style={{ fontSize: 17, marginTop: '-0.12rem', color: 'white', position: 'relative', top: -1 }} /> Whatsapp</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose1} style={{ backgroundColor: 'red', color: 'white', fontSize: '0.8rem' }}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </div>
            <div>
                <Dialog
                    open={open}
                    // onClose={handleClose}
                    onClose={() => {
                        handleClose()
                        window.location.reload()
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {
                        !isCustomer && (
                            <div style={{ padding: 10 }} >
                                {
                                    !wait && (
                                        <div
                                            onClick={() => {
                                                memberSendMessage()
                                            }}
                                            style={{ cursor: 'pointer', height: '6rem', width: '20rem', border: '1.7px solid lightgray', borderRadius: '0.2rem', paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                                            <Typography style={{ fontSize: 24, fontWeight: '500' }} >Self</Typography>
                                            <FaCaretRight style={{ fontSize: 15 }} />
                                        </div>
                                    )
                                }
                                {
                                    wait && (
                                        <div

                                            style={{ backgroundColor: 'grey', height: '6rem', width: '20rem', border: '1.7px solid lightgray', borderRadius: '0.2rem', paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                                            <Typography style={{ fontSize: 24, fontWeight: '500' }} >Referral sending..</Typography>
                                            <FaCaretRight style={{ fontSize: 15 }} />
                                        </div>
                                    )
                                }
                                <br />
                                <div onClick={() => setIsCustomer(true)} style={{ cursor: 'pointer', height: '6rem', width: '20rem', border: '1.7px solid lightgray', borderRadius: '0.2rem', paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                                    <Typography style={{ fontSize: 24, fontWeight: '500' }} >Customer</Typography>
                                    <FaCaretRight style={{ fontSize: 15 }} />
                                </div>
                            </div>
                        )
                    }

                    {
                        isCustomer && (
                            <div style={{ padding: 10, width: '24rem' }} >
                                <Typography style={{ fontSize: '20px', marginBottom: 10, fontWeight: '500' }} >Customer</Typography>
                                <div style={{ marginBottom: 20 }} >
                                    <input
                                        onChange={(text) => {
                                            setCustomerName(text.target.value)
                                            setCustomerBadName(false)
                                        }}
                                        style={{ width: '100%', height: '52px', border: '1px solid lightgray', borderRadius: '3px', paddingLeft: 13, outline: 'none' }} placeholder='Enter customer name' />
                                    {
                                        customerBadName && (
                                            <Typography style={{ color: 'red' }} >Please enter valid name</Typography>
                                        )
                                    }
                                </div>


                                <div style={{ marginBottom: 20 }}>
                                    <input
                                        onChange={(text) => {
                                            setCustomerEmail(text.target.value)
                                            setCustomerBadEmail(false)
                                        }}
                                        style={{ width: '100%', height: '52px', border: '1px solid lightgray', borderRadius: '3px', paddingLeft: 13, outline: 'none' }} placeholder='Enter customer email' />
                                    {
                                        customerBadEmail && (
                                            <Typography style={{ color: 'red' }} >Please enter valid email</Typography>
                                        )
                                    }
                                </div>


                                <div style={{ marginBottom: 20 }}>
                                    <input
                                        onChange={(text) => {
                                            setCustomerMobile(text.target.value)
                                            setCustomerBadMobile(false)
                                        }}
                                        style={{ width: '100%', height: '52px', border: '1px solid lightgray', borderRadius: '3px', paddingLeft: 13, outline: 'none' }} placeholder='Enter customer mobile' />
                                    {
                                        customerBadMobile && (
                                            <Typography style={{ color: 'red' }} >Please enter valid mobile</Typography>
                                        )
                                    }
                                </div>

                                {
                                    !wait && (
                                        <button onClick={() => customerDataValidation()} style={{ height: '48px', backgroundColor: 'red', borderRadius: '3px', color: 'white', width: '100%', border: 'none' }} >Send</button>
                                    )
                                }
                                {
                                    wait && (
                                        <button style={{ height: '48px', backgroundColor: 'gray', borderRadius: '3px', color: 'white', width: '100%', border: 'none' }} >Sending..</button>
                                    )
                                }
                            </div>
                        )
                    }

                </Dialog>
            </div>





            <div>
                <Dialog
                    open={openn}
                    // onClose={handleClose}
                    onClose={() => {
                        handleClose()
                        window.location.reload()
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {
                        !isCustomer && (
                            <div style={{ padding: 10 }} >
                                {
                                    !wait && (
                                        <div
                                            onClick={() => {
                                                customerSendMessage()
                                            }}
                                            style={{ cursor: 'pointer', height: '6rem', width: '20rem', border: '1.7px solid lightgray', borderRadius: '0.2rem', paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                                            <Typography style={{ fontSize: 24, fontWeight: '500' }} >Self</Typography>
                                            <FaCaretRight style={{ fontSize: 15 }} />
                                        </div>
                                    )
                                }
                                {
                                    wait && (
                                        <div

                                            style={{ backgroundColor: 'grey', height: '6rem', width: '20rem', border: '1.7px solid lightgray', borderRadius: '0.2rem', paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                                            <Typography style={{ fontSize: 24, fontWeight: '500' }} >Referral sending..</Typography>
                                            <FaCaretRight style={{ fontSize: 15 }} />
                                        </div>
                                    )
                                }
                                <br />
                                <div onClick={() => setIsCustomer(true)} style={{ cursor: 'pointer', height: '6rem', width: '20rem', border: '1.7px solid lightgray', borderRadius: '0.2rem', paddingLeft: 10, paddingRight: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                                    <Typography style={{ fontSize: 24, fontWeight: '500' }} >Customer</Typography>
                                    <FaCaretRight style={{ fontSize: 15 }} />
                                </div>
                            </div>
                        )
                    }

                    {
                        isCustomer && (
                            <div style={{ padding: 10, width: '24rem' }} >
                                <Typography style={{ fontSize: '20px', marginBottom: 10, fontWeight: '500' }} >Customer</Typography>
                                <div style={{ marginBottom: 20 }} >
                                    <input
                                        onChange={(text) => {
                                            setCustomerName(text.target.value)
                                            setCustomerBadName(false)
                                        }}
                                        style={{ width: '100%', height: '52px', border: '1px solid lightgray', borderRadius: '3px', paddingLeft: 13, outline: 'none' }} placeholder='Enter customer name' />
                                    {
                                        customerBadName && (
                                            <Typography style={{ color: 'red' }} >Please enter valid name</Typography>
                                        )
                                    }
                                </div>


                                <div style={{ marginBottom: 20 }}>
                                    <input
                                        onChange={(text) => {
                                            setCustomerEmail(text.target.value)
                                            setCustomerBadEmail(false)
                                        }}
                                        style={{ width: '100%', height: '52px', border: '1px solid lightgray', borderRadius: '3px', paddingLeft: 13, outline: 'none' }} placeholder='Enter customer email' />
                                    {
                                        customerBadEmail && (
                                            <Typography style={{ color: 'red' }} >Please enter valid email</Typography>
                                        )
                                    }
                                </div>


                                <div style={{ marginBottom: 20 }}>
                                    <input
                                        onChange={(text) => {
                                            setCustomerMobile(text.target.value)
                                            setCustomerBadMobile(false)
                                        }}
                                        style={{ width: '100%', height: '52px', border: '1px solid lightgray', borderRadius: '3px', paddingLeft: 13, outline: 'none' }} placeholder='Enter customer mobile' />
                                    {
                                        customerBadMobile && (
                                            <Typography style={{ color: 'red' }} >Please enter valid mobile</Typography>
                                        )
                                    }
                                </div>

                                {
                                    !wait && (
                                        <button onClick={() => customerDataValidation()} style={{ height: '48px', backgroundColor: 'red', borderRadius: '3px', color: 'white', width: '100%', border: 'none' }} >Send</button>
                                    )
                                }
                                {
                                    wait && (
                                        <button style={{ height: '48px', backgroundColor: 'gray', borderRadius: '3px', color: 'white', width: '100%', border: 'none' }} >Sending..</button>
                                    )
                                }
                            </div>
                        )
                    }

                </Dialog>
            </div>
        </Box>
    )
}

export default AllProdcuts


