import React, { useEffect } from 'react';


import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'
import { useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap'
import axios from 'axios';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';




const ReferralIn = () => {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [GstValidated, setGstValidated] = useState(false);

    const handleSubmitGst = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
    };


    const [lgShow, setLgShow] = useState(false);

    const [declinePop, setDeclinePop] = useState(false)




    const [isOpenDropdownEmail, setEmailDropdown] = useState(false)

    const emailDrop = () => {
        setEmailDropdown(prevState => !prevState)
        setMobileDropdown(false)
    }
    const [isOpenDropdownMobile, setMobileDropdown] = useState(false)

    const mobileDrop = () => {
        setMobileDropdown(prevState => !prevState)
        setEmailDropdown(false)
    }

    const getInitialState = () => {
        const value = 'other'
        return value
    }


    const [value, setValue] = useState(getInitialState)

    const onChangeResonInput = (e) => {
        setValue(e.target.value)
    }

    const [referralIn, setReferralIn] = useState([])

    const referralInData = (e) => {
        const email = sessionStorage.getItem('email')
        axios.post('https://vyaparapi.emedha.in/referralIn/get', {
            email
        }).then((res) => {
            setReferralIn(res.data)
            console.log(res.data)
        })

    }


    useEffect(() => {
        referralInData()
    }, [])

    const [photo, setPhoto] = useState()
    const [pname, setPname] = useState()
    const [price, setPrice] = useState()
    const [description, setDescription] = useState()
    const [discount, setDiscount] = useState()
    const [location, setLocation] = useState()
    const [discountPrice, setDiscountPrice] = useState()

    const viewProduct = (photo, price, location, pName, description, discount) => {
        setPhoto(photo)
        setPname(pName)
        setPrice(price)
        setDescription(description)
        setDiscount(discount)
        const dis = ((discount / 100) * price)
        setDiscountPrice(price - dis)
        setLocation(location)
        setLgShow(true)
    }

    const [ripple, setRipple] = useState(false)
    const [clasification, setIsClasification] = useState(false)

    const classification = (mapNumber) => {

        setRipple(true)



        axios.post('https://vyaparapi.emedha.in/classification', {

            mapNumber

        }).then((res) => {
            // setClasification(res.data)

            setIsClasification(true)
            setRipple(false)
        })
    }

    useEffect(() => {
        classification()
    }, [])





    return (
        <>

            <div className='referral-in-container'>
                <div className='container-fluid'>
                    <div className='row mt-5'>
                        <div className='col-lg-6 mx-auto'>
                            <h1>Referral In</h1>
                        </div>
                        <div className='col-lg-6 mx-auto' style={{position:'relative'}}>

                            <input
                                type="text"
                                className="shadow"
                                placeholder="Search"
                                style={{ width: '100%', border: 'none', height: '2.8rem', borderRadius: '0.3rem', paddingLeft:16}}

                            />
                            <BsSearch style={{ position: 'absolute', right: 16, top: 10.2,fontSize:23 }} />
                        </div>


                    </div>


                    <div className='row mb-3 mr-3'>
                        <div className='col-lg-12 mx-auto mb-4'>
                            <Paper elevation={1} >
                                <TableContainer sx={{ maxHeight: 1000, cursor: 'default' }}>

                                    <Table style={{ width: '100%' }}>
                                        <TableHead sx={{ backgroundColor: '#02375a', borderRadius: '0.7rem' }}>

                                            <TableRow>


                                                <TableCell

                                                    align={"start"}
                                                    style={{ top: 57, minWidth: 120, fontWeight: 'bold', color: 'white' }}
                                                >
                                                    Product
                                                </TableCell>

                                                <TableCell

                                                    align={"start"}
                                                    style={{ top: 57, minWidth: 120, fontWeight: 'bold', color: 'white' }}
                                                >
                                                    User Name
                                                </TableCell>

                                                <TableCell

                                                    align={"start"}
                                                    style={{ top: 57, minWidth: 120, fontWeight: 'bold', color: 'white' }}
                                                >
                                                    Location
                                                </TableCell>
                                                <TableCell

                                                    align={"start"}
                                                    style={{ top: 57, minWidth: 120, fontWeight: 'bold', color: 'white' }}
                                                >
                                                    Email
                                                </TableCell>

                                                <TableCell

                                                    align={"start"}
                                                    style={{ top: 57, minWidth: 120, fontWeight: 'bold', color: 'white' }}
                                                >
                                                    Referral Id
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {referralIn
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((data, index) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={data.code}>

                                                            <TableCell align={"start"}
                                                                style={{ top: 57, minWidth: 120 }}><img  style={{width:'19%'}} src={`https://vyaparapi.emedha.in/images/${data.photo}`} /></TableCell>

                                                            <TableCell align={"start"}
                                                                style={{ top: 57, minWidth: 350 }}>{data.customerSalerName}</TableCell>
                                                            <TableCell align={"start"}
                                                                style={{ top: 57, minWidth: 320 }}>{data.location}</TableCell>
                                                            <TableCell align={"start"}
                                                                style={{ top: 57, minWidth: 450 }}>{data.email}</TableCell>
                                                            <TableCell align={"start"}
                                                                style={{ top: 57, minWidth: 30, textDecoration: 'underline', cursor: 'pointer', textDecorationColor: 'black', fontWeight: 'bold' }} onClick={() => viewProduct(data.photo, data.price, data.location, data.pName, data.discription, data.discount)}>EV00{index + 1}</TableCell>

                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>

                                    </Table>



                                </TableContainer>

                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={referralIn.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />


                            </Paper>

                        </div>
                    </div>

                </div>







            </div >



            <Modal
                size="xl"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                centered
                backgroundColor='#e3010f'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Referral In Products
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className='row mx-auto referral-in-product'>


                            <div className='col-lg-4 mx-auto'>
                                <img width='75%' src={`https://vyaparapi.emedha.in/images/${photo}`} />

                            </div>
                            <div className='col-lg-6 mx-auto pop-up-referral-cont'>
                                <h1 className='product-type mt-5' ><span className='mobile-types'>Product: </span>{pname}</h1>
                                <p style={{ fontWeight: 'bold' }}><span className='mobile-types'>Price:</span>{price}/-<span className='discount-price'>{discountPrice}</span><span className='discount-percentage'>{discount}% off</span></p>
                                <p className='product-description'><span className='mobile-types'>Description:</span>{description}</p>

                                <p><span className='mobile-typ' style={{ color: 'black', marginTop: 5 }}><span className='mobile-types'>Location: </span></span> {location}</p>


                            </div>

                            <div className='col-lg-2 mx-auto mt-1 mb-1 text-center aceept-close-btn'>
                                <Button className='mt-0 mb-3' variant='dark' style={{ width: 120, marginRight: 25, backgroundColor: 'rgb(13, 10, 70)' }}>Accept</Button>
                                <Button className='mt-0 mb-3' variant='dark' style={{ width: 120, marginRight: 25, backgroundColor: '#d9370d' }} onClick={() => setDeclinePop(true)}>Decline</Button>
                            </div>




                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                size="lg"
                show={declinePop}
                onHide={() => setDeclinePop(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                centered

            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Referral Close
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className='form-referral-close-container'>


                        <div className='d-flex flex-column'>
                            <h1 style={{ fontSize: 20, fontFamily: 'Initial' }}>Reason For Decline:</h1>
                            <select className='select-input' onChange={onChangeResonInput}>
                                <option value="" disabled selected>Select Your Reason</option>
                                <option value='Not Interest'>Not Interest</option>
                                <option value='Price High'>Price High</option>
                                <option value='Not Lifting Call'>Not Lifting Call</option>
                                <option value='Other'>Other</option>

                            </select>

                            {value === 'Other' && <textarea textarea rows='5' cols='50' placeholder='Type your reason' style={{ backgroundColor: 'white', marginTop: 12 }}></textarea>}


                        </div>


                        <div className='mt-3 mb-2'>
                            <Button variant='dark' style={{ width: 120, marginRight: 25, backgroundColor: 'rgb(13, 10, 70)' }}>Submit</Button>

                        </div>
                    </form>
                </Modal.Body>
            </Modal>


        </>
    );
}


export default ReferralIn









