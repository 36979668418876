import React, { useState, useEffect } from 'react'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import axios from 'axios';
import Button from '@mui/material/Button';
import Swal from "sweetalert2";
import { Typography } from '@mui/material';

const GstKyc = () => {

    const [wait, setWait] = useState(false)

    const GstUpdate = () => {
        Swal.fire({
            title: "Successful..!",
            cancelButtonText: 'Cancel',
            text: "Your GST number successfully updated...!",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Dashboard",
        }).then((result) => {
            if (result.value) {
                window.location.reload()
            } else {

            }
        });
    }


    const [GstValidated, setGstValidated] = useState(false);

    const handleSubmitGst = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            gstVerification()
        }
        setGstValidated(true);
    };

    const [GST, setGst] = useState('')
    const [pan, setPan] = useState('')

    const gstVerification = () => {
        setWait(true)
        const url = 'https://vyaparapi.emedha.in/e-vyapar/v1/submit/gstin'
        axios.post(url, {
            GSTIN: GST,
            businessName: 'test'
        }).then((res) => {
            if (res.data.statusCode === 200) {
                kyc()
                setWait(false)
            }
        }).catch((err) => {
            console.log('Somthing wrong, (please enter valid GSTIN)', err)
        })
    }

    const kyc = () => {

        const kycType = 'gst'
        axios.post('https://vyaparapi.emedha.in/kyc', {
            userId: sessionStorage.getItem('userId'),
            gst: GST,
            pan,
            kycType
        }).then((res) => {
            GstUpdate()
        })
    }

    const [isGstStatus, setIsGstStatus] = useState()

    const userKycCheck = () => {
        axios.post('https://vyaparapi.emedha.in/e-vyapar/pg/v1/p-status-check', {
            userId: sessionStorage.getItem('userId')
        }).then((res) => {
            setIsGstStatus(res.data.data[0].isGstKyc)
            setGst(res.data.data[0].GstNumber)
            setPan(res.data.data[0].pan)
        })
    }

    useEffect(() => {
        userKycCheck()
    }, [])


    return (
        <div>
            <h4>Business Details</h4>
            <p style={{ fontSize: '0.8rem' }}>A business description is a high-level overview of your company that you include in your business plan. Your business description should entice readers—like lenders and investors—to look through the rest of your business plan to learn more about your company.</p>
            <Form noValidate validated={GstValidated} onSubmit={handleSubmitGst} >
                <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                        <Form.Label>Gst Number</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder={`${isGstStatus === 0 ? "EX: 36AB CDE7 661F1GH" : "XXXX XXXX " + GST.substring(GST.length + 4, 8)}`}
                            disabled={(isGstStatus !== 0)}
                            style={{ padding: '0.8rem' }}
                            onChange={(e) => {
                                setGst(e.target.value)
                            }}
                        />

                        {
                            isGstStatus !== 0 && (
                                <Typography style={{ color: 'rgb(42, 179, 42)' }} >Verified <img src='https://cdn-icons-png.flaticon.com/128/3472/3472620.png' style={{ width: '16px' }} /></Typography>
                            )
                        }

                        <Form.Control.Feedback type="invalid">
                            Please Enter Valid GST Number
                        </Form.Control.Feedback>
                        <br />
                    </Form.Group>


                    <Form.Group as={Col} md="12" controlId="validationCustom02">
                        <Form.Label>Pan Card Number</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={`${isGstStatus === 0 ? "EX: ABCD E7661F " : "XXXX " + pan.substring(pan.length + 4, 4)}`}
                            disabled={(isGstStatus !== 0)}
                            aria-describedby="inputGroupPrepend"
                            required
                            style={{ padding: '0.8rem' }}
                            onChange={(e) => {
                                setPan(e.target.value)
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please Enter Valid Pan Card Number .
                        </Form.Control.Feedback>
                        <br />
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                        <Form.Label>GST Image </Form.Label>

                        <InputGroup hasValidation>
                            <Form.Control
                                type="file"
                                aria-describedby="inputGroupPrepend"
                                disabled={(isGstStatus !== 0)}
                                required
                                style={{ padding: '0.8rem' }}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please Enter Valid GST Image .
                            </Form.Control.Feedback>
                            <br />
                        </InputGroup>
                    </Form.Group>
                </Row>
                <br />
                <div className='d-flex'>
                    <Button disabled={wait} className='mr-2' type='submit' variant="contained" style={{ backgroundColor: '#12124e', padding: '0.6rem', width: '9rem', marginTop: '7rem' }}>
                        {
                            wait ? 'Submiting' : 'Submit'
                        }
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default GstKyc