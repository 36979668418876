// import "./index.css";
// import { Button } from "react-bootstrap";
// import "./index.css";
// import { useAppStore } from "../AppStore";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import { CgDetailsMore } from "react-icons/cg";
// import StoreProductDetails from "../StoreProductDetailView";
// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import AdminNavbar from "../AdminNavbar";

// const AdminStore = () => {
//     const [signupData, setSignupData] = useState([]);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [itemsPerPage] = useState(5); // Define how many items per page
  
//     // Fetch all signup data
//     const fetchSignupData = async () => {
//       try {
//         const response = await axios.get('http://localhost:3000/api/signup');
//         setSignupData(response.data);
//       } catch (error) {
//         console.error('Error fetching signup data:', error);
//       }
//     };
  
//     // Use effect to fetch data when component mounts
//     useEffect(() => {
//       fetchSignupData();
//     }, []);
  
//     // Calculate pagination
//     const indexOfLastItem = currentPage * itemsPerPage;
//     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//     const currentItems = signupData.slice(indexOfFirstItem, indexOfLastItem);
  
//     // Handle page change
//     const handlePageChange = (pageNumber) => {
//       setCurrentPage(pageNumber);
//     };
  
//     // Total number of pages
//     const totalPages = Math.ceil(signupData.length / itemsPerPage);
 
//   React.useEffect(() => {
//     if (!sessionStorage.getItem('adminEmail')) {
//         window.location.href = '/admin-login'
//     }
// }, [])


// console.log(currentItems)
//   return (
//     <>
//       <AdminNavbar />

//       <div>
//       <h1>Signup Table</h1>
//       <table>
//         <thead>
//           <tr>
//             <th>ID</th>
//             <th>Name</th>
//             <th>Email</th>
//             <th>Company Name</th>
//           </tr>
//         </thead>
//         <tbody>
//           {currentItems.map((signup,index) => (
//             <tr key={signup.id}>
//               <td>{index+1}</td>
//               <td>{signup.fullName}</td>
//               <td>{signup.email}</td>
//               <td>{signup.companyName}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       <div>
//         {/* Pagination Controls */}
//         <button
//           onClick={() => handlePageChange(currentPage - 1)}
//           disabled={currentPage === 1}
//         >
//           Previous
//         </button>
//         <span> Page {currentPage} of {totalPages} </span>
//         <button
//           onClick={() => handlePageChange(currentPage + 1)}
//           disabled={currentPage === totalPages}
//         >
//           Next
//         </button>
//       </div>
//     </div>
//     </>
//   );
// };

// export default AdminStore;


import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Table, Pagination } from "react-bootstrap";
import AdminNavbar from "../AdminNavbar";
import "./index.css";

const AdminStore = () => {
  const [signupData, setSignupData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5); // Define how many items per page

  // Fetch all signup data
  const fetchSignupData = async () => {
    try {
      const response = await axios.get("https://vyaparapi.emedha.in/api/signup");
      setSignupData(response.data);
    } catch (error) {
      console.error("Error fetching signup data:", error);
    }
  };

  // Use effect to fetch data when component mounts
  useEffect(() => {
    fetchSignupData();
  }, []);

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = signupData.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle items per page change
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to page 1 when items per page changes
  };

  // Total number of pages
  const totalPages = Math.ceil(signupData.length / itemsPerPage);

  // Redirect if no admin session exists
  React.useEffect(() => {
    if (!sessionStorage.getItem("adminEmail")) {
      window.location.href = "/admin-login";
    }
  }, []);

  return (
    <>
      <AdminNavbar />

      <div>
        <h1 className="mb-4">Users Table</h1>

        {/* Table design using Bootstrap */}
        <Table responsive striped bordered hover>
          <thead className="thead-dark">
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Company Name</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((signup, index) => (
              <tr key={signup.id}>
                <td>{index + 1}</td>
                <td>{signup.fullName}</td>
                <td>{signup.email}</td>
                <td>{signup.companyName}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* Items per page dropdown */}
        

        {/* Pagination Controls using Bootstrap */}
        <div className="d-flex justify-content-center mt-3">
          <Pagination>
            <Pagination.First
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            />
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            
            {/* Pagination Item Numbers */}
            {/* {[...Array(totalPages)].map((_, index) => {
              const pageNum = index + 1;
              return (
                <Pagination.Item
                  key={pageNum}
                  active={pageNum === currentPage}
                  onClick={() => handlePageChange(pageNum)}
                >
                  {pageNum}
                </Pagination.Item>
              );
            })} */}

            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </div>
        <div className="d-flex justify-content-between mt-3">
       
          <select
            className="form-select w-auto"
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            style={{ width: "auto", margin: "0 auto" }}
          >
            {[10, 20, 30, 40, 50].map((size) => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

export default AdminStore;


