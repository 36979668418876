import { FaRegHandPointRight } from 'react-icons/fa'
import './index.css'
import Navigation from '../LandingPage/Navigation'
import { Col, Row } from 'react-bootstrap'
import Footer from '../../components/LandingPage/Footer'

const AboutPage = () => {

    return (
        <>
            <Navigation />

            <div className='container-fluid about-page-container' style={{ marginTop: '6rem' }} >
                <div className='row about-img-card'>
                    <div className='col-lg-6 mx-auto' style={{ color: 'white' }}>
                        <h1 className='about-us-heading' >E-Vyapar Exchange LLP : The Business Network Platform.</h1>
                        <p className='about-us-description'>e-Vyapar Exchange LLP is a Hyderabad referral network platform which has emerged locally and is operational across the globe. The platform network has been put in place to offer one-stop solution for enhance the business scope for members associated with the exchange platform.</p>
                        <p className='benefits-heading'>The interim benefits of the Exchange Program:</p>
                        <p><FaRegHandPointRight style={{ marginRight: 8, color: '#d9370d' }} />The members receive qualified leads all the time.</p>
                        <p><FaRegHandPointRight style={{ marginRight: 8, color: '#d9370d' }} />The referred customers would persistently increase all the time.</p>
                        <p><FaRegHandPointRight style={{ marginRight: 8, color: '#d9370d' }} />The referred customers develop a kind of loyalty for recurring sales.</p>

                    </div>
                    <div className='col-lg-4 mx-auto'>
                        <img src='http://www.vyaparexchange.com/assets/img/memberteam.png' className='management-image' />
                    </div>
                </div>
                {/* <div className='row'>
                    <div className='card-container'>
                        <div className='our-mission-card'>
                            <img src='http://www.vyaparexchange.com/assets/img/objective.png' className='card-img' />
                            <div className='card-content'>
                                <h1 className='our-object-heading'>Our Objective</h1>
                                <p className='our-object-description'>To CONNECT business community in a relation, BUILD through technology and GROW business through EVE.</p>
                            </div>
                        </div>
                    </div>
                </div> */}

<div style={{ display: 'flex',justifyContent: 'center', alignItems: 'center',  width: "100%", flexWrap: 'wrap', margin: '0 auto' }}>
<Row style={{ width: '100%', justifyContent: 'center' }}>
        {/* Vyapar Objective Card */}
        <Col xs={12} sm={6} md={4} lg={3} style={{ padding: '10px' }}>
            <div className="carddd" style={{
                backgroundColor: "red",
                borderRadius: '10px',
                textAlign: 'center',
                color: "white",
                height: '100%',
                width: '100%',
                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                margin: '5px'
            }}>
                <img
                    alt="Icon of a person pointing to a checklist"
                    height="100"
                    width="100"
                    style={{ width: '200px', height: '200px' }}
                    src="https://vyaparexchange.com/assets/img/objective.png"
                />
                <h2 style={{ fontFamily: "'Comic Sans MS', cursive, sans-serif", fontSize: '24px', margin: '10px 0' }}>Vyapar OBJECTIVE</h2>
                <h3 style={{ fontSize: '20px', margin: '10px 0' }}>Our Objective</h3>
                <p style={{ fontSize: '16px', lineHeight: '1.5',padding:"20px" }}>
                    To CONNECT business community in a relation, BUILD through technology and GROW business through EVE.
                </p>
            </div>
        </Col>

        {/* Vyapar Vision Card */}
        <Col xs={12} sm={6} md={4} lg={3} style={{ padding: '10px' }}>
            <div className="carddd" style={{
                backgroundColor: "red",
                borderRadius: '10px',
                textAlign: 'center',
                color: "white",
                height: '100%',
                width: '100%',
                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                margin: '10px'
            }}>
                <img
                    alt="Icon of a telescope"
                    height="100"
                    width="100"
                    style={{ width: '200px', height: '200px' }}
                    src="https://vyaparexchange.com/assets/img/vision.png"
                />
                <h2 style={{ fontFamily: "'Comic Sans MS', cursive, sans-serif", fontSize: '24px', margin: '10px 0' }}>Vyapar VISION</h2>
                <h3 style={{ fontSize: '20px', margin: '10px 0' }}>Our Vision</h3>
                <p style={{ fontSize: '16px', lineHeight: '1.5',padding:"20px" }}>
                    EVE initiates, indoctrinates, involves, instructs and impacts businesses and entrepreneurs.
                </p>
            </div>
        </Col>

        {/* Vyapar Mission Card */}
        <Col xs={12} sm={6} md={4} lg={3} style={{ padding: '10px' }}>
            <div className="carddd" style={{
                backgroundColor: "red",
                borderRadius: '10px',
                textAlign: 'center',
                color: "white",
                height: '100%',
                width: '100%',
                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                margin: '10px'
            }}>
                <img
                    alt="Icon of a target with an arrow"
                    height="100"
                    width="100"
                    style={{ width: '200px', height: '200px' }}
                    src="https://vyaparexchange.com/assets/img/mission.png"
                />
                <h2 style={{ fontFamily: "'Comic Sans MS', cursive, sans-serif", fontSize: '24px', margin: '10px 0' }}>Vyapar MISSION</h2>
                <h3 style={{ fontSize: '20px', margin: '10px 0' }}>Our Mission</h3>
                <p style={{ fontSize: '16px', lineHeight: '1.5',padding:"20px" }}>
                    To bond businesses supportively closer and mutually complimentary.
                </p>
            </div>
        </Col>
    </Row>
</div>

            </div>
            <div style={{ marginTop: 21 }} >
                <Footer />
            </div>
        </>
    )
}


export default AboutPage


//varma 13-11-2024

// import { FaRegHandPointRight } from 'react-icons/fa';
// import './index.css';
// import Navigation from '../LandingPage/Navigation';

// const AboutPage = () => {
//     return (
//         <>
//             <Navigation />
//             <div className="container-fluid about-page-container" style={{ marginTop: '9rem' }}>
//                 <div className="row about-img-card">
//                     <div className="col-lg-6 col-md-8 col-sm-12 mx-auto" style={{ color: 'black' }}>
//                         <h1 className="about-us-heading">E-Vyapar Exchange LLP : The Business Network Platform.</h1>
//                         <p className="about-us-description">
//                             e-Vyapar Exchange LLP is a Hyderabad referral network platform which has emerged locally and is operational across the globe. The platform network has been put in place to offer one-stop solution for enhancing the business scope for members associated with the exchange platform.
//                         </p>
//                         <p className="benefits-heading">The interim benefits of the Exchange Program:</p>
//                         <div className="benefit-list">
//                             <p className="benefit-item"><FaRegHandPointRight /> The members receive qualified leads all the time.</p>
//                             <p className="benefit-item"><FaRegHandPointRight /> The referred customers would persistently increase all the time.</p>
//                             <p className="benefit-item"><FaRegHandPointRight /> The referred customers develop a kind of loyalty for recurring sales.</p>
//                         </div>
//                     </div>
//                     <div className="col-lg-4 col-md-4 col-sm-12 mx-auto">
//                         <img src="http://www.vyaparexchange.com/assets/img/memberteam.png" alt="Team" className="management-image" />
//                     </div>
//                 </div>

//                 <div className="row">
//                     <div className="card-container">
//                         <div className="our-mission-card">
//                             <img src="http://www.vyaparexchange.com/assets/img/objective.png" alt="Objective" className="card-img" />
//                             <div className="card-content">
//                                 <h1 className="our-object-heading">Our Objective</h1>
//                                 <p className="our-object-description">
//                                     To CONNECT business community in a relation, BUILD through technology and GROW business through EVE.
//                                 </p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default AboutPage;
