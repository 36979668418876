import React from 'react';
import { FaGreaterThan } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const RefundPolicy = () => {
    const containerStyle = {
        padding: '40px',
       
        margin: '0 auto',
        fontFamily: 'Arial, sans-serif'
    };

    const headerStyle = {
        color: '#333',
    };

    const subHeaderStyle = {
        color: '#666',
        marginTop: '20px'
    };

    const paragraphStyle = {
        textAlign: 'left',
        lineHeight: '1.6'
    };

    const listStyle = {
        textAlign: 'left',
        lineHeight: '1.6'
    };
    const banner = {
        height: '250px',
        backgroundColor: 'rgb(3, 3, 56)',
        color: 'white',
        padding: '45px',
    };

    return (
        <>
            <div style={banner}>
                <h1>Refund Policy</h1>
                <div style={{ borderBottom: '3px solid white', width: '150px' }}></div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                    <Link to='/' style={{ textDecoration: 'none', color: 'white' }}>  <p style={{ marginRight: '12px' }}>Home <FaGreaterThan /></p></Link>
                    <p>Refund Policy</p>
                </div>
            </div>
            <div style={containerStyle}>
               
                <h2 style={subHeaderStyle}>Refund Limitations</h2>
                <ul style={listStyle}>
                    <li>
                        <strong>Non-refundable Fees:</strong> As it’s a subscription-based fee, it is non-refundable.
                    </li>
                </ul>
            </div>
        </>
    );
};

export default RefundPolicy;
