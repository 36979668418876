import '../node_modules/bootstrap/dist/css/bootstrap.css'

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import LoginPage from './components/LoginPage';
import Dashboard from './components/Dashboard';
import AdminLoginPage from './components/AdminLoginPage';
import CostomerDashboard from './components/AdminDashboard/CostomerDashboard';
import LandingPage from './components/LandingPage/LandingPage';
import ContactPageData from './components/ContactPage';
import AboutPage from './components/AboutPage';
import Signup from './components/RegisterForm';
import PrivacyPolicy from './components/LandingPage/PrivacyPolicy';
import RefundPolicy from './components/LandingPage/RefundPolicy';


const App = () => {


   return (

      <>

         <BrowserRouter>
            <Routes>
               <Route path="/" element={<LandingPage></LandingPage>}></Route>
               <Route path="/login" element={<LoginPage />}></Route>
               <Route path='/user-dashboard' element={<Dashboard></Dashboard>} ></Route>
               <Route path='/admin-login' element={<AdminLoginPage></AdminLoginPage>} ></Route>
               <Route path='/admin-store' element={<CostomerDashboard></CostomerDashboard>} ></Route>
               <Route path='/Contact' element={<ContactPageData />} />
               <Route path='/About' element={<AboutPage />} />
               <Route path='/sign-up' element={<Signup />} />
               <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
               <Route path='/refund-policy' element={<RefundPolicy/>}/>
           
            </Routes>
         </BrowserRouter>

      </>
   )
}

export default App;
