import React from "react";
import Slider from "react-slick";
import './Carousel.css'
import SliderWrapper from "./SliderWrapper";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const PrevArrowBtn = (props) => {
    return (
        <div className={props.className} onClick={props.onClick}>
            <ChevronLeftIcon style={{ color: 'white' }} />
        </div>
    )
}

const NextArrowBtn = (props) => {
    return (
        <div className={props.className} onClick={props.onClick} >
            <NavigateNextIcon style={{ color: 'white' }} />
        </div>
    )
}



const TopCarausel = () => {

    const settings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 500,
        adaptiveHeight: true,
        appendDots: dots => <ul>{dots}</ul>,
        customPaging: i => (
            <div className="ft-slick__dots--custom">
                <div className="loading" />
            </div>
        )

    };


    return (
        <div className="carousel-con">
            <SliderWrapper className="d-none d-lg-block">
                <Slider arrows={true} prevArrow={<PrevArrowBtn></PrevArrowBtn>} nextArrow={<NextArrowBtn></NextArrowBtn>} {...settings}>
                    <div className="testimoni--wrapper w-100">
                        <img src={require('../Images/img3.jpeg')} />
                    </div>
                    <div className="testimoni--wrapper">
                        <img src={require('../Images/img3.jpeg')} />
                    </div>
                    <div className="testimoni--wrapper">
                        <img src={require('../Images/img1.jpeg')} />
                    </div>
                    <div className="testimoni--wrapper">
                        <img src={require('../Images/ad4.jpeg')} />
                    </div>
                    <div className="testimoni--wrapper">
                        <img src={require('../Images/ad5.jpeg')} ></img>
                    </div>
                </Slider>
            </SliderWrapper>
            <SliderWrapper className="d-block d-lg-none">
                <Slider arrows={false} prevArrow={<PrevArrowBtn></PrevArrowBtn>} nextArrow={<NextArrowBtn></NextArrowBtn>} {...settings}>
                    <div className="testimoni--wrapper w-100">
                        <img className="img1" src={require('../Images/img3.jpeg')} />
                    </div>
                    <div className="testimoni--wrapper">
                        <img className="img2" src={require('../Images/img2.jpeg')} />
                    </div>
                    <div className="testimoni--wrapper">
                        <img className="img3" src={require('../Images/img1.jpeg')} />
                    </div>
                    <div className="testimoni--wrapper">
                        <img className="img4" src={require('../Images/ad4.jpeg')} />
                    </div>
                    <div className="testimoni--wrapper">
                        <img className="img5" src={require('../Images/ad5.jpeg')} ></img>
                    </div>
                </Slider>
            </SliderWrapper>
        </div>
    );

}

export default TopCarausel