import React from 'react'
import Navigation from './Navigation'
import TopCarausel from './TopCarausel'
import SmallCarousel from './SmallCarousel/SmallCarousel'
import ProductCarousel from './ProductsCarousel/ProductCarousel'
import ShopType from './ShopType'
import Advertisements from './Advertisements/Advertisements'
import Footer from './Footer'

const LandingPage = () => {
    return (
        <div style={{ backgroundColor: 'rgb(254, 224, 224)' }}>
            <Navigation />
            <div style={{ marginTop: 120 }} >
                <TopCarausel />
            </div>


            <div style={{ marginTop: 4, marginBottom: 30 }} >
                <ProductCarousel />
            </div>

            <div>
                <SmallCarousel />
            </div>

            <div className='p-2 pt-3'>
                <ShopType />
            </div>
            <div>
                <Advertisements />
            </div>
            <div className='mt-3'>
                <SmallCarousel />
            </div>
            <br />
            <br />
            <div style={{ marginTop: 21 }} >
                <Footer />
            </div>
        </div>
    )
}

export default LandingPage