import React from 'react'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { FaCheckCircle, FaCircle, FaDotCircle, FaRupeeSign } from 'react-icons/fa'
import SimpleBackdrop from '../BackDrop/BackDrop'
import {useNavigate} from 'react-router-dom'

const Signup = () => {

    const navigate = useNavigate()
    
    const [isSubVertical, setIsSubVertical] = useState(false)
    const [isBusinessVertical, setIsBusinessVertical] = useState(false)
    const [subVerticalData, setSubVerticalData] = useState('Select Product')
    const [validate, setValidate] = useState(false)
    const [first_name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [state, setState] = useState('Select State')
    const [city, setCity] = useState('City')
    const [address, setAddress] = useState('')
    const [cName, setCName] = useState('')
    const [pass, setPass] = useState('')
    const [cPass, setCPass] = useState('')



    const validation = () => {
        if (email === '' || first_name === '' || mobile === '' || state === '' || city === '' || cName === '' || address === '' || subVerticalData === 'Select Sub Vertical') {
            setValidate(true)
            setTimeout(() => setValidate(false), 5000)
        } else {
            if (pass !== cPass) {
                alert('Confirm password not matched')

            } else {
                sendEmailVerificationOtp(subVerticalData, cName)
            }
        }
    }

    const [isOtp, setIsOtp] = useState(false)
    const [otp, setOtp] = useState('')
    const [otp1, setOtp1] = useState('')
    const [token, setToken] = useState('')
    const [sntEmail, setSntEmail] = useState(false)

    const sendEmailVerificationOtp = (subVertical, companyName) => {

        setSntEmail(true)
        setValidate(false)

        axios.post('https://vyaparapi.emedha.in/signup', {
            email,
            first_name,
            mobile,
            city,
            address,
            state,
            companyName,
            subVertical,
        }).then((res) => {
            if (res.data.status === 200) {

                setSntEmail(false)
                setIsOtp(true)
                setOpen1(true)
                sessionStorage.setItem('userId', res.data.userId)
                setOtp1(res.data.otp)
                setToken(res.data.token)
            } else {
                alert('already register')
                setSntEmail(false)
            }
        })
    }

    const [subVer, setSubVer] = useState([])



    const [isWait, setIsWait] = useState(false)

    const subVertical = () => {

        setIsWait(true)

        axios.post('https://vyaparapi.emedha.in/sub-vertical').then((res) => {
            setSubVer(res.data)
            setIsSubVertical(true)
            setOpen1(true)
            setIsWait(false)
        })
    }

    const [citys, setCitys] = useState([])
    const [states, setStates] = useState([])
    const [isState, setIsState] = useState(false)
    const [isCity, setIsCity] = useState(false)

    const cityData = (mapNumber) => {
        axios.post('https://vyaparapi.emedha.in/city', {
            mapNumber
        }).then((res) => {
            setCitys(res.data)
            setIsCity(true)
        })
    }



    const StateData = () => {
        setIsWait(true)
        axios.get('https://vyaparapi.emedha.in/state').then((res) => {
            setStates(res.data)
            setIsState(true)
            setIsWait(false)
            setOpen(true)
        })
    }


    const [otpError, setOtpErrore] = useState(false)
    const [isPay, setIsPay] = useState(false)
    const [isSuccessfullLogin, setIsSuccessFullLogin] = useState(false)

    const emailVerification = async () => {
        if (parseInt(otp1) !== parseInt(otp)) {
            setOtpErrore(true)
            return
        }
        axios.post('https://vyaparapi.emedha.in/emailVerification', {
            email,
            token,
            pass,
        }).then((res) => {
            if (res.data.status === 200) {
                setIsSuccessFullLogin(true)
                setIsOtp(false)
            } else {
                alert(res.data.message)
            }
        })
    }

    const [payUrl, setPayUrl] = useState()
    const [isPayConfirm, setIsPayConfirm] = useState(false)

    const pay = () => {

        const userId = sessionStorage.getItem('userId')
        axios.post(`https://vyaparapi.emedha.in/e-vyapar/pg/v1/pay`, {
            payText: payText,
            userId: userId
        }).then((res) => {
            if (res.data.success) {
                setPayUrl(res.data.url)
                setIsPay(false)
                setIsPayConfirm(true)
            } else {
                alert('Somthing went wrong')
            }
        })

    }

    const [subscription1, setSubscription1] = useState(false)
    const [subscription2, setSubscription2] = useState(false)
    const [subscription3, setSubscription3] = useState(false)
    const [subscription4, setSubscription4] = useState(false)
    const [payText, setPayText] = useState('')

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const handleClickOpen = () => {
        StateData()
    };

    const handleClose = () => {
        setOpen(false);
        setIsState(false)
        setIsCity(false)
    };

    const [open1, setOpen1] = React.useState(false);

    const handleClose1 = () => {
        setOpen1(false);
    };

    return (
        <div className='container mt-4' >
            <div className='col-md-11 col-12 mx-auto' >
                <div className='row p-2 ' style={{ backgroundColor: 'lightgray', borderRadius: 4 }} >
                    <div className='col-12 col-md-3 col-lg-4 mb-3' style={{ position: 'relative' }}  >
                        <div style={{ marginTop: 17, marginBottom: 5 }} >
                            <Typography style={{ fontSize: 28, fontWeight: 'bold', color: 'red' }} >Signup</Typography>
                            <Typography style={{ width: '70%', fontSize: 13 }} >Get acees to contact saller with the help of email and whatsApp referral</Typography>
                        </div>
                        <img className='d-flex d-md-none' style={{ width: '100%', borderRadius: 3 }} src={require('../LoginPage/image/login.jpeg')} />
                        <img className='d-none d-md-flex' style={{ position: 'absolute', bottom: 16, left: 0, width: '100%', borderRadius: 3 }} src={require('../LoginPage/image/login.jpeg')} />
                    </div>
                    <div className='col-12 col-lg-8 col-md-9' >
                        <div className='row' >

                            <div className='col-6' >
                                <Typography>Full Name</Typography>
                                <input style={{ height: '2.8rem', width: '100%', border: '1px solid lightgray', outline: 'none', borderRadius: '0.3rem', marginBottom: 10 }} onChange={(e) => setName(e.target.value)} ></input>
                            </div>
                            <div className='col-6'>
                                <Typography>Email</Typography>
                                <input style={{ height: '2.8rem', width: '100%', border: '1px solid lightgray', outline: 'none', borderRadius: '0.3rem', marginBottom: 10 }} onChange={(e) => setEmail(e.target.value)} ></input>
                            </div>
                            <div className='col-12'>
                                <Typography>Mobile</Typography>
                                <input style={{ height: '2.8rem', width: '100%', border: '1px solid lightgray', outline: 'none', borderRadius: '0.3rem', marginBottom: 10 }} onChange={(e) => setMobile(e.target.value)} ></input>
                            </div>
                            <div className='col-6'>
                                <Typography>State</Typography>
                                <select onClick={() => handleClickOpen()} style={{ height: '2.8rem', width: '100%', border: '1px solid lightgray', outline: 'none', borderRadius: '0.3rem', marginBottom: 10 }}>
                                    <option disabled selected>{state}</option>
                                </select>
                            </div>
                            <div className='col-6'>
                                <Typography>Address</Typography>
                                <input style={{ height: '2.8rem', width: '100%', border: '1px solid lightgray', outline: 'none', borderRadius: '0.3rem', marginBottom: 10 }} onChange={(e) => setAddress(e.target.value)} ></input>
                            </div>
                            <div className='col-md-6 col-12'>
                                <Typography>Company Name</Typography>
                                <input style={{ height: '2.8rem', width: '100%', border: '1px solid lightgray', outline: 'none', borderRadius: '0.3rem', marginBottom: 10 }} onChange={(e) => setCName(e.target.value)} ></input>
                            </div>
                            <div className='col-12 col-md-6'>
                                <Typography>Select Product</Typography>
                                <select onClick={() => {
                                    subVertical()
                                }} style={{ height: '2.8rem', width: '100%', border: '1px solid lightgray', outline: 'none', borderRadius: '0.3rem', marginBottom: 10 }}>
                                    <option disabled selected>{subVerticalData}</option>
                                </select>
                            </div>
                            <div className='col-6'>
                                <Typography>Password</Typography>
                                <input type='password' style={{ height: '2.8rem', width: '100%', border: '1px solid lightgray', outline: 'none', borderRadius: '0.3rem', marginBottom: 10 }} onChange={(e) => setPass(e.target.value)} ></input>
                            </div>
                            <div className='col-6'>
                                <Typography>Confirm Password</Typography>
                                <input type='password' style={{ height: '2.8rem', width: '100%', border: '1px solid lightgray', outline: 'none', borderRadius: '0.3rem', marginBottom: 10 }} onChange={(e) => setCPass(e.target.value)} ></input>
                            </div>
                            {
                                validate && <Typography style={{ color: 'red', textAlign:'center', marginTop:'2rem' }} >All field is Required *</Typography>
                            }

                            {
                                sntEmail && <Typography style={{ color: 'red', textAlign:'center', marginTop:'2rem' }} >Wait ....</Typography>
                            }

                            <div className='col-12 mt-5' >
                                <button style={{ backgroundColor: 'red', width: '5rem', borderRadius: '0.2rem', color: 'white', height: '2.6rem', border: 'none' }} onClick={() => validation()} >Sign Up</button>
                            </div>
                            <Typography style={{ color: 'red', fontSize: 13, textAlign: 'center', marginTop: '2rem', cursor: 'pointer', marginBottom: '4rem' }} onClick={() => navigate('/login')} >You have already an account ? Login now</Typography>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    maxWidth='lg'
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        {isState ? "Please Select Your State" : "Please Select Your City"}
                    </DialogTitle>
                    <DialogContent>
                        {
                            isState && (
                                <div>
                                    <div style={{ width: '19rem' }} >
                                        <div>
                                            {
                                                states.map((data) => {
                                                    return (
                                                        <Typography onClick={() => {
                                                            setState(data.name)
                                                            setIsState(false)
                                                            cityData(data.sno)
                                                        }} style={{ cursor: 'pointer', padding: 10.5, borderRadius: '0.03rem', borderLeft: '2px solid white', marginBottom: 14, backgroundColor: 'red', color: 'white', fontSize: '0.9rem', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: 'none' }} className='card' ><Typography>{data.name}</Typography>  <Typography ><FaCircle /></Typography></Typography>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {
                            isCity && (
                                <div>
                                    <div style={{ width: '19rem' }} >
                                        <div>
                                            {
                                                citys.map((data) => {
                                                    return (
                                                        <Typography onClick={() => {
                                                            setCity(data.name)
                                                            setIsState(false)
                                                            setIsCity(false)
                                                            handleClose()
                                                            setIsState()
                                                        }} style={{ cursor: 'pointer', padding: 10.5, borderRadius: '0.03rem', borderLeft: '2px solid white', marginBottom: 14, backgroundColor: 'red', color: 'white', fontSize: '0.9rem', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: 'none' }} className='card' ><Typography>{data.name}</Typography>  <Typography ><FaCircle /></Typography></Typography>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    {
                                        (citys.length === 0) && <Typography style={{ textAlign: 'center', color: 'red', marginTop: 10 }} >0 Cities</Typography>
                                    }
                                </div>
                            )
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} autoFocus>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

            <div>
                <Dialog
                    fullScreen={fullScreen}
                    open={open1}
                    maxWidth='lg'
                    aria-labelledby="responsive-dialog-title"
                >
                    {
                        (!isSuccessfullLogin && !isPayConfirm) && (
                            <DialogTitle id="responsive-dialog-title">
                                {isBusinessVertical ? "Please Select Your Business Verticle" : (isSubVertical ? "Please Select Your Product" : (isOtp ? "Email Verification" : (isPay ? "" : "Please Select Your Classification")))}
                            </DialogTitle>
                        )
                    }
                    <DialogContent>
                        {
                            isSubVertical && (
                                <div>
                                    <div style={{ width: '19rem' }} >
                                        <div>
                                            {
                                                subVer.map((data) => {
                                                    return (
                                                        <Typography onClick={() => {
                                                            setSubVerticalData(data.name)
                                                            setIsSubVertical(false)
                                                            setOpen1(false)
                                                        }} style={{ cursor: 'pointer', padding: 10.5, borderRadius: '0.03rem', borderLeft: '2px solid white', marginBottom: 14, backgroundColor: 'red', color: 'white', fontSize: '0.9rem', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', border: 'none' }} className='card' ><Typography>{data.name}</Typography>  <Typography ><FaCircle /></Typography></Typography>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    {
                                        (subVer.length === 0) && <Typography style={{ textAlign: 'center', color: 'red', marginTop: 10 }} >0 Sub Vertical</Typography>
                                    }
                                </div>
                            )
                        }
                        {
                            isOtp && (
                                <div>
                                    <Typography style={{ color: 'white', padding: 10.5, backgroundColor: 'rgb(69, 182, 99)', borderRadius: '0.03rem', borderLeft: '5px solid green', marginBottom: 10 }}>Otp sent on your register email please check and follow given instuction .!</Typography>
                                    <Typography style={{ marginTop: 10 }} >Enter Otp</Typography>
                                    <input style={{ height: '2.8rem', width: '100%', border: '1px solid lightgray', outline: 'none', borderRadius: '0.3rem', marginBottom: 23 }} autoFocus onChange={(e) => setOtp(e.target.value)} ></input>
                                    <button style={{ backgroundColor: 'red', width: '9rem', borderRadius: '0.2rem', color: 'white', height: '2.6rem', border: 'none', }} onClick={() => emailVerification()} >Verify</button>
                                </div>
                            )
                        }
                        {
                            isSuccessfullLogin && (
                                <div>
                                    <div className='card' style={{ backgroundColor: 'red', justifyContent: 'center', alignItems: 'center', border: 'none', border: 'none', paddingTop: 5, paddingBottom: 5, width: '21rem' }} >
                                        <FaCheckCircle color='green' style={{ fontSize: 29, color: 'white' }} />
                                        <br />
                                        <Typography style={{ fontSize: 15, color: 'white', marginBottom: 10 }} >You are login successfully !</Typography>
                                        <br />
                                        <button style={{ width: '11rem', backgroundColor: 'white', borderRadius: '0.3rem', height: '2.4rem', border: 'none' }} onClick={() => {
                                            setIsPay(true)
                                            setIsSuccessFullLogin(false)
                                        }} >Ok</button>
                                    </div>
                                </div>
                            )
                        }
                        {
                            isPay && (
                                <div className='card' style={{ backgroundColor: 'red', borderRadius: 6, elevation: 23, justifyContent: 'center', alignItems: 'center', width: '28rem' }} >
                                    <div className='card' style={{ width: '100%', borderColor: 'white', borderWidth: 1, borderRadius: 4, borderStyle: 'solid', padding: 9 }} >
                                        <Typography onClick={() => {
                                            setSubscription1(!subscription1)
                                            setSubscription2(false)
                                            setSubscription3(false)
                                            setPayText('1500')
                                            setSubscription4(false)
                                        }} style={{ borderColor: 'white', borderWidth: 0.5, borderRadius: 4, borderStyle: 'dashed', height: 80, marginBottom: 20, width: '100%', padding: 4, position: 'relative', backgroundColor: 'red' }} >
                                            <div>
                                                <Typography style={{ color: 'white', fontWeight: 'bold', fontSize: 17, marginBottom: 10 }}>Subscribe Now</Typography>
                                                <Typography style={{ color: 'white', fontSize: 15 }}>Pay 6 months subscription 1500 RS/- </Typography>
                                            </div>

                                            {
                                                !subscription1 && (
                                                    <FaCircle color='white' size={20} style={{ position: 'absolute', right: 10, top: 5, cursor: 'pointer' }} />
                                                )
                                            }
                                            {
                                                subscription1 && (
                                                    <FaDotCircle color='white' size={20} style={{ position: 'absolute', right: 10, top: 5, cursor: 'pointer' }} />
                                                )
                                            }

                                        </Typography>
                                        <Typography onClick={() => {
                                            setSubscription2(!subscription2)
                                            setSubscription1(false)
                                            setSubscription3(false)
                                            setPayText('2000')
                                            setSubscription4(false)
                                        }} style={{ borderColor: 'white', borderWidth: 0.5, borderRadius: 4, borderStyle: 'dashed', height: 80, marginBottom: 20, width: '100%', padding: 4, position: 'relative', backgroundColor: 'red' }} >
                                            <div>
                                                <Typography style={{ color: 'white', fontWeight: 'bold', fontSize: 17, marginBottom: 10 }}>Subscribe Now</Typography>
                                                <Typography style={{ color: 'white', fontSize: 15 }}>Pay 1 year subscription 2000 RS/- </Typography>
                                            </div>

                                            {
                                                !subscription2 && (
                                                    <FaCircle name='circle-o' color='white' size={20} style={{ position: 'absolute', right: 10, top: 5, cursor: 'pointer' }} />
                                                )
                                            }
                                            {
                                                subscription2 && (
                                                    <FaDotCircle name='check-circle-o' color='white' size={20} style={{ position: 'absolute', right: 10, top: 5, cursor: 'pointer' }} />
                                                )
                                            }

                                        </Typography>
                                        <Typography onClick={() => {
                                            setSubscription3(!subscription3)
                                            setSubscription2(false)
                                            setSubscription1(false)
                                            setPayText('3750')
                                            setSubscription4(false)
                                        }} style={{ borderColor: 'white', borderWidth: 0.5, borderRadius: 4, borderStyle: 'dashed', height: 80, marginBottom: 20, width: '100%', padding: 4, position: 'relative', backgroundColor: 'red' }} >
                                            <div>
                                                <Typography style={{ color: 'white', fontWeight: 'bold', fontSize: 17, marginBottom: 10 }}>Subscribe Now</Typography>
                                                <Typography style={{ color: 'white', fontSize: 15 }}>Pay 2 year subscription 3750 RS/- </Typography>
                                            </div>

                                            {
                                                !subscription3 && (
                                                    <FaCircle name='circle-o' color='white' size={20} style={{ position: 'absolute', right: 10, top: 5, cursor: 'pointer' }} />
                                                )
                                            }
                                            {
                                                subscription3 && (
                                                    <FaDotCircle name='check-circle-o' color='white' size={20} style={{ position: 'absolute', right: 10, top: 5, cursor: 'pointer' }} />
                                                )
                                            }

                                        </Typography>
                                        <Typography onClick={() => {
                                            setSubscription4(!subscription4)
                                            setSubscription2(false)
                                            setSubscription3(false)
                                            setPayText('5500')
                                            setSubscription1(false)
                                        }} style={{ borderColor: 'white', borderWidth: 0.5, borderRadius: 4, borderStyle: 'dashed', height: 80, width: '100%', padding: 4, position: 'relative', backgroundColor: 'red' }} >
                                            <div>
                                                <Typography style={{ color: 'white', fontWeight: 'bold', fontSize: 17, marginBottom: 10 }}>Subscribe Now</Typography>
                                                <Typography style={{ color: 'white', fontSize: 15 }}>Pay 3 year subscription 5500 RS/- </Typography>
                                            </div>

                                            {
                                                !subscription4 && (
                                                    <FaCircle name='circle-o' color='white' size={20} style={{ position: 'absolute', right: 10, top: 5, cursor: 'pointer' }} />
                                                )
                                            }
                                            {
                                                subscription4 && (
                                                    <FaDotCircle name='check-circle-o' color='white' size={20} style={{ position: 'absolute', right: 10, top: 5, cursor: 'pointer' }} />
                                                )
                                            }

                                        </Typography>
                                        {
                                            (subscription1 || subscription2 || subscription3 || subscription4) && (
                                                <button style={{ height: 50, backgroundColor: 'white', borderRadius: 3, width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 12 }} onClick={() => pay()} >
                                                    <Typography style={{ fontSize: 14, fontWeight: 'bold', color: 'red' }} >Pay {payText} RS /-</Typography>
                                                </button>
                                            )
                                        }
                                    </div>
                                </div>

                            )
                        }
                        {
                            isPayConfirm && (
                                <div className='card p-4' style={{ backgroundColor: 'red', borderRadius: 6, elevation: 23, justifyContent: 'center', alignItems: 'center', width: '28rem' }} >
                                    <img src='https://th.bing.com/th/id/OIP.1fMFpLDsUd1aX6IKspPMIgAAAA?w=167&h=180&c=7&r=0&o=5&dpr=2.5&pid=1.7' width='110px' />
                                    <br />
                                    <Typography style={{ color: 'white' }} >Subscribe Amount is <FaRupeeSign />{payText} /-</Typography>
                                    <br />
                                    <div style={{ display: 'flex' }}>
                                        <a href={payUrl} target='_blank' >
                                            <button style={{ height: 43, backgroundColor: 'white', borderRadius: 3, width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 12, border: 'none' }} onClick={() => pay()} >
                                                <Typography style={{ fontSize: 14, fontWeight: 'bold', color: 'red', width: '12rem', backgroundColor: 'white', borderRadius: '0.3rem', border: 'none' }} >Pay Now</Typography>
                                            </button>
                                        </a>
                                        <button onClick={() => {
                                            setOpen1(false)
                                            setIsPay(false)
                                            setIsPayConfirm(false)
                                            setSubscription1(false)
                                            setSubscription2(false)
                                            setSubscription3(false)
                                            setSubscription4(false)
                                        }} style={{ height: 43, backgroundColor: 'white', borderRadius: 3, width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 12, border: 'none', marginLeft: 2 }}  >
                                            <Typography style={{ fontSize: 14, fontWeight: 'bold', color: 'red', width: '12rem', backgroundColor: 'white', borderRadius: '0.3rem', border: 'none' }} >Cancel</Typography>
                                        </button>
                                    </div>
                                </div>
                            )
                        }
                    </DialogContent>
                    {
                        (!isOtp && !isSuccessfullLogin && !isPay && !isPayConfirm) && (
                            <DialogActions>
                                <Button onClick={handleClose1} autoFocus>
                                    Cancel
                                </Button>
                            </DialogActions>
                        )
                    }
                </Dialog>
            </div>
            {
                isWait && (
                    <SimpleBackdrop />
                )
            }
        </div>
    )
}

export default Signup