import React from 'react';
import {FaGreaterThan} from 'react-icons/fa'
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
    const containerStyle = {
        padding: '40px',

        margin: '0 auto',
        fontFamily: 'Arial, sans-serif'
    };

    const headerStyle = {
        color: '#333',
    };

    const subHeaderStyle = {
        color: '#666',
        marginTop: '20px'
    };
    const paragraphStyle1 = {
        textAlign: 'left',
        lineHeight: '1.6',
        fontWeight:'600'
    };

    const paragraphStyle = {
        textAlign: 'left',
        lineHeight: '1.6'
    };

    const listStyle = {
        textAlign: 'left',
        lineHeight: '1.6'
    };

    const linkStyle = {
        color: '#007bff'
    };

    const banner = {
        height: '250px',
        backgroundColor: 'rgb(3, 3, 56)',
        color: 'white',
        padding:'45px',
    };

    return (
        <>
            <div style={banner}>
                <h1>Privacy Policy</h1>
                <div style={{ borderBottom: '3px solid white', width: '150px' }}></div>
                <div style={{ display: 'flex', alignItems: 'center' ,marginTop:'15px'}}>
                <Link to='/' style={{textDecoration:'none',color:'white'}}>  <p style={{marginRight:'12px'}}>Home <FaGreaterThan/></p></Link>
                    <p>Privacy Policy</p>
                </div>
            </div>
            <div style={containerStyle}>

            
                <p style={paragraphStyle1}>
                    eVyapar Exchange LLP is committed to protecting the privacy and security of your personal information. This Privacy Policy describes how we collect, use, and disclose personal information when you visit our website or use our services.
                </p>
                <h2 style={subHeaderStyle}>Information We Collect</h2>
                <p style={paragraphStyle}>
                    We collect various types of personal information in order to provide and improve our services. The types of information we may collect include:
                </p>
                <ul style={listStyle}>
                    <li>
                        <strong>Information You Provide:</strong> When you sign up for an account, fill out forms, or communicate with us, you may provide us with personal information such as your name, email address, phone number, and other contact details.
                    </li>
                    <li>
                        <strong>Referral Information:</strong> When you refer individuals or businesses through our platform, we may collect personal information about them, such as their name and contact information.
                    </li>
                    <li>
                        <strong>Usage Information:</strong> We collect information about how you interact with our website and services, including your IP address, browser type, operating system, and browsing behavior.
                    </li>
                    <li>
                        <strong>Cookies and Similar Technologies:</strong> We may use cookies and similar tracking technologies to collect information about your activity on our website.
                    </li>
                </ul>
                <h2 style={subHeaderStyle}>How We Use Your Information</h2>
                <p style={paragraphStyle}>We may use the personal information we collect for various purposes, including:</p>
                <ul style={listStyle}>
                    <li>Providing and maintaining our services</li>
                    <li>Processing and managing referrals</li>
                    <li>Communicating with you about our services</li>
                    <li>Personalizing your experience</li>
                    <li>Analyzing usage trends and improving our website and services</li>
                    <li>Complying with legal obligations</li>
                </ul>
                <h2 style={subHeaderStyle}>Data Sharing and Disclosure</h2>
                <p style={paragraphStyle}>We may share your personal information with third parties in the following circumstances:</p>
                <ul style={listStyle}>
                    <li>With your consent</li>
                    <li>To service providers who assist us in operating our website and providing our services</li>
                    <li>To comply with legal obligations or respond to lawful requests from government authorities</li>
                    <li>In connection with a merger, acquisition, or other business transaction involving our company</li>
                </ul>
                <h2 style={subHeaderStyle}>Data Security</h2>
                <p style={paragraphStyle}>
                    We take reasonable measures to protect the security of your personal information and prevent unauthorized access, disclosure, or modification. However, no method of transmission over the internet or electronic storage is completely secure, so we cannot guarantee absolute security.
                </p>
                <h2 style={subHeaderStyle}>Your Rights</h2>
                <p style={paragraphStyle}>
                    You have certain rights regarding your personal information, including the right to access, correct, or delete your information. You may also have the right to object to or restrict certain types of processing. Please contact us using the information provided below if you would like to exercise any of these rights.
                </p>
                <h2 style={subHeaderStyle}>Changes to This Privacy Policy</h2>
                <p style={paragraphStyle}>
                    We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated policy on our website or by other means.
                </p>
                <h2 style={subHeaderStyle}>Contact Us</h2>
                <p style={paragraphStyle}>
                    If you have any questions or concerns about our Privacy Policy or our practices regarding your personal information, please contact us at <a style={linkStyle} href="mailto:vyaparexchange@gmail.com">vyaparexchange@gmail.com</a>
                </p>
                <p style={paragraphStyle}><em>Last updated: 01/05/2024</em></p>
            </div>
        </>
    );
};

export default PrivacyPolicy;
