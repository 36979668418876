import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import './index.css'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { BsSearch } from 'react-icons/bs';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import { Box } from '@mui/material';





const NewReferral = () => {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [referralIn, setReferralIn] = useState([])

    const [isCloseReferral, setNewRefrral] = useState(false)

    const [customerSalerEmail, setCustomersalerEmail] = useState()
    const [customerSalerMobile, setCustomersalerMobile] = useState()
    const [customerSalerName, setCustomersalerName] = useState()
    const [customerSalerProduct, setCustomersalerProduct] = useState()
    const [customerSalerLocation, setCustomersalerLocation] = useState()
    const [customerSalerPhoto, setCustomersalerPhoto] = useState()
    const [customerSalerDiscount, setCustomersalerDiscount] = useState()
    const [customerSalerPrice, setCustomersalerPrice] = useState()
    const [customerSalerDiscription, setCustomersalerDiscription] = useState()


    const customer = (salerEmail, first_name, pName, photo, price, discount, discription, location, userId, companyName, salerMobile) => {

        setCustomersalerEmail(salerEmail)
        setCustomersalerName(first_name)
        setCustomersalerProduct(pName)
        setCustomersalerLocation(location)
        setCustomersalerPhoto(photo)
        setCustomersalerDiscount(discount)
        setCustomersalerDiscription(discription)
        setCustomersalerPrice(price)
        setCustomerShow(true)
        setCustomersalerMobile(salerMobile)


    }



    const member = (salerEmail, first_name, pName, photo, price, discount, discription, location, userId, companyName, salerMobile) => {

        const email = sessionStorage.getItem('email')
        axios.post('https://vyaparapi.emedha.in/send-message-member', {
            email,
            salerEmail,
            companyName,
            first_name,
            mobile: sessionStorage.getItem('mobile'),
            name: sessionStorage.getItem('name'),
            pName,
            photo,
            price,
            discount,
            discription,
            location,
            userId,
            salerMobile
        }).then((res) => {
            if (res.data) {
                alert(res.data)

                setLgShow(false)
            }
        })
    }


    const [customerName, setCustomerName] = useState()
    const [customerMobile, setCustomerMobile] = useState()
    const [customerEmail, setCustomerEmail] = useState()


    const customerSendMessage = () => {

        const email = sessionStorage.getItem('email')

        axios.post('https://vyaparapi.emedha.in/send-message-customer', {
            customerEmail,
            customerMobile,
            customerName,
            customerSalerName,
            customerSalerEmail,
            customerSalerProduct,
            customerSalerLocation,
            customerSalerPhoto,
            customerSalerPrice,
            customerSalerDiscount,
            customerSalerDiscription,
            customerSalerMobile,
            email,
        }).then((res) => {
            if (res.data) {
                alert(res.data)

            } else {
                alert(res.data)
            }
        })
    }



    const referralInData = () => {
        const email = sessionStorage.getItem('email')

        axios.post('https://vyaparapi.emedha.in/referralOut/get', {
            email
        }).then((res) => {
            setReferralIn(res.data)

        })
    }
    useEffect(() => {

        referralInData()

    }, [])

    const [productHandler, setProducts] = useState("");
    const [locationHandler, setLocationHandler] = useState(false)


    const onChangeInput = (e) => {
        setLocationHandler(true)
        setLocationHandler(e.target.value)

    }

    const [referralShow, setReferralShow] = useState(false)


    const [smShow, setSmShow] = useState(false);
    const [lgShow, setLgShow] = useState(false);


    const [customerShow, setCustomerShow] = useState(false)

    const [customerSerchProduct, setCustomerSerchProduct] = useState([])


    const [photo, setPhoto] = useState()
    const [email, setEmail] = useState()
    const [price, setPrice] = useState()
    const [discount, setDiscount] = useState()
    const [description, setDescription] = useState()
    const [location, setLocation] = useState()
    const [pname, setpName] = useState()
    const [customName, setCustomerSalerName] = useState()
    const [discountPrice, setDiscountPrice] = useState()


    const viewProduct = (photo, email, price, discount, description, location, pName, customerSalerName) => {
        setPhoto(photo)
        setEmail(email)
        setPrice(price)
        setDiscount(discount)
        setDescription(description)
        setLocation(location)
        setpName(pName)
        setCustomerSalerName(customerSalerName)
        const dis = ((discount / 100) * (price))
        setDiscountPrice(price - dis)
        setDiscount(discount)
        setReferralShow(true)
    }




    const productDataHandler = (productHandler) => {

        axios.post('https://vyaparapi.emedha.in/search-product-admin', {
            productHandler,
            locationHandler
        }).then((res) => {
            if (res.data) {

                setLocationHandler("")
                setCustomerSerchProduct(res.data)
                setLgShow(true)
            }
        })
    }

    return (
        <>

            <div className='referral-out-main-container'>

                <div className='container-fluid'>
                    <div className='row mt-5'>
                        <div className='col-lg-4 mx-auto'>
                            <h1 style={{ color: 'red' }}>Referral Out</h1>
                        </div>
                        <div className='col-lg-4 mx-auto' >
                            <center> <button style={{ border: 'none', width: '8rem', backgroundColor: 'red', borderRadius: '0.2rem', height: '2.5rem', color: 'white' }} onClick={() => setSmShow(true)}>New Referral</button></center>
                        </div>
                        <div className='col-lg-4 mx-auto' style={{ position: 'relative' }}>
                            <div >
                                <input
                                    className='shadow'
                                    type="search"
                                    placeholder="Search"
                                    style={{ width: '100%', height: '2.6rem', border: 'none', borderRadius: '0.3rem', paddingLeft: 5, marginBottom: 3 }}

                                />
                                <BsSearch style={{ position: 'absolute', right: 22, top: 13 }} className="search-icon" />
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-12 mx-auto  mb-4'>

                            {/* <Paper>
                                <TableContainer sx={{ maxHeight: 650, cursor: 'default' }}>

                                    <Table>
                                        <TableHead sx={{ backgroundColor: '#02375a', borderRadius: '0.7rem' }}>


                                            <TableRow>


                                                <TableCell

                                                    align={"start"}
                                                    style={{ top: 57, minWidth: 30, fontWeight: 'bold', color: 'white' }}
                                                >
                                                    Product
                                                </TableCell>
                                                <TableCell

                                                    align={"start"}
                                                    style={{ top: 57, minWidth: 120, fontWeight: 'bold', color: 'white' }}
                                                >
                                                    Referral Id
                                                </TableCell>
                                                <TableCell

                                                    align={"start"}
                                                    style={{ top: 57, minWidth: 120, fontWeight: 'bold', color: 'white' }}
                                                >
                                                    Mobile Number
                                                </TableCell>
                                                <TableCell

                                                    align={"start"}
                                                    style={{ top: 57, minWidth: 120, fontWeight: 'bold', color: 'white' }}
                                                >
                                                    Email
                                                </TableCell>

                                                <TableCell

                                                    align={"start"}
                                                    style={{ top: 57, minWidth: 120, fontWeight: 'bold', color: 'white' }}
                                                >
                                                    Status
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {referralIn
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((data, index) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={data.code}>

                                                            <TableCell align={"start"}
                                                                style={{ top: 57, minWidth: 100 }}><img style={{ width: '20%' }} src={`https://vyaparapi.emedha.in/images/${data.photo}`} /></TableCell>
                                                            <TableCell align={"start"}
                                                                style={{ top: 57, minWidth: 350, textDecoration: 'underline', cursor: 'pointer', textDecorationColor: 'black', fontWeight: "bold" }} onClick={() => viewProduct(data.photo, data.email, data.price, data.discount, data.discription, data.location, data.pName, data.customerSalerName)}>EV00{index + 1}</TableCell>
                                                            <TableCell align={"start"}
                                                                style={{ top: 57, minWidth: 380 }}>9876543210</TableCell>
                                                            <TableCell align={"start"}
                                                                style={{ top: 57, minWidth: 380 }}>raju@gmail.com</TableCell>
                                                            <TableCell align={"start"}
                                                                style={{ top: 57, minWidth: 20 }}>Accepted</TableCell>

                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>

                                    </Table>



                                </TableContainer>

                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 20, 100]}
                                    component="div"
                                    count={referralIn.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}

                                />


                            </Paper> */}

                            <Paper>
                                <TableContainer sx={{ maxHeight: 650, cursor: 'default' }}>
                                    <Box sx={{ width: '100%' }}>
                                        <Table sx={{ width: '100%' }}>
                                            <TableHead sx={{ backgroundColor: '#02375a', borderRadius: '0.7rem' }}>
                                                <TableRow>
                                                    <TableCell
                                                        align="start"
                                                        sx={{
                                                            fontWeight: 'bold',
                                                            color: 'white',
                                                            fontSize: { xs: '0.55rem', sm: '1rem' },  // Smaller font size for mobile
                                                            minWidth: { xs: 50, sm: 100 },  // Adjusted column width for mobile
                                                            padding: '4px',  // Reduced padding
                                                        }}
                                                    >
                                                        Product
                                                    </TableCell>
                                                    <TableCell
                                                        align="start"
                                                        sx={{
                                                            fontWeight: 'bold',
                                                            color: 'white',
                                                            fontSize: { xs: '0.55rem', sm: '1rem' },  // Smaller font size for mobile
                                                            minWidth: { xs: 80, sm: 150 },  // Adjusted column width for mobile
                                                            padding: '4px',  // Reduced padding
                                                        }}
                                                    >
                                                        Referral Id
                                                    </TableCell>
                                                    <TableCell
                                                        align="start"
                                                        sx={{
                                                            fontWeight: 'bold',
                                                            color: 'white',
                                                            fontSize: { xs: '0.55rem', sm: '1rem' },  // Smaller font size for mobile
                                                            minWidth: { xs: 80, sm: 150 },  // Adjusted column width for mobile
                                                            padding: '4px',  // Reduced padding
                                                        }}
                                                    >
                                                        Mobile Number
                                                    </TableCell>
                                                    <TableCell
                                                        align="start"
                                                        sx={{
                                                            fontWeight: 'bold',
                                                            color: 'white',
                                                            fontSize: { xs: '0.55rem', sm: '1rem' },  // Smaller font size for mobile
                                                            minWidth: { xs: 80, sm: 150 },  // Adjusted column width for mobile
                                                            padding: '4px',  // Reduced padding
                                                        }}
                                                    >
                                                        Email
                                                    </TableCell>
                                                    <TableCell
                                                        align="start"
                                                        sx={{
                                                            fontWeight: 'bold',
                                                            color: 'white',
                                                            fontSize: { xs: '0.55rem', sm: '1rem' },  // Smaller font size for mobile
                                                            minWidth: { xs: 50, sm: 100 },  // Adjusted column width for mobile
                                                            padding: '4px',  // Reduced padding
                                                        }}
                                                    >
                                                        Status
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {referralIn
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((data, index) => {
                                                        return (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={data.code}>
                                                                <TableCell
                                                                    align="start"
                                                                    sx={{

                                                                        minWidth: { xs: 50, sm: 80, md: 100 },  // Adjusted column width for mobile and desktop
                                                                        fontSize: { xs: '0.45rem', sm: '1rem', md: '0.85rem' },  // Smaller font size for mobile, and slightly smaller for desktop
                                                                        padding: '4px',  // Reduced padding
                                                                        display: 'flex',  // Use flexbox to align the image and text
                                                                        justifyContent: 'center',  // Center the image
                                                                    }}
                                                                >
                                                                    <img
                                                                        style={{
                                                                            width: '30%',  // Image size for mobile
                                                                            maxWidth: '80px',  // Set max width for image on large screens
                                                                        }}
                                                                        src={`https://vyaparapi.emedha.in/images/${data.photo}`}
                                                                        alt={`Product ${index}`}
                                                                    />
                                                                </TableCell>

                                                                <TableCell
                                                                    align="start"
                                                                    sx={{
                                                                        minWidth: { xs: 80, sm: 150 },  // Adjusted column width for mobile
                                                                        fontSize: { xs: '0.45rem', sm: '1rem' },  // Smaller font size for mobile
                                                                        textDecoration: 'underline',
                                                                        cursor: 'pointer',
                                                                        fontWeight: 'bold',
                                                                        padding: '4px',  // Reduced padding
                                                                    }}
                                                                    onClick={() =>
                                                                        viewProduct(
                                                                            data.photo,
                                                                            data.email,
                                                                            data.price,
                                                                            data.discount,
                                                                            data.discription,
                                                                            data.location,
                                                                            data.pName,
                                                                            data.customerSalerName
                                                                        )
                                                                    }
                                                                >
                                                                    EV00{index + 1}
                                                                </TableCell>
                                                                <TableCell
                                                                    align="start"
                                                                    sx={{
                                                                        minWidth: { xs: 60, sm: 120 },  // Adjusted column width for mobile
                                                                        fontSize: { xs: '0.45rem', sm: '1rem' },  // Smaller font size for mobile
                                                                        padding: '4px',  // Reduced padding
                                                                    }}
                                                                >
                                                                    9876543210
                                                                </TableCell>
                                                                <TableCell
                                                                    align="start"
                                                                    sx={{
                                                                        minWidth: { xs: 60, sm: 120 },  // Adjusted column width for mobile
                                                                        fontSize: { xs: '0.45rem', sm: '1rem' },  // Smaller font size for mobile
                                                                        padding: '4px',  // Reduced padding
                                                                    }}
                                                                >
                                                                    raju@gmail.com
                                                                </TableCell>
                                                                <TableCell
                                                                    align="start"
                                                                    sx={{
                                                                        minWidth: { xs: 50, sm: 100 },  // Adjusted column width for mobile
                                                                        fontSize: { xs: '0.45rem', sm: '1rem' },  // Smaller font size for mobile
                                                                        padding: '4px',  // Reduced padding
                                                                    }}
                                                                >
                                                                    Accepted
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </TableContainer>

                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 20, 100]}
                                    component="div"
                                    count={referralIn.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>


                        </div>
                    </div>

                </div>


            </div>



            <div>

                <Modal
                    size="lg"
                    centered
                    show={smShow}
                    onHide={() => {
                        setLocationHandler("")
                        setProducts("")
                        setSmShow(false)
                    }}
                    style={{ color: 'white' }}
                    aria-labelledby="example-modal-sizes-title-sm"
                >
                    <Modal.Header closeButton style={{ backgroundColor: 'lightcyan' }}>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            Select Products
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ backgroundColor: 'white' }}>
                        <form>
                            <div className='d-flex flex-column align-items-center'>
                                <select className='select-input' onChange={onChangeInput}>
                                    <option value="" disabled selected>Select Your City</option>
                                    <option>Hyderabad</option>
                                    <option>Mumbai</option>
                                    <option>Chennai</option>
                                    <option>Banglore</option>
                                    <option>Delhi</option>
                                </select>

                                {(locationHandler) && <select className='select-input mt-3' onChange={(e) => productDataHandler(e.target.value)}>
                                    <option value="" disabled selected>Select Product</option>
                                    <option>mobile</option>
                                    <option>laptop</option>
                                    <option>mobile</option>
                                    <option>laptop</option>
                                </select>}

                            </div>
                        </form>
                    </Modal.Body>
                </Modal>

            </div>

            <>
                <Modal
                    size="xl"
                    show={lgShow}
                    onHide={() => setLgShow(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                    centered
                    backdrop="static"
                    keyboard={false}
                    className='shadow'

                >
                    <Modal.Header closeButton className='refOutModelBody shadow'>
                    </Modal.Header>
                    <Modal.Body className='model-body' style={{ backgroundColor: 'white' }}>
                        <div className='col col-11 mx-auto shadow referralConatainer'>
                            <div>
                                {
                                    customerSerchProduct.slice(0, 10).map((data, index) => {
                                        const dis = ((data.discount / 100) * (data.pPrice))
                                        return (

                                            <div className='filter-product-card' key={index}>
                                                <img style={{ width: '75%' }} src={`https://vyaparapi.emedha.in/images/${data.pPhoto}`} alt="file empty" />
                                                <div className='content-referal-container'>
                                                    <div className='mobile-content'>
                                                        <h1 className='product-type' style={{ color: 'rgb(217, 55, 13, 1)' }}>{data.pName}</h1>
                                                        <p className='price-referral-out' ><span style={{ fontSize: 22, fontWeight: 'bold' }}>Price:</span>Rs 21000/-<span className='discount-price'>{data.pPrice - dis}</span><span className='discount-percentage'>{data.discount}% off</span></p>
                                                        <p className='product-description'><span className='mobile-types' style={{ fontWeight: 'bold' }}>Description:</span>{data.discription}</p>
                                                        <p><span className='mobile-types' style={{ fontWeight: 'bold' }}>Brand:</span>Samsung</p>
                                                        <p><span className='mobile-types' style={{ ontWeight: 'bold' }}>Location:</span>{data.location}</p>
                                                    </div>
                                                    <div className='email-whatsapp-cards'>
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ backgroundColor: '#DB4437' }}>
                                                                Email
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#/action-1" style={{ color: 'green', textAlign: 'center', fontweight: 'bold' }} onClick={() => member(data.email, data.fullName, data.pName, data.pPhoto, data.pPrice, data.discount, data.discription, data.location, data.id, data.companyName, data.mobile)}>Self</Dropdown.Item>
                                                                <Dropdown.Item href="#/action-2" style={{ color: 'red', textAlign: 'center', fontweight: 'bold' }} onClick={() => customer(data.email, data.fullName, data.pName, data.pPhoto, data.pPrice, data.discount, data.discription, data.location, data.id, data.companyName, data.mobile)}>Customer</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        <Dropdown className='mt-2'>
                                                            <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ backgroundColor: '#25D366', color: 'white', marginLeft: 15 }}>
                                                                Whatsapp
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#/action-1" style={{ color: 'green', textAlign: 'center', fontweight: 'bold' }}>Self</Dropdown.Item>
                                                                <Dropdown.Item href="#/action-2" style={{ color: 'red', textAlign: 'center', fontweight: 'bold' }}>Customer</Dropdown.Item>

                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>


            <Modal
                size="md"
                centered
                show={customerShow}
                onHide={() => setCustomerShow(false)

                }
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Customer
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form >
                        <div className='d-flex flex-column align-items-center'>
                            <form className='referral-to-customer-form'>
                                <input type='text' id='fullname' placeholder='Enter full name' className='referral-input mt-4' onChange={(e) => setCustomerName(e.target.value)} />
                                <input type='tel' id='mobileNo' placeholder='Enter mobile' className='referral-input' onChange={(e) => setCustomerMobile(e.target.value)} />
                                <input type='email' id='emailCustomer' placeholder='Enter Email' className='referral-input' onChange={(e) => setCustomerEmail(e.target.value)} />
                                <Button onClick={customerSendMessage} style={{ width: 160 }} >Send</Button>
                            </form>

                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal
                size="xl"
                show={referralShow}
                onHide={() => setReferralShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                centered
                backdrop="static"
                keyboard={false}
                className='shadow'

            >
                <Modal.Header closeButton className='refOutModelBody shadow text-color'>
                    Referral Out
                </Modal.Header>
                <Modal.Body className='model-body' >

                    <div className='referral-out-product-card-container'>

                        <div className='referral-out-product-card'>
                            <img style={{ width: '46%' }} src={`https://vyaparapi.emedha.in/images/${photo}`} alt="file empty" />
                            <div className='content-referal-container'>
                                <div className='mobile-content'>
                                    <h1 className='product-type' style={{ color: 'rgba(217, 55, 13, 1)' }}><span style={{ fontSize: 22, fontWeight: 'bold', color: 'black' }}>Refer By:</span> {customName}</h1>
                                    <p className='price-referral-out' ><span style={{ fontSize: 22, fontWeight: 'bold' }}>Price: </span>Rs {price}<span className='discount-price'>{discountPrice}</span><span className='discount-percentage'>{discount}% off</span></p>
                                    <p className='product-description'><span className='mobile-types' style={{ fontWeight: 'bold' }}>Description:</span>{description}</p>
                                    <p><span className='mobile-types' style={{ fontWeight: 'bold' }}>Brand:</span>{pname}</p>
                                    <p><span className='mobile-types' style={{ color: 'black', fontWeight: 'bold' }}>Location:</span>{location}</p>
                                </div>
                            </div>
                        </div>

                    </div>


                </Modal.Body>
            </Modal>


        </>
    )
}

export default NewReferral



